import React, { Component } from 'react';
import Helpers from './Viewer-helpers';
import './Viewer.css'

class Viewer extends Component {

    componentDidMount() {
        var documentId = 'urn:'+ this.props.urn        
        Helpers.launchViewer(documentId , '0002', this.props.extensions, this.props.hasDwg);        
    }

	render() {
        return (           
            <div id="forgeViewer" className="forgeViewer"></div>
        );
	}
}

export default Viewer;