import React, { useState, useEffect } from "react";
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { uchConfigList, updateConfigStatus } from '../api/apiConfigs';
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Row, Col,  Image, Table } from 'react-bootstrap';
import { FaCodepen, FaEdit } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'

const UchConfigAdmin = () => {

    const [state, setState] = React.useState({
        configList: '',
        errorMessage: '',
        success: false,
        runningInCAD: true,
        updated: false
    })

    const userId = isAuthenticated().userId
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    useEffect(() => {
        if (state.updated) {
            uchConfigList("All").then(data => {
                if (data.error) {
                } else {                    
                    setState({
                        ...state,
                        configList: data,
                        updated: false
                    });

                }
            });
        }
        // use the user details to grab the vendor Id associated to them
        uchConfigList("All").then(data => {
            if (data.error) {
            } else {                
                setState({
                    ...state,
                    configList: data,
                    updated: false
                });

            }
        });
        // if (window.uchInventorInterface) {
        //     this.setState({ runningInCAD: true })
        // }
    }, []);


    const style = {
        image: {
            'background': '#fefefe',
            'text-align': 'center',
            'display': 'block',
            'justify-content': 'center',
            'align-items': 'center',
            'margin': 'auto',
        },
        vendorimage: {
            background: '#fefefe',
        },
    };

    const handleStatusChange = (status, configId) => {
        var updatedStatus
        if (status === 'Draft') {
            updatedStatus = "Published"
        } else {
            updatedStatus = "Draft"
        }

        updateConfigStatus({ status: updatedStatus, configId: configId })

        setState({
            ...state,
            updated: true
        });

    }



    if (state.configList.length > 0) {
        return (
            <Layout
                title="Configurator Admin"
                description="Update your configurators"
                className="container-fluid"
                isMobile={isTabletOrMobile}
            >
                <div className="container-fluid">

                    <Row>
                        <Col md={12}>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to admin dashboard</a></li>
                                    <li className="breadcrumb-item active" aria-current="page" >administer your configurators</li>
                                </ol>
                            </nav>
                            <hr />
                            {state.runningInCAD && <Link to={`/ucInventorConfigurators`} className="mr-2"> 
                                <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Add New Configurator <FaCodepen /></button>
                            </Link>
                            }
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>Status</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Last Updated</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {state.configList.map((configurator, index) => (
                                        <tr>
                                            <td><Link to={`/ucInventorConfiguratorUpdate/${configurator._id}`} className="mr-2">
                                                <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Edit <FaEdit /></button>
                                            </Link>
                                            </td>
                                            <td><Image
                                                src={configurator.photo}
                                                alt={configurator.title}
                                                className="mb-3"
                                                width={100}
                                                height={100}
                                                style={style.image}
                                            />
                                            </td>
                                            <td>{configurator.status}
                                                <input
                                                    type="checkbox"
                                                    checked={configurator.status === 'Published'}
                                                    onClick={() => handleStatusChange(configurator.status, configurator._id)}
                                                />
                                            </td>
                                            <td>{configurator.title}</td>
                                            <td>{configurator.description}</td>
                                            <td>{configurator.lastUpdated}</td>

                                        </tr>
                                    ))}
                                </tbody>

                            </Table>

                        </Col>
                    </Row>

                </div>
                <br />
            </Layout>

        )
    } else {
        return (
            <Layout
                title="Configurator Admin"
                description="Update your configurators"
                className="container-fluid"
            >
                <div className="container-fluid">
                    <Row>
                        <Col md={12}>
                            <Link to={`/configBuilder/${userId}`} className="mr-2">
                                <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Add New Configurator <FaCodepen /></button>
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h3>There are no configurators.</h3>
                        </Col>
                    </Row>
                </div>
            </Layout>

            // <Container>
            //     <Row>
            //         <Col md={12}>
            //             <div className="d-flex align-items-center" style={{ height: '400px' }} >
            //                 <Button variant="primary" disabled>
            //                     <ProgressBar animated now={75} />
            //                     {` `}Loading Data...
            //                 </Button>
            //             </div>
            //         </Col>
            //     </Row>
            // </Container>
        )
    }

}

export default UchConfigAdmin;