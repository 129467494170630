import React, { useState, useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { getFullUserList, updateUserRole, findPeople, deletePendingUser, getUserList } from '../api/apiAdmin';
import { sendMessage } from '../api/apiCore';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBan } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive'

const ManageUsers = () => {

    const [state, setState] = React.useState({
        users: [],
        user: '',
        fullUserList: [],
        userList: [],
        updateMessage: '',
        searchText: ''
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const updateUserList = () => {
        getFullUserList(isAuthenticated().token).then(data => {
            setState({
                ...state,
                userList: data,
            });
        })

    }

    useEffect(() => {
        setState({
            ...state,
            user: isAuthenticated()
        });
        updateUserList();
    }, []);


    const deleteUser = (userId, email, firstName) => {

        const confirmation = window.confirm('Are you sure you want to delete this user? This action cannot be undone!');

        if (confirmation) {
            // User confirmed, proceed with deleting the user
            // Your code for deleting the user goes here
            deletePendingUser(userId, isAuthenticated().token).then(msg => {
                updateUserList()                
                setState({
                    ...state,
                    updateMessage: 'User Deleted.'
                });
            })    
        } 
    }

    // changes user status to approved and sends email
    const approveUser = (userId, email, firstName) => {
        updateUserRole(userId, 'subscriber', isAuthenticated().token).then(approved => {
            const mailMessage = {
                "subject": `${firstName} welcome to UtilityContent.com`,
                "from": "no-reply-utilitycontent@spatialbiz.com",
                "to": email,
                "html": `<html><body>Hi ${firstName},<p>Your UtilityContent.com account is setup and you can login to start downloading content.</p><p>Enjoy!</p></body></html>`
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                updateUserList()
                setState({
                    ...state,
                    updateMessage: approved.message
                });
            });
        })
    }

    const holdUser = (userId) => {
        updateUserRole(userId, 'pending', isAuthenticated().token).then(approved => {
            setState({
                ...state,
                updateMessage: approved.message
            });
            updateUserList()
        })
    }

    const makeVendor = (userId, email, firstName) => {
        updateUserRole(userId, 'vendor', isAuthenticated().token).then(approved => {
            const mailMessage = {
                "subject": `${firstName} you are now a Vendor on UtilityContent.com`,
                "from": "no-reply-utilitycontent@spatialbiz.com",
                "to": email,
                "html": `<html><body>Hi ${firstName},<p>Your UtilityContent.com account is now setup as a vendor and you can administer your own content.</p><p>Enjoy!</p></body></html>`
            }
            // make request to api to send message
            sendMessage(mailMessage).then(data => {
                updateUserList()
                setState({
                    ...state,
                    updateMessage: approved.message
                });
                clickSearch();
            });

        })
    }


    const clickSearch = () => {
        findPeople(isAuthenticated().userId, state.searchText, isAuthenticated().token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setState({
                    ...state,
                    users: data
                });
            }
        });
    };

    const handleChange = name => event => {

        const input = event.target;
        const value = event.target.type === 'checkbox' ? input.checked : input.value;

        setState({
            ...state,
            error: "",
            [name]: value
        });

    };

    const renderUsers = users => (
        <div className="row">
            {users.map((user, i) => (
                <div className="card col-md-4" key={i}>
                    <div className="card-body">
                        <h5 className="card-title">{user.name} {user.lastName}</h5>
                        <p className="card-text">from company: <b>{user.companyName}</b> has the role of <b>{user.role}</b></p>
                        <p className="card-text">with email address: <b>{user.email}</b> </p>
                        <button onClick={() => { holdUser(user._id) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            Put on Hold <FaCheckCircle />
                        </button>

                        <button onClick={() => { makeVendor(user._id, user.email, user.name) }}
                            className="btn btn-outline-info mt-2 mb-2 card-btn-1">
                            Make Vendor <FaCheckCircle />
                        </button>

                    </div>
                </div>
            ))}
        </div>
    );

    const downloadFullUserList = () => {
       const downloadJSONFile = (data, filename) => {
         const jsonData = JSON.stringify(data);
         const blob = new Blob([jsonData], { type: "application/json" });
         const url = URL.createObjectURL(blob);
         const link = document.createElement("a");
         link.href = url;
         link.download = filename;
         link.click();
         URL.revokeObjectURL(url);
       };
       
       getUserList(isAuthenticated().token).then(data => {
         if (data.error) {
           console.log(data.error);
         } else {
           // Call the function to download the data as a JSON file
           downloadJSONFile(data, "userlist.json");
         }
       });
    }

    return (
        <Layout
            title={`${isAuthenticated().name}'s User Management`}
            description="User Management for Admins"
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Manage User Access</li>
                    </ol>
                </nav>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">

                            {state.userList.length > 0 && <div className="col-md-12">
                                <div>All users waiting to signin </div>
                                <table className="table table-sm table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>email</th>
                                            <th>Current Role</th>
                                            <th>Approve user</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.userList.map((user, pIndex) => (
                                            <tr key={pIndex}>
                                                <td>{user.name}</td>
                                                <td>{user.lastName}</td>
                                                <td>{user.email}</td>
                                                <td>{user.role}</td>
                                                <td>
                                                    <button onClick={() => { deleteUser(user._id, user.email, user.name) }}
                                                        className="btn btn-outline-danger mt-2 mb-2 card-btn-1">
                                                        Delete <FaBan />
                                                    </button>
                                                    <button onClick={() => { approveUser(user._id, user.email, user.name) }}
                                                        className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                                                        Approve <FaCheckCircle />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>}
                            {state.userList.length === 0 && <div className="alert alert-info" ><h4>There are no users waiting for approval to join! </h4></div>}

                        </div>
                        {state.updateMessage === 'User Updated Successfully' && <div className="alert alert-info" ><h4>User was approved!</h4></div>}
                    </div>

                    <div className="container">
                        <h2 className="mt-5 mb-5">Find Any Existing User </h2>

                        <div className="form-group">
                            <label className="col-form-label">Search for Users by first name (note: capitals are important so enter 'Bob' not 'bob'):</label>
                            <input name="searchText" value={state.searchText} type="text" onChange={handleChange("searchText")} className="form-control" id="searchText" />
                        </div>
                        <div className="form-group">
                            <button onClick={clickSearch} className="btn btn-outline-primary mt-2 mb-2 card-btn-1">
                                Submit
                            </button>
                        </div>
                        {renderUsers(state.users)}
                    </div>
   
                    <div className="container">
                    <br/>
           
                   <hr/>
                        <div className="form-group">                  
                        <p>Note: this will generate a JSON file for download. You will then need to pull this data into Excel.</p>      
                            <button onClick={downloadFullUserList} className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                                Download User Report
                            </button>
                           
                        </div>
                      
                    </div>

                </div>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default ManageUsers;