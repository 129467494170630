import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { withRouter } from "react-router-dom";
import Logo from "../images/ContentLogo.png";

const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "blue" };
    } else {
        return { color: "black" };
    }
};

const Footer = ({ history }) => (

    <footer className="#80bfff navbar-fixed-bottom" >
        <hr />
        <Container fluid="md">
        <Row>
            <Col sm>
                <a href="/"><img src={Logo}
                    alt={'Utility Content'}
                    className="mb-3"
                    style={{ maxHeight: "50%", maxWidth: "60%" }}
                />
                </a>
                <small className="d-block mb-3 text-muted">copyright &copy; 2024</small>
            </Col>
            <Col sm>
                <p className="mbr-contacts__text">
                    <strong>ADDRESS</strong><br />
                    1890 West Littleton Boulevard.
                   <br />
                    Littleton, CO, USA 80120
                                </p>
            </Col>
            <Col sm>
                <p className="mbr-contacts__text">
                    <strong>Email Support</strong><br />
                    support@spatialbiz.com<br />                    
                </p>
            </Col>
            <Col sm>
                <strong>Policies</strong>
                <ul className="list-unstyled text-small">
                    <li><a className="text-muted" href="/privacy">Privacy Policy</a></li>
                    <li><a className="text-muted" href="/terms">Terms of Service</a></li>
                </ul>
            </Col>

        </Row>
        </Container>
    </footer>
);


export default withRouter(Footer);
