import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { uchConfigList, uchConfigListSearch } from '../api/apiConfigs';
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Row, Col, Card, CardDeck, Image, Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'
import moment from 'moment';
import Select from 'react-select'
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const UchconfigList = () => { 

    const [configList, setConfigList] = useState('');
    const [loading, setLoading] = useState([])    
    const configOptionsList = []
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    useEffect(() => {   
        setLoading(true);     
        uchConfigList("Published").then(data => {
            if (data.error) {
            } else {
                console.log(data)
                setConfigList(data)
            }
            data.map((item, i) => {
                configOptionsList.push({
                    'value': item._id,
                    'label': `${item.title}   -   [${item.description}]`
                })
            })

        });
        setLoading(false);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/configurators");
    }, []);

    const runLoader = () => {
        if (loading) {
            return (
                <div className="row">
                    <div className="col-mb-3">
                        <h4 color="#00BFFF">Searching...</h4>
                    </div>
                    <div className="col-mb-3">
                        <Loader type="Circles" color="#00BFFF" height={40} width={40} />
                    </div>
                </div>
            )
        }
    }

    const style = {
        image: {
            'background': '#fefefe',
            'textAlign': 'center',
            'display': 'block',
            'justifyContent': 'center',
            'alignItems': 'center',
            'margin': 'auto',
            'width': '18rem',

            //'min-height': '18rem', 
            //'max-height': '28rem'             
        },
        vendorimage: {
            background: '#fefefe',
            height: 20
        },
    };

    const filterConfigList = (newValue) => {

        uchConfigListSearch(newValue).then(data => {
            if (data.error) {
            } else {
                setConfigList(data)

            }
        });
    }

    const handleInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed');
        console.log(inputValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };

    const handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue.value);
        filterConfigList(newValue.value)
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };

    if (configList.length > 0) {
        return (
            
                <Layout
                    title="Configurators"
                    description=""
                    className="container-fluid"
                    isMobile={isTabletOrMobile}
                >
                    <div className="container-fluid">                        
                        <Row className="justify-content-md-center">
                            <Col xs lg="6">
                                <Form.Label>Search Configurators:</Form.Label>
                                <Select
                                    placeholder={'Start Typing a Title...'}
                                    onChange={handleChange}
                                    options={configOptionsList}
                                    onInputChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <br />
                        <div className="d-flex justify-content-center">

                            <CardDeck>
                                {configList.map((configurator, index) => (  
                                    <Card style={{ 'minWidth': '22rem', 'maxWidth': '22rem', 'marginTop': "1%" }} border="primary" key={index}>
                                        <Card.Body >
                                            <a href={`/vendor/${configurator.vendor._id}`} >
                                                <Image
                                                    src={configurator.vendor.logo}
                                                    alt={configurator.vendor.name}
                                                    style={style.vendorimage}
                                                />
                                            </a>
                                            <Image
                                                src={configurator.photo}
                                                alt={configurator.title}
                                                style={style.image}
                                            />
                                            <Card.Title>{configurator.title}</Card.Title>
                                            <Card.Text>
                                                {configurator.description}
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="row">
                                                        <small className="text-muted">{moment(configurator.lastUpdated).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                                        <br />
                                                    </div>
                                                    <div className="row">
                                                        <span className="badge badge-primary badge-pill">{configurator.downloads.length} downloads </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to={`/configurator/${configurator._id}`} className="mr-2">
                                                        <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Details <FaSearch /></button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                ))}
                                
                            </CardDeck>
                        </div>
                        
                    </div>
                    <br />
                </Layout>          
        )
        } else {
        return (
          
            <Layout
                title="Configurator Admin"
                description="Update your configurators"
                className="container-fluid"
            >
                <Row className="justify-content-md-center">
                            {runLoader()}
                        </Row>
            </Layout>
           
        )
    }
}

export default UchconfigList;
