import React, { Component } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import Image from 'react-image-resizer';
import moment from "moment";
import { sendMessage } from '../api/apiCore';
import ReactGA from 'react-ga';
import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';
import NaucsiLogo from '../images/NAUCSI_logo.png';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

class Naucsi extends Component {

    constructor(props) {
        super(props)
        this.state = {
            from: '',
            message: '',
            subject: '',
            error: '',
            success: false
        }
    }



    style = {
        image: {
            background: '#fefefe',
        },
    };


    handleChange = name => event => {
        let value = "";
        value = event.target.value
        this.setState({ [name]: value });
    };

    componentDidMount() {
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/forvendors");
    };

    sendMessage = e => {
        e.preventDefault();

        const mailMessage = {
            "subject": `${this.state.subject}`,
            "from": this.state.from,
            "to": "steve.kaufman@spatialbiz.com",
            "html": `<html><body>From user ${this.state.from},<p>${this.state.message}</p></body></html>`
        }


        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            // if (data.error) {
            //     this.setState({error: data.error})
            // } else {
            this.setState({
                error: '',
                success: true,
                from: '',
                message: '',
                subject: ''
            })
            // }
        });

    };

    showSuccess = () => {
        if (this.state.success) {
            return (
                <div className="alert alert-info" ><h4>Thanks for your message. </h4></div>
            )
        }
    };


    render() {
        return (
            <Layout
                title="NAUCSI group"
                description="The North American Utilities Content Sharing Initiative"
                className="container-fluid"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={NaucsiLogo} style={{ height: '100px' }}></img>
                                    <h2>The North American Utilities Content Sharing Initiative</h2>
                                    <br />
                                    <p>

                                    </p>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <h4>Share Design Content with other Utilities</h4>
                                    <br />
                                    <p>
                                        Dear Electric Utility,
                                    </p>
                                    <p>
                                        During creation and launch of the Utility Content platform, SBS has streamlined the process of sharing
                                        design content across different Autodesk products as this new platform uses Autodesk Forge services
                                        to automatically translate data.
                                </p>
                                    <p>
                                        Now that we have this in place, we are launching what we are calling, the “North American Utilities
                                        Content Sharing Initiative” to help our customers gain access to a broader selection of design content in
                                        a variety of formats whether it be 3D models, 2D dwg blocks or even engineering data in spreadsheet formats.
                                        The goal is the same. Convert the data to useable formats for all Utility Companies involved
                                </p>
                                    <p>
                                        To reach this goal we are creating a "NAUCSI" group under this Utility Content Platofrm and we will start
                                        sharing the data there. This NAUCSI group will be free and will be <b>only accessible by the Utility
                                        members that are involved in this initiative.</b>
                                    </p>
                                    <p>
                                        This group is really targeted at Utility Company members of the Substation Design Solutions Industry Consortium
                                        (SDSIC) that are currently undertaking, have completed, or are investing in content development for Substation
                                        Design.
                                </p>
                                    <p>
                                        Please feel free to read the FAQ section below or use the contact form below to get more information.
                                </p>
                                </div>


                            </div>
                        </div>
                    </div>

                    <br />
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Frequently Asked Questions</h2>
                                    <br />

                                </div>


                            </div>
                            <div className="row">

                                <div className="col-md-12">
                                    <Accordion defaultActiveKey="1">

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                    <p><h5>Q: Are there any costs associated to membership in this group or any additional services charges? </h5></p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <p>A: The NAUCSI group is completely free for this select group of Utility Companies. We do reserve
                                                    the right to refuse content that does not ‘easily’ translate with our current platform though as
                                                    there can be some heavy services involved if data being uploaded does not conform. ie. Legacy
                                                        file types.</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                    <p><h5>Q: We use our own Document Management system, but I have never heard of the Utility Content
                                                        portal before. What is the background on this platform?  </h5></p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <p>A: The Utility Content site started as a simple, searchable file sharing system and has evolved
                                                    into a utility industry platform that automates the transformation of native CAD files into
                                                    precisely prepared models for immediate use within the various AutoCAD software like Inventor
                                                    and AutoCAD Electrical.
                                                    </p>
                                                    <p>
                                                        This platform will not replace your document management system but would be used for designers
                                                        to search for content, download to your internal network and then load into your document
                                                        management system with your own company details on the content.
                                                    </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                    <h5>Q: Our team has been building their own content for a couple of years now, what are the
                                                        benefits of sharing it with others?  </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <p>A: Sharing is reciprocal and participation in this NAUCSI group requires sharing content.
                                                    For another Utility to benefit from your content, they in turn must share with you as well.
                                                    There will be a minimum content size to get involved with this group. That minimum
                                                        threshold is being discussed.</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                    <h5>Q: What are the benefits to sharing our content with others through your Utility Content
                                                        site and not just via some FTP or SharePoint site? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <p>A: Our platform has been developed so that when users download models, it automatically
                                                    applies all the additional intelligence required by both Autodesk software and our SDS
                                                    tools. This eliminates the 5-to-10 additional steps your team would undertake today to
                                                    convert vendor models.
                                                    </p>
                                                    <p>
                                                        If the content is already built for Autodesk and SDS, the system
                                                        still applies standard, Utility Specific templates and build the correct version of the
                                                        content prior to download.
                                                    </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                                    <p><h5>Q: I am concerned that my models will now be “out there” and we will be responsible
                                                        for any defects. How are we protected against this?</h5></p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="5">
                                                <Card.Body>
                                                    <p>A: The content you share will be under strict terms of service outlined on our Utility Content site
                                                        here <a href="https://www.utilitycontent.com/terms">Terms</a> removing all liabilities and users of the content are
                                                        responsible to verify their own design and engineering.
                                                    </p>
                                                    <p>
                                                        Prior to granting login credentials to the
                                                        NAUCSI group, SBS will verify the user credentials and ensure that they are both a member of this
                                                        group and have accepted the terms under the automated sign in process.
                                                    </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                                    <p><h5>Q: Our equipment is made to order. How would others possibly benefit from using it? </h5></p>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="6">
                                                <Card.Body>
                                                    <p>A: Even with Equipment that is made to order specifically for your Utility Company, the designs
                                                    can still provide others with an accelerated start instead of building from a blank screen.
                                                    In specific examples like disconnect switch heights, users can still download the models and
                                                    modify the switches to fit their own needs. They would in-turn, share that new derivative of
                                                        your switch, back with the NAUCSI group further enhancing the library of content. </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                                    <h5>Q: If I discover a critical error in a model I shared, can I replace it with the updated
                                                        model? What if it’s someone else’s shared content? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="7">
                                                <Card.Body>
                                                    <p>A: Yes, you will be able update models you have shared.  If you discover a critical error in
                                                    shared content, let us know and we will evaluate and discuss with the company providing the
                                                        content. </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                                                    <h5>Q: We have a lot of legacy data in other formats like MicroStation. Can we share that content
                                                        as well? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="8">
                                                <Card.Body>
                                                    <p>A: Yes. From inception, the Utility Content platform has been developed with Autodesk Forge
                                                    which enables us to convert to and from various file formats. In fact, we can accept up to
                                                    65 file formats and the platform will translate and convert them to usable content for newer
                                                    Autodesk platforms.
                                                    </p>
                                                    <p>
                                                        Our platform then applies your company specific templates and auto-builds
                                                        the Autodesk software version you need for download.  A good example is taking legacy
                                                        MicroStation cells and our platform automatically builds 3D dwg and Inventor part files
                                                        for use.
                                                    </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                                                    <h5>Q: Can I grant or restrict access to the content I have uploaded to the portal? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="9">
                                                <Card.Body>
                                                    <p>A: The NAUCSI group will be an exclusive group of Utility companies and will not be
                                                    accessed by any other members of our Utility Content site unless specifically requested
                                                        by one of the NAUCSI members with specific criteria.  </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="10">
                                                    <h5>Q: : Does the platform provide any type of reporting services that will provide statics
                                                        concerning my specific content? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="10">
                                                <Card.Body>
                                                    <p>A: Yes. The utility Content platform generates reports related to your content identifying
                                                    download counts, by whom, dates, utility name, content name, version, etc. Our hope is that
                                                    this information will enable further collaboration when Utility members can have some insight
                                                        into other Utility engineers and designers working on similar content.  </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="11">
                                                    <h5>Q: Would the site be able to handle “heavy” 3D models from manufacturers? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="11">
                                                <Card.Body>
                                                    <p>A: Our platform is built on Autodesk Forge which provides file translations for 65 file
                                                    formats including Revit and Infraworks which are extremely large files so all indications
                                                    are that we can indeed take large files and simplify them to usable format. That said,
                                                        we would need to run tests any large content to ensure that it will be properly translated. </p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>

                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="12">
                                                    <h5>Q: Do you have an idea of how much time/effort it will take to have a designer upload
                                                        content? </h5>
                                                </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="12">
                                                <Card.Body>
                                                    <p>A: This is where SBS has done the heaviest lifting so far. The options are;</p>

                                                    <ul>
                                                        1.	The website has an upload feature, but it’s really meant for someone that has updated a single part and wants to share it back or a vendor uploading one model.
                                                            </ul>
                                                    <ul>
                                                        2.	We do have Inventor and AutoCAD tools that can read meta data directly and upload straight from CAD. We can discuss getting your designers using these tools.
                                                            </ul>
                                                    <ul>
                                                        3.	We have a batch loading process where your team can simply fill out a spreadsheet. One row for each item. Provide a batch of files, and we can perform an automated upload. Easy.
                                                            </ul>


                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>




                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br />
                    <hr />
                    <div className="row">

                        <div className="col-md-12 col-centered">
                            <h3 className="text-center">
                                Current NAUCSI Members
			            </h3>
                            <br />
                            <div className="col-md-12">
                                <div className="row text-center">

                                    <div className="col-md-3">
                                        <a className="rounded" href='https://www.entergy.com/' target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_EntergyLogo.png' alt='Entergy' height={50} style={this.style.image} /></a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://www.southerncompany.com/" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_SouthernCoLogo.jpg' alt='Southern Co.' height={35} style={this.style.image} /></a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://www.dominionenergy.com/" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_DOMLogo.png' alt='Dominion Energy' height={75} style={this.style.image} /></a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://www.duke-energy.com/home" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_DukeLogo.png' alt='Duke Energy' height={75} style={this.style.image} /></a>
                                    </div>

                                </div>
                                <div className="row text-center">
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://www.bchydro.com/index.html" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_BCHydroLogo.png' alt='BC Hydro' height={100} style={this.style.image} /></a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://www.eversource.com/content/ema-c" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_EversourceLogo.png' alt='Eversource' height={150} style={this.style.image} /></a>
                                    </div>
                                    <div className="col-md-3">
                                        <a className="rounded" href="https://aep.com/" target="_blank"><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/NAUCSI_AEPLogo.png' alt='AEP' height={125} style={this.style.image} /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <hr />
                    <div className="row">

                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-8">
                                    <h2>Send us a note to learn more!</h2>
                                    {this.showSuccess()}
                                    <form onSubmit={this.sendMessage}>
                                        <div className="form-group">
                                            <label for="recipient-name" className="col-form-label">From (your email):</label>
                                            <input name="from" type="text" value={this.state.from} onChange={this.handleChange("from")} className="form-control" id="recipient-name" />
                                        </div>
                                        <div className="form-group">
                                            <label for="subject" className="col-form-label">Subject:</label>
                                            <input name="subject" value={this.state.subject} onChange={this.handleChange("subject")} type="text" className="form-control" id="subject" />
                                        </div>
                                        <div className="form-group">
                                            <label for="message-text" className="col-form-label">Message:</label>
                                            <textarea name="message" value={this.state.message} onChange={this.handleChange("message")} className="form-control" id="message-text"></textarea>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Send Message</button>
                                    </form>

                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                </div>
                <Footer />
            </Layout>
        )
    }
}

export default Naucsi;