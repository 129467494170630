import React, { useState } from 'react';

import {
    Box, Dialog, DialogActions, DialogContent, DialogTitle,
    Tooltip, TextField, Button, Alert, IconButton, CircularProgress,
    Select, InputLabel, FormControl, MenuItem
} from '@material-ui/core';

import HelpIcon from '@material-ui/icons/Help';
import { isAuthenticated } from '../auth';
import { getPointCloudDetails } from '../api/apiCore';


export default function FormFileDetails({ fileId }) {
    
    const [values, setValues] = useState({
        open: false,
        fileStatus: '',
        version: 1,
        fileName: '',
        fileType: '',
        fileSize: 0,
        uploadedBy: '',
        uploadedByEmail: '',
        uploadedAt: '',
        loading: false
    })

    const {
        open,
        fileStatus,
        version,
        fileName,
        fileType,
        fileSize,
        uploadedBy,
        uploadedByEmail,
        uploadedAt,
        loading,
    } = values;



    const handleClickOpen = () => {
        getAlldetails()
        setValues({ ...values, open: true });
    };

    const handleClose = () => {
        setValues({ ...values, open: false });
    };

    const handleDone = () => {
        setValues({ ...values, open: false });
    };


    const getAlldetails = () => {

        getPointCloudDetails(isAuthenticated().token, fileId, isAuthenticated().userId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
            } else {
                console.log(data)
                setValues({
                    fileStatus: data.fileStatus,
                    version: data.version,
                    fileName: data.fileName,
                    fileType: data.fileType,
                    fileSize: data.fileSize,
                    uploadedBy: data.uploadedBy.name + ' ' + data.uploadedBy.lastName,
                    uploadedByEmail: data.uploadedBy.email,
                    ctaegory: data.category,
                    uploadedAt: data.uploadedAt,
                    loading: false,
                    open: true
                });
            }
        })
    }


    const showLoading = () =>
        loading && (
            <CircularProgress />
        );

    return (
        <>

            {/* <Tooltip title="Add a new project" placement="top">
                <Button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleIcon />}
                >
                    Add Project
                </Button>


            </Tooltip> */}

            <Tooltip
                title="view Details"
                placement="top"
            >
                <IconButton
                    onClick={handleClickOpen}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <HelpIcon />
                </IconButton>
            </Tooltip>


            <Dialog 
                open={open} 
                onClose={handleClose}
            >
                <DialogTitle>File Information</DialogTitle>
                <DialogContent>

                 
                        <br />
                        <TextField
                            disabled
                            label="File Name"
                            value={fileName}
                        />

                        <TextField
                            disabled
                            label="File status"
                            value={fileStatus}
                        />

                        <TextField
                            disabled
                            label="File version"
                            value={version}
                        />
                        <TextField
                            disabled
                            label="File type"
                            value={fileType}
                        />
                        <TextField
                            disabled
                            label="File size"
                            value={fileSize}
                        />

                        <TextField
                            disabled
                            label="Uploaded By"
                            value={uploadedBy}
                        />

                        <TextField
                            disabled
                            label="Uploaded By"
                            value={uploadedByEmail}
                        />

                        {showLoading()}

                  
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDone}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}