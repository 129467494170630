import React, { useState, useEffect } from 'react';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth';
import { Link } from 'react-router-dom';
import { createBulkUpload } from '../api/apiAdmin';

const Bulkupload = () => {
    let fileName = ""

    const { userId, token, name } = isAuthenticated();
    
    
    const clickSubmit = event => {             
        createBulkUpload(userId, token, fileName.files[0]).then(data => {
        });
    };

    // Handle form input changes
    const handleChange = e => {
        fileName= e.target.files[0];        
    };



    return (
        <Layout
            title="Orders"
            description={`Hello ${
                name
            }, You can bulk upload files here for processing`}
            className="container-fluid"
        >
        <div className="row mt-5">
            <div className="col-12">
                <h4>BulkUploads</h4>
                <form className="mb-3" onSubmit={clickSubmit}>
                    <div className="card">
                        <button className="btn btn-outline-primary card-header">Upload File</button>
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h5>csv File</h5>
                                    <div className="form-group">
                                        <label className="btn btn-secondary">
                                            <input onChange={handleChange} type="file" name="fileName" accept=".csv" />
                                            <small id="photoHelp" className="form-text text-warning">Choose any csv file</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </Layout>
    );
};

export default Bulkupload;