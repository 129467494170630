import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { getContents, deleteContent } from "../api/apiAdmin";
// import Pagination from "./Pagination";
import LoadingOverlay from 'react-loading-overlay';
import { useMediaQuery } from 'react-responsive'
import { FaCodepen } from 'react-icons/fa'

let contentLoaded = false;

const ManageContents = () => {
    const [contents, setContents] = useState([]);
    const { userId, token } = isAuthenticated();
    const [values, setValues] = useState({ loading: false });
    const [limit] = useState(15);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [error, setError] = useState(false);
    const [count, setCount] = useState([]);
    const [runningInCAD, setRunningInCAD] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [loading, setLoading] = useState([])

    const countContents = () => {
        //getContents(userId, skip, limit, token).then(data => {
        getContents(userId, 0, 4000, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setCount(data.size);
            }
        });
        contentLoaded = true
    };

    const loadContents = () => {
        getContents(userId, skip, limit, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setContents(data.data);
                setSize(data.size);
                setSkip(0);
                countContents();
            }
        });

    };

    const loadMoreSearchResults = () => {
        let toSkip = skip + limit;
        // console.log(newFilters);
        getContents(userId, toSkip, limit, token).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setContents([...contents, ...data.data]);
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };

    const destroy = contentId => {
        deleteContent(contentId, userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                loadContents();
            }
        });
    };

    useEffect(() => {
        // contentLoaded = false;
        loadContents();
        setLoading(false);
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    // const searchMessage = (contents, values) => {

    //     if (contentLoaded == false) {
    //         return (<div>
    //             <h2 color="#00BFFF">Searching...</h2>
    //             {/* <Loader type="Circles" color="#00BFFF" height={80} width={80} /> */}
    //         </div>)
    //     } else {
    //         if (contents.length > 0) {
    //             return `Total ${contents.length} items shown of ${count} total`;
    //         }
    //         if (contents.length < 1) {
    //             return `Nothing found!`;
    //         }
    //     }
    // };


    const loadMoreSearchButton = (contents) => {
        return (
            size > 0 &&
            size >= limit && (
                <button onClick={loadMoreSearchResults} className="btn btn-warning mb-5">
                    Now showing {contents.length} items, Load more
                </button>
            )
        );
    };

    const onPageChanged = data => {
        // const { allCountries } = this.state;
        // const { currentPage, totalPages, pageLimit } = data;

        // const offset = (currentPage - 1) * pageLimit;
        // const currentCountries = allCountries.slice(offset, offset + pageLimit);

        // this.setState({ currentPage, currentCountries, totalPages });
    };


    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout
                title="Manage Content"
                description="Update or Delete your content!"
                className="container-fluid"
                isMobile={isTabletOrMobile}
            >
                <div className="row">
                    <div className="col-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to admin dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page" >administer content</li>
                            </ol>
                        </nav>
                        <hr />
                        {runningInCAD && <Link to={`/ucInventorParts`} className="mr-2">
                            <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Add New Content <FaCodepen /></button>
                        </Link>
                        }
                        <hr />
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Your Content Description<input type="text" /></th>
                                    <th scope="col">Update</th>
                                    <th scope="col">Delete It</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contents.map((p, i) => (

                                    <tr>
                                        <td>
                                            <Link to={`/content/${p._id}`}>{p.name}</Link>
                                        </td>
                                        <td>
                                            {/* <Link to={`/admin/content/update/${p._id}`}>
                                                <span className="badge badge-warning badge-pill">
                                                    Update
                                                </span>
                                            </Link> */}
                                            <button type="submit" onClick={() => window.location.href = `/admin/content/update/${p._id}`}
                                                className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                                                Update
                                            </button>

                                        </td>
                                        <td>
                                            {/* <span
                                                onClick={() => destroy(p._id)}
                                                className="badge badge-danger badge-pill"
                                            >
                                                Delete
                                            </span> */}
                                            <button type="submit" onClick={() => destroy(p._id)}
                                                className="btn btn-outline-danger mt-2 mb-2 card-btn-1">
                                                Delete
                                            </button>

                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        <div className="mb-4 d-flex justify-content-center">
                            {loadMoreSearchButton(contents)}
                        </div>
                    </div>
                </div>

            </Layout>
        </LoadingOverlay>
    );
};

export default ManageContents;
