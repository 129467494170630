import { API } from "../config";
import queryString from "query-string";

export const getContents = (sortBy, orderBy) => {
    return fetch(`${API}/contents?sortBy=${sortBy}&order=${orderBy}&limit=25`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
export const checkMembership = (vendorId, userId, token) => {
    return fetch(`${API}/checkNAUCSImember/${vendorId}/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const getCategories = () => {
    return fetch(`${API}/categories`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getConfigurators = () => {
    return fetch(`${API}/configurator`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getTags = () => {
    return fetch(`${API}/tags`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getFilteredContents = (skip, limit, filters = {}, fileSearch, description) => {
    const data = { 
        limit,
        skip,
        filters,
        fileSearch,
        description
    };
    // console.log(JSON.stringify(data))

    return fetch(`${API}/contents/by/search`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const listContentsByCatId = (skip, limit, categoryId) => {    
    const data = {
        limit,
        skip,
        categoryId
    };
    console.log(JSON.stringify(data))

    return fetch(`${API}/contents/bycategory`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const countByCatId = (categoryId) => {
    const data = {
        categoryId
    };

    return fetch(`${API}/contents/countbycategory`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const countBySearch = (filters = {}, fileSearch, description) => {
    const data = {
        filters,
        fileSearch,
        description
    };

    return fetch(`${API}/contents/countbysearch`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const list = params => {
    const queryIn = queryString.stringify(params);
    const splitquery = queryIn.split("&")
    const query = splitquery[1] + "&" + splitquery[0]
    console.log("query", query);
    return fetch(`${API}/contents/search?${query}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


// export const fullListSearch = (searchstring, skip, limit) => {

//     return fetch(`${API}/content/searchtext?searchText=${searchstring}&limit=${limit}&skip=${skip}`, {
//         method: "POST"
//     })
//         .then(response => {
//             return response.json();
//         })
//         .catch(err => console.log(err));
// };

export const fullListSearch = (search, skip, limit) => {
    //alert(search + "   :   "   + skip + "   :   "   + limit)
    const data = {
        limit,
        skip,
        search
    };
    
    return fetch(`${API}/contents/search`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const listSearchCount = (search) => {
    const data = {
        search
    };
    
    return fetch(`${API}/contents/searchcount`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


export const read = contentId => {
    return fetch(`${API}/content/${contentId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const readConfigurator = configuratorId => {
    return fetch(`${API}/configurator/${configuratorId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const readVendor = (vendorId, token) => {
    return fetch(`${API}/vendor/${vendorId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const listRelated = contentId => {
    return fetch(`${API}/contents/related/${contentId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createOrder = (userId, token, createOrderData) => {
    return fetch(`${API}/order/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ order: createOrderData })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createDownload = async (userId, token, createDownloadData) => {
    return fetch(`${API}/download/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ download: createDownloadData })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const sendMessage = (message) => {
    return fetch(`${API}/mail/sendinGridEmail`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(message)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const saveVendorMessage = (userId, token, message) => {
    return fetch(`${API}/vendor/message/${userId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(message)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const createDownloadLink = (token, modelLink, filetomake, brand, awsVersion) => {
    // console.log("Sent: " + modelLink + " : " + filetomake+ " : " +  brand+ " : " +  awsVersion)

    const body = {
        modelLink,
        filetomake,
        brand,
        awsVersion
    }

    return fetch(`${API}/content/downloadfile`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => {                  
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createNewPointCloud = async (token, values, userId) => {

    return fetch(`${API}/pointcloud/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(values)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const downloadPointCloud = async(token, fileInfo) => {
    return fetch(`${API}/pointcloud/downloadpointcloudfile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(fileInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllPointCloudsByUser = (token, userId) => {
    return fetch(`${API}/pointcloud/allbyUser/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getAwsPointCloudLink =(token, fileId, userId, fileName) => {
    return fetch(`${API}/pointcloud/awsLink/${fileId}/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({fileName: fileName})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const deletePointCloudFile = async (token, fileId, userId, fileName) => {
    return fetch(`${API}/pointcloud/delete/${fileId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({fileName: fileName})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const getPointCloudDetails =(token, fileId, userId) => {
    return fetch(`${API}/pointcloud/read/${fileId}/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

// All Forma Content in beta R&D as of December 2024


export const createNewFormaContent = async (token, values, userId) => {

    return fetch(`${API}/formacontent/create/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(values)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const downloadFormaContent = async(token, fileInfo) => {
    return fetch(`${API}/formacontent/downloadpointcloudfile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(fileInfo)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllFormaContentByUser = (token, userId) => {
    return fetch(`${API}/formacontent/allbyUser/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const getAwsFormaContentLink =(token, fileId, userId, fileName) => {
    return fetch(`${API}/formacontent/awsLink/${fileId}/${userId}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({fileName: fileName})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};


export const deleteFormaContentFile = async (token, fileId, userId, fileName) => {
    return fetch(`${API}/formacontent/delete/${fileId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({fileName: fileName})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}


export const getFormaContentDetails =(token, fileId, userId) => {
    return fetch(`${API}/formacontent/read/${fileId}/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}