import React from "react";
import { API } from "../config";
import Image from 'react-image-resizer';

const style = {
    image: {      
      background: '#fefefe',
    },
  };

const ShowImage = ({ item, url, size }) => (

    
    <div className="product-img">
        <Image
            src={item.photo}
            alt={item.name}
            className="mb-3"
            width={size}
            height={size}
            style={style.image}
        />

        {/* <img
            src={item.photo}
            alt={item.name}
            className="mb-3"            
        /> */}
    </div>
);

export default ShowImage;
