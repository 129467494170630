import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import FormHelperText from '@material-ui/core/FormHelperText';
import { deletePointCloudFile, getAllPointCloudsByUser } from '../api/apiCore';
import { isAuthenticated } from '../auth';


export default function FormDeleteFileDialog({fileId, fileName, filesRemoved, userId}) {
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const [confirmText, setConfirmText]= React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    
    const handleDelete = () => {
        
        
        if (confirmText === 'permanently delete') {
        alert(fileName)

        deletePointCloudFile(isAuthenticated().token, fileId, isAuthenticated().userId, fileName).then(data => {
            if (data.error) {
                 setSuccess(false)
                 setError(true)
                 setErrorMessage(data.error)
            } else {
                setSuccess(true)
                setError(false)
                setErrorMessage(data.error)
            }
        })
    } else {

    }
        setError(true)
        setErrorMessage('Please enter the text "permanently delete" to confirm')
    };

    const handleClose = () => {
        getAllPointCloudsByUser(userId).then(data => {           
            filesRemoved(data)           
          })
        setOpen(false);
    };

    const showSuccess = () => {
        return (
            <Alert severity="success" color="info">
            File was deleted! Close this dialog to go back to your projects.
          </Alert>
        )
    };

    const showError = () => {
        return (
            <Alert severity="error" color="error">
                {errorMessage}
          </Alert>
        )
    };

    const handleConfirmText = name => event => {        
        setError(false)
        setErrorMessage('')
        setConfirmText(event.target.value);
        
        
    }

    return (
        <>
            <Tooltip title="delete file" placement="top">
                <IconButton
                    onClick={handleClickOpen}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <DeleteIcon />
                </IconButton>
            </Tooltip>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Delete File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you really want to delete this file?
                    </DialogContentText>
                    <TextField                        
                        margin="dense"
                        id="confirmText"                        
                        aria-describedby="filled-weight-helper-text"
                        // label={`Enter the text ${'permanently delete'} to confirm"`}
                        type="text"
                        value={confirmText}
                        variant="standard"
                        onChange={handleConfirmText("confirmText")}
                    />
                    <FormHelperText id="filled-weight-helper-text">{`Enter the text ${'permanently delete'} to confirm"`}</FormHelperText>
                </DialogContent>
                {success && showSuccess()}
                {error && showError()}
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {!success && <Button onClick={handleDelete}>Delete</Button>}
                    {success && <Button onClick={handleClose}>Close</Button>}
                </DialogActions>
            </Dialog>
            
        </>
    );
}