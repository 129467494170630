import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import { getContents } from '../api/apiCore';
import Card from './Card';
import AdvancedSearch from './AdvancedSearch23';
import Footer from "./Footer"
import CookieConsent from "react-cookie-consent";
import ReactGA from 'react-ga';
import ItemsCarousel from 'react-items-carousel';
import './widget.css';
import { useMediaQuery } from 'react-responsive'


const Newhome = () => {
    const [contentsByDownload, setContentsByDownload] = useState([]);
    const [contentsByArrival, setContentsByArrival] = useState([]);
    const [setError] = useState(false);
    const [numItems, setNumItems] = useState(Math.round(window.innerWidth / 400))
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [activePopularIndex, setActivePopularIndex] = useState(0);
    const chevronWidth = 10;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const loadContentsByDownload = () => {
        getContents('downloads', 'desc').then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setContentsByDownload(data);
            }
        });
    };

    const loadContentsByArrival = () => {
        getContents('createdAt', 'desc').then(data => {
            //console.log(data);
            if (data.error) {
                setError(data.error);
            } else {
                setContentsByArrival(data);
            }
        });
    };

   

    useEffect(() => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('email');        
                
        loadContentsByArrival();
        loadContentsByDownload(); 
        function updateItems() {
            setNumItems(Math.round(window.innerWidth / 400)) 
          }
        

        window.addEventListener("resize", updateItems);                     
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/home");

    }, []);

    
    return (
        <Layout
            title="UtilityContent.com"
            description="Welcome to the World's Largest Utility Design Library"
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <CookieConsent
                location="bottom"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "19px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>

            <div>
                <AdvancedSearch />
            </div>
            <h2 className="mb-4 d-flex justify-content-center">Newest Content</h2>
            <div className="container-fluid">
                <div style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={setActiveItemIndex}
                        activeItemIndex={activeItemIndex}
                        numberOfCards={numItems}                        
                        gutter={10}
                        leftChevron={<button className="btn btn-primary">{'<'}</button>}
                        rightChevron={<button className="btn btn-primary">{'>'}</button>}
                        outsideChevron
                        chevronWidth={chevronWidth}
                    >                        
                        {contentsByArrival.map((content, i) => (
                        <div key={i} >
                            <Card
                                cardStyle={{'minWidth': '18rem', 'maxWidth': '28rem'}}                        
                                content={content}
                                imageSize={225}
                                showAddToCartButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? false : true}
                                showNaucsiButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? true : false}
                            />
                        </div>
                    ))}

                    </ItemsCarousel>
                </div>

                {/* <div className="card-deck justify-content-center">
                    {contentsByArrival.map((content, i) => (
                        <div key={i} >
                            <Card
                                cardStyle={{ width: '18rem' }}
                                content={content}
                                imageSize={225}
                                showAddToCartButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? false : true}
                                showNaucsiButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? true : false}
                            />
                        </div>
                    ))}

                </div> */}
                <br />
                <hr />

                <h2 className="mb-4 d-flex justify-content-center">Most Popular This Week</h2>
                <div style={{ padding: `0 ${chevronWidth}px` }}>
                    <ItemsCarousel
                        requestToChangeActive={setActivePopularIndex}
                        activeItemIndex={activePopularIndex}
                        numberOfCards={numItems}
                        gutter={20}
                        leftChevron={<button className="btn btn-primary">{'<'}</button>}
                        rightChevron={<button className="btn btn-primary">{'>'}</button>}                        
                        outsideChevron
                        chevronWidth={chevronWidth}
                    >              
                              
                        {contentsByDownload.map((content, i) => (
                        <div key={i} >
                            <Card
                                cardStyle={{'minWidth': '18rem', 'maxWidth': '28rem' }}
                                content={content}
                                imageSize={225}
                                showAddToCartButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? false : true}
                                showNaucsiButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? true : false}
                            />
                        </div>
                    ))}

                    </ItemsCarousel>
                </div>

                {/* <div className="card-deck justify-content-center">
                    {contentsByDownload.map((content, i) => (
                        <div key={i}>
                            <Card
                                cardStyle={{ width: '18rem' }}
                                content={content}
                                imageSize={225}
                                showAddToCartButton={content.vendor._id === '5ef0fa557693db248cafa992' ? false : true}
                                showNaucsiButton={content.vendor._id === '5ef0fa557693db248cafa992' ? true : false}
                            />
                        </div>
                    ))}

                </div> */}


            </div>
            {/* <Widget
                        handleNewUserMessage={handleNewUserMessage}
                        title="your Content Robot"
                        subtitle="find answers here!"
                    /> */}
            <Footer />
        </Layout>
    );
};

export default Newhome;
