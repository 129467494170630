import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { createTag } from "../api/apiAdmin";
import { getTags } from "../api/apiCore";
import { useMediaQuery } from 'react-responsive'


const UpdateTags = () => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const loadTags = () => {
        getTags().then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                //console.log(data);
                setData({ ...data, tags: data });
            }
        });
    };


    const [data, setData] = useState({
        tags: [],
        tag: "",
        search: "",
        results: [],
        selectedOption: null,
        searched: false
    });

    useEffect(() => {
        loadTags();
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    const { tags } = data;

    // const catOptions = (
    //     tags.map((c, i) => {
    //         return {
    //             value: c.name,
    //             label: c.name
    //         }
    //     })
    // )

    // ==================================================
    const [tagName, setTagName] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [runningInCAD, setRunningInCAD] = useState(false);

    // destructure user and token from localstorage
    const { userId, token, name } = isAuthenticated();

    const handleChange = e => {
        setError("");
        setTagName(e.target.value);
    };

    // const handleSearchChange = (data) => {
    //     setTagName(data.value);

    // }

    const clickSubmit = e => {
        e.preventDefault();
        setError("");
        setSuccess(false);
        // make request to api to create category
        createTag(userId, token, { tagName }).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setError("");
                setSuccess(true);
                loadTags();
            }
        });
        
    };

    const newTagForm = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={tagName}
                    autoFocus
                    required
                />
            </div>
            <button className="btn btn-outline-primary">Create Tag</button>
        </form>
    );

    const existingTagList = () => {

        if (tags.length > 0) {
            return (
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Tag Name</th>
                            <th scope="col"></th>

                        </tr>
                    </thead>
                    <tbody>
                        {tags.map((p, i) => (
                            <tr>
                                <td>{p.name}</td>
                                <td>manage (super admin only)</td>
                                <td>delete (super admin only)</td>
                            </tr>

                        ))}
                    </tbody>
                </table>
            );
        }
    };

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">{tagName} is created</h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger">Tag should be unique</h3>;
        }
    };

    const goBack = () => (
        <div className="mt-5">
            <Link to="/admin/dashboard" className="text-warning">
                Back to Dashboard
            </Link>
        </div>
    );

    return (
        <Layout
            title="Manage Content Tags"
            description={`Hi ${name}, to admin content tags you must be super admin but, you can add new ones unique to your content?`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
          
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to admin dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page" >administer tags</li>
                        </ol>
                    </nav>
                    {showSuccess()}
                    {showError()}
                    {newTagForm()}
                    {goBack()}
                    {existingTagList()}
                </div>
            </div>
        </Layout>
    );
};

export default UpdateTags;
