import React, { useState, useEffect } from 'react';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth';
import { Link, Redirect } from 'react-router-dom';
import { read, update, updateUser } from '../api/apiUser';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'

const Profile = ({ match }) => {
    const [values, setValues] = useState({
        name: '',
        lastName: '',
        companyName: '',
        companyType: '',
        password: '',
        error: '',
        updatedProfile: ''
    });

    const { token } = isAuthenticated();
    const { name, lastName, companyName, companyType, password, error, updatedProfile } = values;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const init = userId => {
        // console.log(userId);
        read(userId, token).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({ ...values, name: data.name, lastName: data.lastName, companyName: data.companyName, companyType: data.companyType });
            }
        });
        // if (window.uchInventorInterface) {
        //     setRunningInCAD(true)
        // }

    };

    useEffect(() => {
        init(match.params.userId);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/profile/" + match.params.userId);
    }, []);

    const handleChange = name => e => {
        setValues({ ...values, error: '', [name]: e.target.value });
    };

    const clickSubmit = e => {
        e.preventDefault();
        //alert(name + ':' + lastName + ':' + companyName + ':' + companyType + ':' + email + ':' + password)
        update(match.params.userId, token, { name, lastName, companyName, companyType }).then(data => {
            if (data.error) {
                // console.log(data.error);
                //alert(data.error);
                setValues({ ...values, error: data.error })
            } else {
                updateUser(data, () => {
                    setValues({
                        ...values,
                        name: data.name,
                        lastName: data.lastName,
                        companyName: data.companyName,
                        companyType: data.companyType,
                        error: '',
                        updatedProfile: data.name
                    });
                });
            }
        });
    };

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: updatedProfile ? '' : 'none' }}>
            <h2>{`${updatedProfile}`}'s profile' is updated!</h2>
        </div>
    );

    const profileUpdate = (name, lastName, companyName, companyType, password) => (
        <form>
            <div className="form-group">
                <label className="text-muted">First Name</label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} />
            </div>

            <div className="form-group">
                <label className="text-muted">Last Name</label>
                <input onChange={handleChange('lastName')} type="text" className="form-control" value={lastName} />
            </div>

            <div className="form-group">
                <label className="text-muted">Company Name</label>
                <input onChange={handleChange('companyName')} type="text" className="form-control" value={companyName} />
            </div>

            <div className="form-group">
                <label className="text-muted">Select a company "Type"</label>
                <select onChange={handleChange('companyType')} value={companyType} className="form-control">
                    <option value="" selected></option>
                    <option value="Utility">Utility Company</option>
                    <option value="Services">Engineering Services Provider</option>
                    <option value="Vendor">Vendor</option>
                </select>
            </div>

            <button onClick={clickSubmit} className="btn btn-primary">
                Submit
            </button>
        </form>
    );

    return (
        <Layout
            title="Profile"
            description="Update your profile"
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >{`${isAuthenticated().name}'s Profile Update`}</li>
                    </ol>
                </nav>
                <br />
                <h2 className="mb-4">Profile update</h2>
                {showError()}
                {showSuccess()}
                {profileUpdate(name, lastName, companyName, companyType, password)}
            </div>
        </Layout>
    );
};

export default Profile;
