import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Layout from "../core/Layout";
import { ucSignIn, authenticate, isAuthenticated, getIpData } from "../auth";
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import Footer from "../core/Footer";

const Signin = () => {
    const [values, setValues] = useState({
        password: "",
        email: "",
        SBSEmail: '',
        SBSPassword: '',
        error: "",
        loading: false,
        redirectToReferrer: false
    });

    const { email, password, loading, error, redirectToReferrer } = values;

    const handleChange = name => event => {
        const input = event.target;
        const value = event.target.type === 'checkbox' ? input.checked : input.value;

        setValues({ ...values, error: false, [name]: value });
    };

    const [success, setSuccess] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [runningInCAD, setRunningInCAD] = useState(false);

    const [ipInfo, setIpInfo] = useState('');

    useEffect(() => {
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }

        getIpData().then(data => {
            if (data) {
                // alert(data.IPv4)
                setIpInfo(data);
            }
        });

    }, []);

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });

        ucSignIn({ email, password }).then(data => {

            if (data.error) {
                setValues({ ...values, error: data.error, loading: false });
            } else {
                authenticate(data, () => {
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });
                });
            }
        });
    };

    const signUpForm = () => (
        <form>

            <div className="form-group">
                <label className="text-muted">Email</label>
                <input
                    onChange={handleChange("email")}
                    type="email"
                    className="form-control"
                    value={email}
                />
            </div>

            <div className="form-group">
                <label className="text-muted">Password</label>
                <input
                    onChange={handleChange("password")}
                    type="password"
                    className="form-control"
                    value={password}
                />
            </div>

            <div className="form-group">
                <input name="rememberMe" onChange={handleChange("rememberMe")} type="checkbox" /> Remember me
            </div>

            <button onClick={clickSubmit} className="btn btn-primary">
                Submit
            </button>

        </form>
    );



    const showError = () => (
        <div
            className="alert alert-danger"
            style={{ display: error ? "" : "none" }}
        >
            {error}
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-info">
                <h2>Loading...</h2>
            </div>
        );


    const redirectUser = () => {

        if (redirectToReferrer) {

            if (isAuthenticated() && (isAuthenticated().role === "admin" || isAuthenticated().role === "vendor")) {
                return <Redirect to="/admin/dashboard" />;
            } else {
                return <Redirect to="/user/dashboard" />;
            }
        }
        if (isAuthenticated()) {
            return <Redirect to="/" />;
        }
    };

    return (
        <Layout
            title="Signin"
            description="Signin to the World's Largest Utilty Content Library"
            className="container-fluid"
            cadType={runningInCAD}
        >
            <CookieConsent
                location="bottom"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "19px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>

            {/* <ReactTooltip id='SBSLoginHelp' type='dark'>
                <span>If you have an SBS account, use it to login!</span>
            </ReactTooltip> */}

            <div className="container col-md-6 offset-md-3">

                <br />
                
                {showLoading()}
                {showError()}
                {signUpForm()}
                <hr />
                <Link to="/signup">Not registered? Sign up here.</Link>
                {redirectUser()}
                <hr />
                <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            <br />
            <Footer />
        </Layout>
    );
};

export default Signin;
