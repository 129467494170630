import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import Footer from "./Footer"

const Privacy = () => {

    const [runningInCAD, setRunningInCAD] = useState(false);

    useEffect(() => {
        
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    return (

        <Layout
            title="SBS Privacy Policy"
            description="Version: November 2017"
            className="container-fluid"
            cadType={runningInCAD}
        >
            <div className="mbr-section__container container">
                <div className="mbr-section__row row">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <p>
                                    <h4>Spatial Business Systems, Inc, (“Company”, “we” or “us”)</h4> is committed to protecting your privacy. We prepared this privacy policy (“Privacy Policy”) to describe our practices regarding the information we collect from users of our website, located at www.UtilityContent.com (the “Site”). By submitting information through our Site or in connection with any services we provide you, you agree to the terms of this Privacy Policy (except to the extent of contrary provisions contained in any written agreement between you and us) and you expressly consent to the processing of your information in accordance with this Privacy Policy.
                            </p>
                                <h4 className="mbr-header__text">Types of Information We Collect.</h4> The following are the types of information about you that we collect:
                            <p>
                                    Information you give to us. We receive and securely store the information you provide to us when you register for, or otherwise use, the Site such as your name, email address, phone number, address, information and any feedback, questions, or recommendations you provide. We may invite comments, questions, or feedback on certain public areas of the Site. If you provide information in public areas of the Site (if any), such information may be read, collected and used by us and other users of the Site. If you provide information directly to us in other ways, such as by sending us an email, we may retain that information and add it with the other information we have collected from you.
                            </p>
                                <p>
                                    Types of information collected by technology. We may receive and store certain types of information from you each time you use the Site. For example we may automatically log your browser type, language preference, precise geographic location, the date and time of your visit to the Site, any actions you take on the Site, the date and time of your visit to specific listings on the Site, your Internet Protocol (IP) address, and the website you visited before our Site. In addition, we may receive and store information about your use of the Site. The technologies we use for this automatic data collection may include:
                            </p>
                                <p>
                                    <ul>
                                        <li>
                                            Cookies. Cookies are small data files stored on your hard drive by a website. Among other things, cookies help us improve our Site and improve your experience with our Site. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer until you delete them).You can set your browser to not accept cookies, but that may prevent us from providing you with the full range of services we offer. Also, please be aware that if you visit other websites you may be required to accept cookies. The use of cookies by third parties is not covered by our Privacy Policy.
                                    </li>
                                        <li>
                                            Web Beacons. Web beacons are electronic images that may be used on our Site or in our emails. We use web beacons to deliver cookies, count visits, understand usage and campaign effectiveness and to tell if an email has been opened and acted upon.
                                    </li>
                                        <li>
                                            Other. We may also use clear gifs or other data files or identifiers to collect information for statistical purposes and to improve and develop the Site or the services we offer. Clear gifs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are placed on a hard drive, clear gifs are embedded invisibly on web pages.
                                    </li>
                                    </ul>
                                </p>
                                <p>
                                    Analytics: We use Google Analytics on our Site. Google Analytics is a web analytics service provided by Google. Google Analytics uses cookies to collect anonymous traffic data to help use analyze how users use the Site. The information generated by a cookie about your use of the Site (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of the Site, compiling reports on Site activity for us and providing other services relating to Site activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. By using the Site, you consent to the processing of data about you by Google in the manner and for the purposes described in this Privacy Policy.
                            </p>
                                <p>
                                    Do Not Track. We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). We do not respond to browser-based “do not track” signals. Some third party sites who push content to our Site may keep track of your browsing activities over time and across different websites when they serve you content, which enables them to tailor what they present to you.
                            </p>
                                <p>
                                    <b>Use of Your Data.</b> In general, information you submit to us is used either to respond to requests that you make, or to aid us in serving you better. Company uses your information: for research and marketing purposes; to facilitate the creation of your account on our network; identify you as a user in our system; to provide improved administration of our Site; to improve the quality of your experience when you interact with our Site; to send you e-mail notifications; to respond to your inquiries; to send promotional communications; to send upgrades and special offers related to our services and for other marketing purposes of Company or our third party companies; to make telephone calls to you, from time to time, as a part of secondary fraud protection; and to solicit your feedback. We may create anonymous or aggregated data records from your information by excluding information that makes the data personally identifiable to you (such as your name) or by compiling information about you with information from other users. We use this anonymous or aggregated data to analyze request and usage patterns so that we may enhance the content of our services and improve Site navigation. We reserve the right to use and disclose anonymous and aggregate data to third party companies for any purpose in our sole discretion. If you provide feedback on any of our services to us, we may use such feedback for any purpose, provided we will not associate such feedback with your information.
                            </p>
                                <p>
                                    <h4 className="mbr-header__text"> Disclosure of Your Information.</h4>
                                </p>
                                <p>
                                    Affiliates. We may share some or all of your information with any other company that directly or indirectly controls, is controlled by or is under common control with Company (each an “Affiliate”), if any, in which case we will require our Affiliates to honor this Privacy Policy. If another company acquires Company or our assets, or an Affiliate or its assets, that company will possess the information we’ve collected and will assume the rights and obligations described in this Privacy Policy.
                            </p>
                                <p>
                                    Other Disclosures. Regardless of any choices you make regarding your information (as described below), Company may disclose information if it believes in good faith that such disclosure is necessary to (a) comply with relevant laws or to respond to subpoenas or warrants served on Company; or (b) protect or defend the rights or property of Company, users of the Site, or any other party.
                            </p>
                                <p>
                                    Disclosure to Third Parties. We may share your information with third party service providers that are contractually bound to safeguard your information. We may make such disclosure in order to provide you with services, or to conduct quality assurance testing. We may also share your information with any other party with your express consent.
                            </p>
                                <p>
                                    <b>Notice to California Residents – Your California Privacy Rights.</b> Residents of the State of California may request a list of all third parties to which Company has disclosed certain information collected in connection with the Site during the preceding year for the third parties’ direct marketing purposes. If you are a California resident and want such a list, please contact us. For all requests, you must put the statement “Your California Privacy Rights” in the body of your request and include the name of this Site, as well as your name, street address, city, state, and zip code. Please note that we will not accept requests via the telephone, mail, or by facsimile, and we are not responsible for notices that are not labeled or sent properly, or that do not have complete information.
                            </p>
                                <p>
                                    <b>Third Party Websites.</b> We may link to or frame third party websites. Our provision of a link to or frame of any other website or location is for your convenience and does not signify our endorsement of such other website or location or its contents. When you click on such a link, you will leave our Site and go to another site. If we display third party websites through a frame, you are on another site. During this process, another entity may collect information from you. We have no control over, do not review, and cannot be responsible for, these outside websites or their content or their privacy practices. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or content, or framed websites, or to any collection of data after you click on links to such outside websites.
                            </p>
                                <p>
                                    <h4 className="mbr-header__text">Your Choices Regarding Your Information.</h4>
                                </p>
                                <p>
                                    <b>Choices.</b> We offer you choices regarding the collection, use, and sharing of your information. When you receive newsletters or promotional communications from us, you may indicate a preference to stop receiving further communications from us and you will have the opportunity to “opt-out” by following the unsubscribe instructions provided in the e-mail you receive. Should you decide to opt-out of receiving future mailings, we may share your e-mail address with third parties to ensure that you do not receive further communications from these third parties. Despite your indicated e-mail preferences, we may send you emails related to your account or transactions thereunder, or notices of any updates to our Terms of Use or Privacy Policy.
                            </p>
                                <p>
                                    <b>Changes to Information.</b> You may change any of your information in your account by sending an e-mail to us at info@spatialbiz.com or by editing your profile on the Site. You may request deletion of your information by us, but please note that we may be required (by law or otherwise) to keep this information and not delete it (or to keep this information for a certain time, in which case we will comply with your deletion request only after we have fulfilled such requirements). When we delete any information, it will be deleted from the active database, but may remain in our archives.
                            </p>
                                <p>
                                    <b>Security of Your Information.</b> We are committed to protecting the security of your information. We use a variety of industry-standard security technologies and procedures to help protect your information from unauthorized access, use, or disclosure. Despite these measures, you should know that we cannot fully eliminate security risks associated with information and mistakes may happen.
                            </p>
                                <p>
                                    <b>Dispute Resolution.</b> If you have any questions or concerns, please contact us by e-mail at info@spatialbiz.com.  We will do our best to address your concerns. If you feel that your complaint has been addressed incompletely, we invite you to let us know for further investigation.
                            </p>
                                <p>
                                    <b>A Note About Children.</b> We do not intentionally gather information about visitors who are under the age of 18. If you are under the age of 18 you are not permitted to use our Site.
                            </p>
                                <p>
                                    <b>Changes to This Privacy Policy.</b> This Privacy Policy is subject to occasional revision, and if we make any substantial changes in the way we use your information, we will notify you by sending you an e-mail to the last e-mail address you provided to us and/or by prominently posting notice of the changes on our Site. Any material changes to this Privacy Policy will be effective upon the earliest of thirty (30) calendar days following our dispatch of an e-mail notice to you or thirty (30) calendar days following our posting of notice of the changes on our Site. These changes will be effective immediately for new users of our Site. Changes to this Privacy Policy may affect our use of information that you provided us prior to our notification to you of the changes. If you do not wish to permit changes in our use of your information, you must notify us prior to the effective date of the changes that you wish to deactivate your account with us. Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to such changes.
                            </p>
                                <p>
                                    <b>Contact Information.</b> We welcome your comments or questions regarding this Privacy Policy. Please e-mail us.
                            </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Footer />
        </Layout>
    )
};

export default Privacy;