import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ShowImage from './ShowImage';
import moment from 'moment';
import { addItem, updateItem, removeItem } from './cartHelpers';
import Image from 'react-image-resizer';
import { FaSearch, FaFileDownload } from 'react-icons/fa'
import { isAuthenticated } from "../auth";


const Card = ({
  content,
  imageSize,
  cardStyle,
  fullDetail = false,
  showViewContentButton = true,
  showAddToCartButton = true,
  showNaucsiButton = false,
  cartUpdate = false,
  showRemoveContentButton = false,
  setRun = f => f,
  run = undefined
  // changeCartSize
}) => {
  const [redirect, setRedirect] = useState(false);
  const [count, setCount] = useState(content.downloads);

  const showViewButton = showViewContentButton => {
    return (
      showViewContentButton && (
        <Link to={`/content/${content._id}`} className="mr-2">
          <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Details <FaSearch /></button>
        </Link>
      )
    );
  };
  const addToCart = () => {
    // console.log('added');
    addItem(content, setRedirect(true));
  };

  const shouldRedirect = redirect => {
    if (redirect) {
      return <Redirect to="/cart" />;
    }
  };

  const { user, token } = isAuthenticated();

  const showNaucsiBtn = showNaucsiButton => {
    return (
      showNaucsiButton && (
        <button disabled  className="btn btn-outline-success mt-2 mb-2 card-btn-1">NAUCSI group</button>
      )
    );
  };


  const showAddToCartBtn = showAddToCartButton => {
    return (
      showAddToCartButton && (
        <button onClick={addToCart} className="btn btn-outline-success mt-2 mb-2 card-btn-1">download<FaFileDownload /></button>
      )
    );
  };

  const showDownloads = showDownloads => {
    return (
      <span className="badge badge-primary badge-pill">{content.downloads} downloads </span>
    );
  };


  const handleChange = contentId => event => {
    setRun(!run); // run useEffect in parent Cart
    setCount(event.target.value < 1 ? 1 : event.target.value);
    if (event.target.value >= 1) {
      updateItem(contentId, event.target.value);
    }
  };

  // const showCartUpdateOptions = cartUpdate => {
  //   return (
  //     cartUpdate && (
  //       <div>
  //         <div className="input-group mb-3">
  //           <div className="input-group-prepend">
  //             <span className="input-group-text">Adjust Quantity</span>
  //           </div>
  //           <input type="number" className="form-control" value={count} onChange={handleChange(content._id)} />
  //         </div>
  //       </div>
  //     )
  //   );
  // };

  const VerifiedContent = () => {
    if (content.verifiedByManufacturer === true) {
      return 'Verified by Manufacturer!'
    } else {
      return 'Not Verified by Manufacturer!'
    }
  };

  const getLinks = () => {
    if (content.Links.length === 0) {
      return "no web links"
    } else {
      return <a href={content.Links[0]} target="_blank">{content.Links[0]}</a>
    }
  };

  const showFullDetail = showFullDetail => {

    return (
      showFullDetail && (

        <ul className="list-group">
          <h4>Content Details</h4>
          <a className="list-group-item">
            <div className="bmd-list-group-col">
              <p className="list-group-item-heading"><b>Manufacturer details</b></p>
              <p className="list-group-item-text">uploaded by {content.user.email} from {content.vendor.name}</p>
              <p className="list-group-item-text">Manufactured in: {content.manufacture_country}, Designed in: {content.design_country}</p>
              <p className="list-group-item-text">{VerifiedContent()}</p>
              <p className="list-group-item-text">Posted in Category: {content.category.name}</p>
              <p className="list-group-item-text">Posted on: {Date(content.createdAt)}</p>
            </div>
          </a>
          <a className="list-group-item">
            <div className="bmd-list-group-col">
              <p className="list-group-item-heading"><b>Content Details</b></p>
              <p className="list-group-item-text">Content Version Number: {content.edition_number}</p>
              <p className="list-group-item-text">Product Family: {content.product_family}</p>
              <p className="list-group-item-text">Product Group: {content.product_group}</p>
              <p className="list-group-item-text">Unique Reference: {content.unique_product_ref}</p>
            </div>
          </a>
          <a className="list-group-item">
            <div className="bmd-list-group-col">
              <p className="list-group-item-heading"><b>Other Details</b></p>
              <p className="list-group-item-text">{getLinks()}</p>
              <p className="list-group-item-text">comments on CAD file: {content.cad_comments}</p>
            </div>
          </a>
        </ul>
      )
    )
  };

  const showRemoveButton = showRemoveContentButton => {
    return (
      showRemoveContentButton && (
        <button
          onClick={() => {
            removeItem(content._id);
            setRun(!run); // run useEffect in parent Cart
          }}
          className="btn btn-outline-danger mt-2 mb-2"
        >
          Remove content
        </button>
      )
    );
  };

  function roundUsing(func, number, prec) {
    var tempnumber = number * Math.pow(10, prec);
    tempnumber = func(tempnumber);
    return tempnumber / Math.pow(10, prec);
  }

  const style = {
    image: {
      background: '#fefefe'
    },
  };

  // function getLogoUrl(brandName) {
  //   let logoUrl = "https://utilitycontentsite.s3.amazonaws.com/vendor_logos/" + brandName + "_LOGO.png"

  //   return <Image
  //     src={logoUrl}
  //     alt={brandName}
  //     height={20}
  //     style={style.image}
  //     />
  // };


  return (
    <div className="card" style={cardStyle}>
      <h5 className="card-header ">{content.name}</h5>
      <div className="card-body" >
        {shouldRedirect(redirect)}
        <a href={`/vendor/${content.vendor._id}`} ><Image src={content.vendor.logo} alt={content.vendor.name} height={20} width={100} style={style.image} /></a>
        <ShowImage item={content} url="content" size={imageSize} />
        <div className="card-content">
          <p className="card-content  mt-2"><b>{content.description.substring(0, 40)} ...</b></p>
          <p className="card-content black-10"> {content.content_group}</p>
          <p className="card-content black-10"> File Size: {roundUsing(Math.floor, (content.fileSize / 1000000), 2)} Mb</p>
          <p className="text-info">Added {moment(content.createdAt).fromNow()}</p>
          <p className="text-info">{showDownloads(content.downloads)}</p>
          <div className="card-footer ">
            <div className="row">
              <div className="col-md-6">
                {showViewButton(showViewContentButton)}
              </div>
              <div className="col-md-6">
                {showAddToCartBtn(showAddToCartButton)}
                {showNaucsiBtn(showNaucsiButton)}
                {showRemoveButton(showRemoveContentButton)}
              </div>
            </div>
          </div>

        </div>

      </div>
      {showFullDetail(fullDetail)}
    </div>
  );
};

export default Card;
