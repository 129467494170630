import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Layout from './Layout';
import { readVendor, fullListSearch, sendMessage, saveVendorMessage } from '../api/apiCore';
import Card from './Card';
import { isAuthenticated } from "../auth";
import moment from "moment";
import Image from 'react-image-resizer';
import ReactGA from 'react-ga';

const Vendor = props => {
    const [vendor, setVendor] = useState({});
    const [values, setValues] = useState({ to: vendor.contactEmail, message: '', subject: '', requestType: '' })
    //const { to, message, subject } = values;
    const [contentsByVendor, setContentsByVendor] = useState([]);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const vendorId = props.match.params.vendorId;

    const loadSingleVendor = vendorId => {
        readVendor(vendorId, isAuthenticated().token).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setVendor(data);                
                loadContentsByVendor(data.name);
            }
        });
    };

    // function getVendorIdbyName(brandName) {    
    //     readVendor(brandName).then(data => {      
    //       //alert(data[0]._id)
    //       return data[0]._id
    //     });
    //   };


    const loadContentsByVendor = (vendorName) => {
        vendorName = vendorName.replace(" ", "_")
        fullListSearch(vendorName, 0, 4).then(data => {
            console.log(data);
            if (data.error) {
                setError(data.error);
            } else {
                setContentsByVendor(data.data);
            }
        });
    };

    useEffect(() => {
        loadSingleVendor(vendorId);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/vendor/" + vendorId);
    }, [props]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showRequest, setshowRequest] = useState(false);
    const handleCloseRequest = () => setshowRequest(false);
    const handleShowRequest = () => setshowRequest(true);



    // destructure user and token from localstorage
    const { userId, token, email } = isAuthenticated();

    const handleChange = name => event => {
        let value = "";
        value = event.target.value
        setValues({ ...values, [name]: value });
    };

    const clickRequestSubmit = e => {
        e.preventDefault();
        setError("");
        setSuccess(false);

        const mailMessage = {
            "subject": `${values.subject}`,
            "from": email,
            "to":vendor.contactEmail,
            "htmlContent": `<html><body>From user ${this.state.from},<p>${values.message + ".  " + values.requestType}</p></body></html>`
        }

        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            // if (data.error) {
            //     setError(data.error);
            // } else {
            setError("");
            handleCloseRequest();
            setSuccess(true);
            // }
        });

        const VendorSavedMessage = {
            vendorId: vendorId,
            messages: [{
                to: vendor.contactEmail,
                from: email,
                subject: values.subject,
                Message: values.message + ".  " + values.requestType,
                dateSent: moment().format("DD-MM-YYYY hh:mm:ss")
            }]
        }
        // make request to api to send message
        saveVendorMessage(userId, token, VendorSavedMessage).then(data => {
            setSuccess(true);
            // }
        });
    };


    const clickMessageSubmit = e => {
        e.preventDefault();
        setError("");
        setSuccess(false);

        const mailMessage = {
            "to": vendor.contactEmail,
            "from": email,
            "subject": values.subject,
            "Message": values.message,
            "dateSent": moment().format("DD-MM-YYYY hh:mm:ss")
        }
        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            // if (data.error) {
            //     setError(data.error);
            // } else {
            setError("");
            handleClose();
            setSuccess(true);
            // }
        });

        const VendorSavedMessage = {
            vendorId: vendorId,
            messages: [{
                to: vendor.contactEmail,
                from: email,
                subject: values.subject,
                Message: values.message,
                dateSent: moment().format("DD-MM-YYYY hh:mm:ss")
            }]
        }
        // make request to api to send message
        saveVendorMessage(userId, token, VendorSavedMessage).then(data => {
            setSuccess(true);
            // }
        });

    };

    const style = {
        image: {
            background: '#fefefe',
        },
    };

    return (
        <Layout
            title={vendor.longName}
            description='Vendor Showcase'
            className="container"
        >
            <div className="row">

                <div className="col-md-7">
                    <Image src={vendor.logo} alt={vendor.name} height={40} style={style.image} />
                    <hr />
                    <p>
                        {vendor.about}</p>
                </div>
                <div className="col-md-2">

                </div>
                <div className="col-md-3">
                    <div className="row">
                        <Button variant="btn btn-sm btn-outline-primary" onClick={handleShow} style={{ width: "240px" }}>
                            Contact this partner</Button>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Send Message to {vendor.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">Recipient:</label>
                                        <input name="to" disabled="true" value={vendor.contactEmail} type="text" className="form-control" id="recipient-name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="subject" className="col-form-label">Subject:</label>
                                        <input name="subject" onChange={handleChange("subject")} type="text" className="form-control" id="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Message:</label>
                                        <textarea name="message" onChange={handleChange("message")} className="form-control" id="message-text"></textarea>
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close</Button>
                                <Button variant="primary" onClick={clickMessageSubmit}>
                                    Send Message</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <br></br>
                    <div className="row">
                        <Button variant="btn btn-sm btn-outline-primary" onClick={handleShowRequest} style={{ width: "240px" }}>
                            Can't find content? Request it!</Button>

                        <Modal show={showRequest} onHide={handleCloseRequest}>
                            <Modal.Header closeButton>
                                <Modal.Title>Send Request to {vendor.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">Recipient:</label>
                                        <input name="to" disabled="true" value={vendor.contactEmail} type="text" className="form-control" id="recipient-name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="subject" className="col-form-label">Subject:</label>
                                        <input name="subject" onChange={handleChange("subject")} type="text" className="form-control" id="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Type of request:</label>
                                        <select onChange={handleChange('requestType')} value={values.requestType} className="form-control">
                                            <option value="Inventor Files" selected>Inventor Files</option>
                                            <option value="CAD Neutral formats">CAD Neutral formats</option>
                                            <option value="AutoCAD 3D">AutoCAD 3D</option>
                                            <option value="AutoCAD 2D">AutoCAD 2D</option>
                                            <option value="Catalog Cut Sheets">Catalog Cut Sheets</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Message:</label>
                                        <textarea name="message" onChange={handleChange("message")} className="form-control" id="message-text"></textarea>
                                    </div>
                                </form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseRequest}>
                                    Close</Button>
                                <Button variant="primary" onClick={clickRequestSubmit}>
                                    Send Message</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
            <h2 className="mb-4 d-flex justify-content-center">Sample {vendor.name} Content</h2>
            <div className="container-fluid">
                <div className="card-deck justify-content-center">
                    {contentsByVendor.map((content, i) => (
                        <div key={i} >
                            <Card
                                cardStyle={{ width: '15rem' }}
                                content={content}
                                imageSize={200}
                                showAddToCartButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? false : true}
                                showNaucsiButton = {content.vendor._id === '5ef0fa557693db248cafa992' ? true : false}
                            />
                        </div>
                    ))}

                </div>
                <br />
                <div className="col-md-5">
                    <a href="/browse" className="btn btn-primary btn-sm btn-block">Browse All</a>
                </div>
                <br />
                
            </div>
        </Layout>

    );
};

export default Vendor;
