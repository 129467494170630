import React, { Fragment } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { itemTotal } from "./cartHelpers";
import Logo from "../images/ContentLogo.png";
import { signout, isAuthenticated } from "../auth";
import { useHistory } from "react-router-dom";


export default props => {

  // const userId = isAuthenticated() && isAuthenticated().userId;

  let history = useHistory();

  const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return { color: "#269ded", 'fontWeight': "bolder", textDecoration: 'underline' };
    } else {
      return { color: "white" };
    }
  };

  return (
    // core menu listings for all users
    <Menu {...props}>
      <img alt="" src={Logo} style={{ maxHeight: "30px" }} />

      <Link className="p-2" style={isActive(history, "/")} to="/">Home</Link>
      <Link className="p-2" style={isActive(history, "/browse")} to="/browse">Browse</Link>
      <Link className="p-2" style={isActive(history, "/configurators")} to={`/configurators`}>Configurators</Link>
      <Link className="p-2" style={isActive(history, "/cart")} to="/cart">
        Ready to Download{" "}<sup><small className="badge badge-danger ml-2">{itemTotal()}</small></sup></Link>

      {/* if the user is logged in "authenticated" */}
      {isAuthenticated() && isAuthenticated().role === "subscriber" &&<Link className="p-2" style={isActive(history, "/downloads")} to="/downloads">Download History</Link>}
      {isAuthenticated() && isAuthenticated().role === "subscriber" &&<Link className="p-2" style={isActive(history, "/user/dashboard")} to="/user/dashboard">Dashboard</Link>}
     
      {/* if the user is logged in "authenticated" AND an admininstrator*/}
      {isAuthenticated() && isAuthenticated().role === "admin" && <Link className="p-2" style={isActive(history, "/admin/dashboard")} to="/admin/dashboard">Dashboard</Link>}
      {isAuthenticated() && isAuthenticated().role === "vendor" && <Link className="p-2" style={isActive(history, "/admin/dashboard")} to="/admin/dashboard">Dashboard</Link>}

      {isAuthenticated() && (
        <span className="p-2" style={{ cursor: "pointer", color: "#ffffff" }} onClick={() => signout(() => {
          history.push("/");
        })}>Signout
        </span>
      )}

      <hr />
      {!isAuthenticated() && (
        <Fragment>
          <Link className="p-2" style={isActive(history, "/signin")} to="/signin">Signin</Link>
          <Link className="p-2" style={isActive(history, "/signup")} to="/signup">Signup</Link>
        </Fragment>
      )}
      <hr />
      <Link className="badge badge-warning p-2" style={isActive(history, "/gethelp")} to="/gethelp">help</Link>

    </Menu>
  );
};