/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { Menu, MenuItem, Button, Avatar, Chip } from '@material-ui/core';
import { signout, isAuthenticated } from "../auth";
import { useHistory } from "react-router-dom";
import InputIcon from '@material-ui/icons/InputOutlined'


export default function ProfileMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let history = useHistory();


    return (
        <div>

            {/* <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                Dashboard
            </Button> */}
            {isAuthenticated() &&
                <a href="#">
                    <Avatar
                        id="avatarLogin"
                        src="/broken-image.jpg"
                        onClick={handleClick}
                    />
                </a>
            }
            {isAuthenticated() && isAuthenticated().role === "subscriber" && (
                <>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick="#">{`${isAuthenticated().name}'s account`}</MenuItem>
                        <hr/>
                        <MenuItem onClick={() => { history.push("/user/dashboard") }}>Dashboard</MenuItem>
                        <MenuItem onClick={() => { history.push(`/profile/${isAuthenticated()._id}`) }}>Profile</MenuItem>
                        {isAuthenticated() && (
                            <MenuItem onClick={() => signout(() => { history.push("/") })}>Logout</MenuItem>
                        )}
                    </Menu>
                </>
            )}
            {isAuthenticated() && (isAuthenticated().role === "admin" || isAuthenticated().role === "vendor") && (
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => { history.push("/admin/dashboard") }}>Dashboard</MenuItem>
                    <MenuItem onClick={() => { history.push(`/profile/${isAuthenticated().userId}`) }}>Profile</MenuItem>                    
                    {isAuthenticated() && (
                        <MenuItem onClick={() => signout(() => { history.push("/") })}>Logout</MenuItem>
                    )}
                </Menu>
            )}
            {!isAuthenticated() &&
                <Chip onClick={() => { history.push("/signin") }} icon={<InputIcon />} variant="outlined" color="info" label="Sign in to your account" />
            }
        </div>
    );
}