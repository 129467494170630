import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { getUserDetails, getVendorIdByName } from "../api/apiAdmin";
import { readUCHConfigurator, deleteUCHConfig, deleteBucketObjects } from '../api/apiConfigs';
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Form, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


class UCinventorConfiguratorUpdate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            configId: this.props.match.params.configId,
            configProjectName: '',
            configTitle: '',
            description: '',
            vendor: [],
            userId: '',
            forgeToken: '',
            errorMessage: '',
            success: false,
            hasDwg: 1,
            runningInCAD: false,
            configProjectFile: '',
            formData: new FormData(),
            zipeFileChosen: false,
           
        }
        this.deleteConfigurator = this.deleteConfigurator.bind(this);
    }


    style = {
        image: {
            'background': '#fefefe',
            'text-align': 'center',
            'display': 'block',
            'justify-content': 'center',
            'align-items': 'center',
            'margin': 'auto',
        },
        vendorimage: {
            background: '#fefefe',
        },
    };

    // default model shown is the standard box size uploaded
    userId = isAuthenticated().userId

    componentDidMount() {
        readUCHConfigurator(this.state.configId).then(data => {
            if (data.error) {
                this.setState({ errorMessage: data.error });
            } else {
                this.setState({
                    userId: data.userId,
                    configProjectName: data.configProjectName,
                    configTitle: data.title,
                    description: data.description,
                    photo: data.photo,
                    rootFileName: data.rootFileName
                })
            }
        })
        // use the user details to grab the vendor Id associated to them
        getUserDetails(this.userId, isAuthenticated().token).then(data => {
            if (data.error) {
            } else {
                getVendorIdByName(data.companyName).then(vendor => {
                    if (vendor.error) {
                        alert(vendor.error)
                    } else {
                        console.log(vendor)
                        this.setState({
                            vendor: vendor[0],
                            userId: this.userId
                        })
                    }
                })
            }
        });
    };

    updateConfigurator =() => {
        confirmAlert({
            title: 'Confirm Update',
            message: 'Are you sure you want to update this configurator? This cannot be undone!',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        // deleteUCHConfig(this.state.configId).then(data => {
                        //     if (data.error) {
                        //         this.setState({ errorMessage: 'Could not delete Configurator.' });
                        //     } else {
                        //         deleteBucketObjects({ folderName: this.state.configProjectName }).then(data => {
                        //             if (data.error) {
                        //                 this.setState({ errorMessage: 'Could not delete Forge Files.' });
                        //             } else {
                        //                 this.setState({ redirectToProfile: true });
                        //             }
                        //         })
                        //     }
                        // })
                        this.setState({
                            zipeFileChosen: false,
                            configProjectFile: ''
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        alert('Click No')
                    }
                }
            ]
        });
    };

    deleteConfigurator = () => {
        confirmAlert({
            title: 'Confirm Removal',
            message: 'Are you sure you want to delete this configurator? This cannot be undone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteUCHConfig(this.state.configId).then(data => {
                            if (data.error) {
                                this.setState({ errorMessage: 'Could not delete Configurator.' });
                            } else {
                                deleteBucketObjects({ folderName: this.state.configProjectName }).then(data => {
                                    if (data.error) {
                                        this.setState({ errorMessage: 'Could not delete Forge Files.' });
                                    } else {
                                        this.setState({ redirectToProfile: true });
                                    }
                                })
                            }
                        })



                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        alert('Click No')
                    }
                }
            ]
        });
    };

    redirectUser = () => {
        if (this.state.redirectToProfile) {
            return <Redirect to={`/configAdmin/${this.userId}`} />;
        }
    };


    handleConfigProjectChange = event => {
        
        this.setState({
            configProjectFile: event.target.files[0].name,
            formData: new FormData(),
            zipeFileChosen: true
        })
        

    };  

    render() {

        return (
            <Layout
                title="Configurator Admin"
                description={`Use this wizard to update the ${this.state.configProjectName} configurator`}
                className="container-fluid"
                isMobile={true}
            >
                <div className="container-fluid">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/configAdmin/${this.userId}`}>Back to configurator admin</a></li>
                            <li className="breadcrumb-item active" aria-current="page" >{this.state.configProjectName}</li>
                        </ol>
                    </nav>
                    <br />
                    <Row>
                        <Col md={8}>
                            {this.state.errorMessage && <div className="alert alert-danger">{this.state.errorMessage}</div>}
                            {this.state.newConfigProjectId &&
                                <div className="alert alert-danger">
                                    {/* <a href={`/uchconfigurator/${this.state.newConfigProjectId}`} >{`Preview the ${this.state.configProjectName} configurator`}</a>                                     */}
                                    <a href={`/configurator/${this.state.newConfigProjectId}`} >{`Preview the ${this.state.configProjectName} configurator`}</a>
                                </div>}
                            {this.showWarning}
                            <Card>
                                <Card.Header>
                                    <Card.Body>
                                        <Form>
                                            <p>created from model: <br /> <b>{this.state.rootFileName}</b></p>
                                            <Image
                                                src={this.state.photo}
                                                alt={this.state.configTitle}
                                                className="mb-3"
                                                width={100}
                                                height={100}
                                                style={this.style.image}
                                            />
                                            <Form.Group>
                                                <Form.Label>Forge Project Name: </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    readOnly={true}
                                                    type="text"
                                                    name='configProjectName'
                                                    placeholder={this.state.configProjectName}
                                                // onChange={this.handleChange('configProjectName')}
                                                />

                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Upload New Project File [zip]: </Form.Label>
                                                <Form.Control
                                                    size="sm"
                                                    type="file"
                                                    name='configProjectFile'
                                                    placeholder={this.state.configProjectFile}
                                                    accept=".zip|file/*"
                                                    onChange={this.handleConfigProjectChange}
                                                />
                                                <br/>
                                                {this.state.zipeFileChosen && <Row>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Button
                                                                variant="success"
                                                                onClick={this.updateConfigurator}>Update the Configurator package
                                                            </Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row> }
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Text className="text-muted">
                                                    This active Project Will be deleted and you will be re-directed to the
                                                    'Build Configurator' page to upload a new model.
                                                </Form.Text>
                                                <Form.Text className="text-danger">
                                                    This cannot be undone!
                                                </Form.Text>
                                            </Form.Group>

                                            <Form.Group>
                                                <Row>
                                                    <Col lg={6}>
                                                        <Form.Group>
                                                            <Button
                                                                variant="danger"
                                                                onClick={this.deleteConfigurator}>Delete This Configurator
                                                            </Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                        </Form>
                                    </Card.Body>
                                </Card.Header>
                            </Card>
                        </Col>
                    </Row>

                    {this.redirectUser()}
                </div>
                <br />
            </Layout>

        )
    }
}

export default UCinventorConfiguratorUpdate;