import React, { useState, useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { getDownloadHistory } from "../api/apiUser";
import { listDownloads } from "../api/apiAdmin";
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'


const Dashboard = () => {
    const [history, setHistory] = useState([]);
    const [downloads, setDownloads] = useState([]);
    const [runningInCAD, setRunningInCAD] = useState(false);
   
    const {userId, token } = isAuthenticated() && isAuthenticated()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const loadDownloads = () => {
        listDownloads(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setDownloads(data);
            }
        });
    };

    const init = (userId, token) => {
       
        getDownloadHistory(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {

                setHistory(data);
            }
            console.log(data)
        });
    };

    useEffect(() => {
        init(userId, token);
        loadDownloads();
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/user/dashboard/" + isAuthenticated().email);
        ReactGA.set({ userId: isAuthenticated().userId });
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    const userLinks = () => {
        return (
            <div className="card">
                <h4 className="card-header">Your Links</h4>
                <ul className="list-group">
                    <li className="list-group-item">
                        <Link className="nav-link" to="/downloads">
                            Download History <span className="badge badge-pill badge-primary">{downloads.length}</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/profile/${userId}`}>
                            Update Your Profile
                        </Link>
                    </li>                   
                </ul>
            </div>
        );
    };

    const userInfo = () => {
        return (
            <div className="card mb-5">
                <h3 className="card-header">Your Profile Information</h3>
                <ul className="list-group">
                    <li className="list-group-item">First Name: <b>{isAuthenticated().name}</b></li>
                    <li className="list-group-item">Last Name: <b>{isAuthenticated().lastName}</b></li>
                    <li className="list-group-item">Company Name: <b>{isAuthenticated().companyName}</b></li>
                    <li className="list-group-item">Company Type: <b>{isAuthenticated().companyType}</b></li>
                    <li className="list-group-item">email: <b>{isAuthenticated().email}</b></li>
                    <li className="list-group-item">
                        Your role for this website: <b>{isAuthenticated().role === "admin" ? "admin" : "Registered User"}</b>
                    </li>
                    <li className="list-group-item">
                        Your role for this website: <b>{isAuthenticated().role === "vendor" ? "admin" : "Registered User"}</b>
                    </li>
                </ul>
            </div>
        );
    };


    return (
        <Layout
            title={`Dashboard ${runningInCAD}`}
            description={`Hello ${isAuthenticated().name}!`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <Row>
                <Col md>
                    {userLinks()}
                    <br/>
                </Col>
                <Col md>
                    {userInfo()}
                    {/* {downloadHistory(history)} */}
                </Col>
            </Row>

        </Layout>
    );
};

export default Dashboard;
