import React, { useState, useEffect } from "react";
import { fixedTags } from './fixedTags';
import { getTags } from "../api/apiCore";
import { ListGroup } from 'react-bootstrap';

const TagsCheckbox = ({ handleFilters }) => {
    const [checked, setCheked] = useState([]);
    const [values, setValues] = useState({
        tags: [],
    });
    const { tags } = values;

    useEffect(() => {
        getTags().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    tags: data,
                });               
            }
        });
        
    }, []);

    const handleToggle = c => () => {
        // return the first index or -1
        const currentCategoryId = checked.indexOf(c);
        const newCheckedCategoryId = [...checked];
        // if currently checked was not already in checked state > push
        // else pull/take off
        if (currentCategoryId === -1) {
            newCheckedCategoryId.push(c);
        } else {
            newCheckedCategoryId.splice(currentCategoryId, 1);
        }
        // console.log(newCheckedCategoryId);
        setCheked(newCheckedCategoryId);
        handleFilters(newCheckedCategoryId);
    };

    return tags.map((c, i) => (
        <ListGroup variant="flush">
            <ListGroup.Item style={{width: "300px"}}>
            <input
                    onChange={handleToggle(c.name)}
                    value={checked.indexOf(c._id === -1)}
                    type="checkbox"
                    className="form-check-input"
                />
                <label className="form-check-label">{c.name}</label>
            </ListGroup.Item>
        </ListGroup>
        // <div className="col-md-auto">
        //     <li key={i} className="list-unstyled " >
        //         <input
        //             onChange={handleToggle(c.name)}
        //             value={checked.indexOf(c._id === -1)}
        //             type="checkbox"
        //             className="form-check-input"
        //         />
        //         <label className="form-check-label">{c.name}</label>
        //     </li>
        // </div>
    ));
};

export default TagsCheckbox;
