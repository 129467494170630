import React, { useState, useEffect } from 'react';
import { isAuthenticated } from '../auth';
import { createDownloadLink } from '../api/apiCore'
import { FaSearch, FaFileDownload } from 'react-icons/fa'


const GetModelLinks = ({ modelLink, filetomake, brand, awsVersion }) => {
    // console.log(modelLink + " : " +  filetomake+ " : " +  brand+ " : " +  awsVersion)   
    const token = isAuthenticated() && isAuthenticated().token;
        

    const [downloadUrl, setDownloadUrl] = useState([]);

    
    const loadmodeLink = (modelLink, filetomake, brand, awsVersion) => {
        
        createDownloadLink(token, modelLink, filetomake, brand, awsVersion).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {                         
                setDownloadUrl(data);
            }            
        });
    };


    useEffect(() => {
        loadmodeLink(modelLink, filetomake, brand, awsVersion);
    }, []);

    return (        
        <a href={downloadUrl} className="btn btn-outline-primary mt-2 mb-2 card-btn-1"> {filetomake} file <FaFileDownload /></a>  
        
    )
};

export default GetModelLinks;