/* global Autodesk, THREE */
import { forgeAuth } from "../api/apiConfigs";

var viewer;
var tileId = '';
var isLoading = false;
var isModel = 0
export var properties = {};

function launchViewer(urn, id, extensions, hasDwg) {
    tileId = id;
    isLoading = true;
    isModel = hasDwg
    forgeAuth().then(token => {
        var options = {
            'document': urn,
            'env': 'AutodeskProduction2',
            'api': 'streamingV2',
            'accessToken': token.access_token
            // 'refreshToken': getForgeToken
        };

        var viewerElement = document.getElementById("forgeViewer");
        // viewer = new Autodesk.Viewing.Viewer3D(viewerElement, {});
        viewer = new Autodesk.Viewing.GuiViewer3D(viewerElement, {});

        // setup the viewer look and feel
        const profileSettings = {
            name: "mySettings",
            description: "My personal settings.",
            settings: {
                ambientShadows: true,
                groundShadows: true,
                groundReflection: true,
                ghosting: true,
                envMapBackground: false,
                lightPreset: 8,
                explodeStrategy: 'radial',
                // openPropertiesOnSelect: true,
                // edgeRendering: true,
                displayUnits: 'ft-and-decimal-in'
            },
            extensions: {
                // load: ["panelExtension"],   // Extensions to load
                load: extensions,//['HeatmapExtension', 'IssuesExtension', 'AnimationExtension'],   // Extensions to load               
                unload: ['Autodesk.Hyperlink', 'Autodesk.BimWalk', 'Autodesk.FullScreen',
                    'Autodesk.LayerManager']
            }
        }

        // const profileSettings = Autodesk.Viewing.ProfileSettings.Navis;
        const profile = new Autodesk.Viewing.Profile(profileSettings);

        Autodesk.Viewing.endpoint.HTTP_REQUEST_HEADERS['If-Modified-Since'] = "Sat, 29 Oct 1994 19:43:31 GMT"
        Autodesk.Viewing.Initializer(
            options,
            function () {
                viewer.initialize();
                viewer.setTheme("light-theme")

                viewer.setProfile(profile);
                viewer.config.modelBrowserExcludeRoot = false

                loadDocument(options.document, hasDwg);
            }
        );
    })
}

function loadDocument(documentId, hasDwg) {

    Autodesk.Viewing.Document.load(
        documentId,
        function (doc) { // onLoadCallback

            var viewables = doc.getRoot().search({ 'type': 'geometry' })

            viewer.loadDocumentNode(doc, viewables[hasDwg], {
                // preserveView: true,
                // keepCurrentModels: true,
                // placementTransform: (new THREE.Matrix4()).setPosition(),
                // keepCurrentModels: true,
                globalOffset: { x: 0, y: 0, z: 0 }
            })
                .then(onLoadFinished);

            // test to fix the issue of the model not loading in correct orientation



            // // var geometryItems = Autodesk.Viewing.Document.getSubItemsWithProperties(doc.getRoot(), { 'type': 'geometry' }, true);
            // var geometryItems = doc.getRoot().search({ 'type': 'geometry' })
            // if (geometryItems.length > 0) {
            //     geometryItems.forEach(function (item, index) {
            //     });
            //     viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeometryLoaded);
            //     viewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, debounce(() => {
            //         console.log('selection change')
            //         getModelProperties()
            //             .then((modelProperties) =>
            //                 store.dispatch(actions.getViewerProperties(modelProperties))
            //             )
            //             .catch(() =>
            //                 store.dispatch(actions.getViewerProperties([]))
            //             )
            //     }), 200);

            //     viewer.loadDocumentNode(doc.getViewablePath(geometryItems[0])); // show 1st view on this document...
            // }
        },
        function (errorMsg) { // onErrorCallback
            console.log(errorMsg);
        }

    )
}


function onLoadFinished(doc) {

    isLoading = false
    // window.localStorage.setItem('forgeViewerStatus', 'loaded')

    // This is a complete kludge to overcome the Forge issue in translation of
    // part files. They were defaulting to a front view.
    if (isModel === 0) {
        // const nav = viewer.navigation;
        // const up = nav.getCameraUpVector();
        // const up2 = nav.getCameraUpVector();
        // const axis = new THREE.Vector3(1, 0, 0);
        // const angle = 45.0;
        // const matrix = new THREE.Matrix4().makeRotationAxis(axis, angle);

        // const axis2 = new THREE.Vector3(0, 1, 0);
        // const angle2 = 45.0;
        // const matrix2 = new THREE.Matrix4().makeRotationAxis(axis2, angle2);

        // let pos = nav.getPosition();
        // let pos2 = nav.getPosition();
        // pos.applyMatrix4(matrix);
        // up.applyMatrix4(matrix);
        // pos2.applyMatrix4(matrix2);
        // up2.applyMatrix4(matrix2);   

        // nav.setView(pos2, new THREE.Vector3(0, 0, 0));
        // nav.setCameraUpVector(up2);
        // var viewState = viewer.getState();
        // viewer.restoreState(viewState);

        const nav = viewer.navigation;
        const up = nav.getCameraUpVector();       

        const axis = new THREE.Vector3(1, 0, 0);
        const angle = -45.0;
        const matrix = new THREE.Matrix4().makeRotationAxis(axis, angle);

        let pos = nav.getPosition();
        pos.applyMatrix4(matrix);
        nav.setView(pos, new THREE.Vector3(0, 0, 0));

    }

    viewer.fitToView();
}

export function viewerResize() {
    //   viewer.resize();
}


export function modelRestoreState() {
    var originalState = "";

    switch (tileId) {
        // case "0001":
        //     originalState = JSON.parse('{"guid":"f075a989156eb711398","seedURN":"dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29uZmlnX2hzc3RzdHJ1Y3R1cmUvSFNTX0luc3RydW1lbnRfVC5pZHc","overrides":{"transformations":[]},"objectSet":[{"id":[],"isolated":[],"hidden":[],"explodeScale":0,"idType":"lmv"}],"viewport":{"name":"","eye":[145.8184640788883,93.51676504768685,-124.19785909734301],"target":[0,0,0.0000019073486328125],"up":[-0.3340034881841154,0.8986169812472194,0.28448056329910826],"worldUpVector":[0,1,0],"pivotPoint":[0,0,0.0000019073486328125],"distanceToOrbit":213.15139804714164,"aspectRatio":1.9929737351528332,"projection":"perspective","isOrthographic":false,"fieldOfView":61.92751520437556},"renderOptions":{"environment":"Rim Highlights","ambientOcclusion":{"enabled":false,"radius":10,"intensity":0.4},"toneMap":{"method":1,"exposure":-9,"lightMultiplier":-1e-20},"appearance":{"ghostHidden":true,"ambientShadow":false,"antiAliasing":true,"progressiveDisplay":true,"displayLines":true}},"cutplanes":[]}');
        //     console.log("Restoring State for Tile:", tileId);
        //     break;
        // case "0002":
        //     originalState = JSON.parse('{"guid":"f075a989156eb711397","seedURN":"dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29uZmlnX3Rlc3RibG9ja3MvVGVzdEJsb2Nrcy56aXA","overrides":{"transformations":[]},"objectSet":[{"id":[],"isolated":[],"hidden":[],"explodeScale":0,"idType":"lmv"}],"viewport":{"name":"","eye":[268.15901156738664,268.1590007567955,268.15899179720685],"target":[0,-0.0000019073486328125,-0.00000762939453125],"up":[0,1,0],"worldUpVector":[0,1,0],"pivotPoint":[0,-0.0000019073486328125,-0.00000762939453125],"distanceToOrbit":464.4650203923889,"aspectRatio":1.9409698157397892,"projection":"orthographic","isOrthographic":true,"orthographicHeight":464.46502039238896},"renderOptions":{"environment":"Rim Highlights","ambientOcclusion":{"enabled":false,"radius":10,"intensity":0.4},"toneMap":{"method":1,"exposure":-9,"lightMultiplier":-1e-20},"appearance":{"ghostHidden":true,"ambientShadow":false,"antiAliasing":true,"progressiveDisplay":true,"displayLines":true}},"cutplanes":[]}');
        //     console.log("Restoring State for Tile:", tileId);
        //     break;
        // case "0003":
        //     originalState = JSON.parse('{"guid":"f075a989156eb711399","seedURN":"dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29uZmlnX2hzc2luc3RydW1lbnRpL0hTU19JbnN0cnVtZW50X0kuemlw","overrides":{"transformations":[]},"objectSet":[{"id":[],"isolated":[],"hidden":[],"explodeScale":0,"idType":"lmv"}],"viewport":{"name":"","eye":[947.8717757705549,-947.8718237856292,947.8718169263327],"target":[0,0,0],"up":[0,1,0],"worldUpVector":[0,1,0],"pivotPoint":[0,0,0],"distanceToOrbit":1641.7621261779516,"aspectRatio":2.47171569916115,"projection":"orthographic","isOrthographic":true,"orthographicHeight":1641.7621261779514},"renderOptions":{"environment":"Rim Highlights","ambientOcclusion":{"enabled":false,"radius":10,"intensity":0.4},"toneMap":{"method":1,"exposure":-9,"lightMultiplier":-1e-20},"appearance":{"ghostHidden":true,"ambientShadow":false,"antiAliasing":true,"progressiveDisplay":true,"displayLines":true}},"cutplanes":[]}');
        //     console.log("Restoring State for Tile:", tileId);
        //     break;
        // case "0004":
        //     originalState = JSON.parse('{"guid":"f075a989156eb711400","seedURN":"dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6Y29uZmlnX2hzc2luc3RydW1lbnRpL0hTU19JbnN0cnVtZW50X0kuemlw","overrides":{"transformations":[]},"objectSet":[{"id":[],"isolated":[],"hidden":[],"explodeScale":0,"idType":"lmv"}],"viewport":{"name":"","eye":[-163.8662266489145,0,101.51004011675508],"target":[0,0,0],"up":[0,1,0],"worldUpVector":[0,1,0],"pivotPoint":[0,0,0],"distanceToOrbit":192.76002822332916,"aspectRatio":2.0663910331477187,"projection":"perspective","isOrthographic":false,"fieldOfView":46.48761986856245},"renderOptions":{"environment":"Rim Highlights","ambientOcclusion":{"enabled":false,"radius":7.3109139716724485,"intensity":0.4},"toneMap":{"method":1,"exposure":-9,"lightMultiplier":-1e-20},"appearance":{"ghostHidden":true,"ambientShadow":false,"antiAliasing":true,"progressiveDisplay":true,"displayLines":true}},"cutplanes":[]}');
        //     console.log("Restoring State for Tile:", tileId);
        //     break;
        default:
            console.log("Sorry, no model selected");
    }


    viewer.restoreState(originalState, false, false);
}

export function checkLoading() {
    return isLoading
};


export async function addViewable(documentId, offset, hideModelNum, hasDwg) {
    Autodesk.Viewing.Document.load(
        documentId,
        function (doc) { // onLoadCallback
            var viewables = doc.getRoot().search({ 'type': 'geometry' })
            viewer.loadDocumentNode(doc, viewables[hasDwg], {
                preserveView: true,
                keepCurrentModels: true, // this was original                
                //placementTransform: (new THREE.Matrix4()).setPosition(4,3,2),                
                globalOffset: { x: 0, y: offset, z: 0 }
            })
                .then(onLoadFinished);
        },
        function (errorMsg) { // onErrorCallback
            console.log(errorMsg);
        }
    )
    // viewer.impl.visibilityManager.setNodeOff(2, true) // true=hide, false=show
    viewer.hideModel(hideModelNum)
    //viewer.impl.visibilityManager.setNodeOff(viewer.model.modelId(hideModelNum),true)
    //viewer.impl.sceneUpdated(true);
}


const Helpers = {
    launchViewer,
    loadDocument
};

export default Helpers;
