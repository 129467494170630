import React, { useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { useMediaQuery } from 'react-responsive'
import {
    Container,
    Box,
    Button,
    Grid,    
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { getAllPointCloudsByUser, getAwsPointCloudLink } from '../api/apiCore';
import { useHistory } from "react-router-dom";
import PointcloudNavigator from './PotreeViewer';

//https://v4.mui.com/components/about-the-lab/
//https://v4.mui.com/components/material-icons/

const PointCloudViewer = () => {
    const [state, setState] = React.useState({
        users: [],
        user: '',
        fullUserList: [],
        userList: [],
        updateMessage: '',
        searchText: '',
        fileList: []
    })
   
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // These parameters will be changed in the future once storage decisions are made
   
    const refreshPage = () => {
        setTimeout(() => {
            console.log(state.user.token)
            getAllPointCloudsByUser(state.user.token, state.user.userId).then(data => {
                // checkAllfileStatus(data)
                setState({
                    ...state,
                    fileList: data
                });
            })
        }, 1000);

    };

    useEffect(() => {
        getAllPointCloudsByUser(isAuthenticated().token, isAuthenticated().userId).then(data => {
            setState({
                ...state,
                user: isAuthenticated(),
                fileList: data
            });
        });
    }, []);


    const setFileList = (userId) => {
        getAllPointCloudsByUser(isAuthenticated().token, userId).then(data => {
            setState({
                ...state,
                fileList: data
            })
        })
    }

 

    return (
        <Layout
            title={`${isAuthenticated().name}'s Point Clouds`}
            description={`for Admin beta testing only`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`pointclouds`}>Back to Point Clouds</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Point Clouds admin</li>
                    </ol>
                </nav>
                <br />
                <Container maxWidth="xl">

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<RefreshIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Refresh
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<SettingsOverscanIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Run SAM
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<FlipToBackIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Classify
                                </Button>
                            </Grid>
                            

                        </Grid>
                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <div id="potree_sidebar_container"></div>
                        </Grid>
                        <Grid item xs={9}>
                            <PointcloudNavigator />
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}>
                               
                            </Grid>
                            <Grid item xs={2}>
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<ImportExportIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Export
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<ScreenShareIcon  />}
                                    onClick={() => (refreshPage())}
                                >
                                    Share
                                </Button>
                            </Grid>
                            

                        </Grid>
                    </Box>



                </Container>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default PointCloudViewer;