import React, { useState, useEffect } from 'react';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth';
import { createContent, getCategories, getVendors, getTags } from '../api/apiAdmin';
import Select from 'react-select'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const AddContent = () => {
    const [values, setValues] = useState({
        user: '',
        name: '',
        description: '',
        unique_product_ref: '',
        categories: [],
        category: '',
        categoryName: '',
        tags: '',
        brand: '',
        vendor: '',
        brands: [],
        product_family: '',
        product_group: '',
        content_type: 'Object',
        awsVersion: '',
        design_country: '',
        manufacture_country: '',
        verifiedByManufacturer: '',
        cad_comments: '',        
        status: '',
        photo: '',
        modelFile: '',
        loading: false,
        error: '',
        createdContent: '',
        redirectToProfile: false,
        formData: ''
    });



    const {
        name,
        description,
        unique_product_ref,
        categories,
        category,
        categoryName,
        tags,
        brands,
        vendor,
        vendors,
        product_family,
        product_group,
        content_type,
        awsVersion,
        design_country,
        manufacture_country,
        verifiedByManufacturer,
        cad_comments,        
        status,
        loading,
        error,
        createdContent,
        redirectToProfile,
        formData
    } = values;

    const [tagvalues, setTagValues] = useState({
        fixedTags: [],
    });    
    const { fixedTags } = tagvalues;

    const [brandvalues, setBrandValues] = useState({
        fixedBrands: [],
    });
    const { fixedBrands } = brandvalues;


    // load categories and set form data
    const init = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    categories: data,
                    formData: new FormData()
                });
            }
        });
        getTags().then(data => {
            if (data.error) {
                setTagValues({ ...tagvalues, error: data.error });
            } else {
                setTagValues({
                    ...tagvalues,
                    fixedTags: data
                });
            }
        });
        getVendors().then(data => {
            if (data.error) {
                setBrandValues({ ...brandvalues, error: data.error });
            } else {
                setBrandValues({
                    ...brandvalues,
                    fixedBrands: data,                    
                });
            }
        });
    };

    useEffect(() => {
        init();
    }, []);

    const handleChange = name => event => {
        let value = "";
        if (name === 'photo') {
            value = event.target.files[0]
        } else if (name === 'tags') {
            const finalVal = (event && event.map((c, i) => (
                [
                    c.value
                ]
            )))
            value = finalVal
        } else if (name === 'modelFile') {
            value = event.target.files[0]
        } else if (name === 'category') {
            value = event.value
            // pass the actual category name to the create call so we 
            // can use it for a file friendly name.            
            formData.append('categoryName', event.label)            
        } else if (name === 'brand') {
            value = event.label            
            // pass the actual category name to the create call so we 
            // can use it for a file friendly name.
            formData.append('vendor', event.value)
            //alert(formData.get('vendorId'))
        } else {
            value = event.target.value
        }
        
        //const value = name === 'photo' ? event.target.files[0] : event.target.value;
        //formData.set(name, value);  // changed this for IE11 only.
        formData.append(name, value); 
        setValues({ ...values, [name]: value });
    };


    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: '', loading: true });

        const userId = isAuthenticated() && isAuthenticated().userId;
        const token = isAuthenticated() && isAuthenticated().token;
        const name = isAuthenticated() && isAuthenticated().name;

        createContent(userId, token, formData).then(data => {

            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                //const modelLink = handleModelUpload();       
                //console.log(data)
                setValues({
                    ...values,
                    name: '',
                    description: '',
                    unique_product_ref: '',
                    brand: '',
                    brands: [],
                    brandId: '',
                    categories: [],
                    category: '',
                    categoryName: '',
                    tags: [],
                    product_family: '',
                    content_type: '',
                    awsVersion: '',
                    design_country: '',
                    manufacture_country: '',
                    verifiedByManufacturer: '',
                    cad_comments: '',
                    edition_number: '',                    
                    status: '',
                    photo: '',
                    modelFile: '',
                    loading: false,
                    createdContent: data.name
                })
            };
        });
    };

    
    const newPostForm = () => (
        
        <form className="mb-3" onSubmit={clickSubmit} style={{ display: createdContent ? 'none' : ''  }}>
            <div className="card">
                <button className="btn btn-outline-primary card-header">Publish Content</button>
                <div className="card-body">
                    <h4 className="card-title">Required Inputs</h4>
                    <hr />

                    <div className="row">
                        <div className="col">
                            <h5>Content Photo</h5>
                            <div className="form-group">
                                <label className="btn btn-secondary">
                                    <input onChange={handleChange('photo')} type="file" name="photo" accept="image/*" />
                                    <small id="photoHelp" className="form-text text-warning">Choose any product image</small>
                                </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="name" className="text-muted">Content Name</label>
                                <input id="name" type="text" value={name} onChange={handleChange('name')} className="form-control"
                                 aria-describedby="nameHelp" ></input>
                                <small id="nameHelp" className="form-text text-muted">This will be the file name once you publish.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="description" className="text-muted">Description</label>
                                <textarea id="description" onChange={handleChange('description')} className="form-control" style={{ height: "150px" }}
                                 aria-describedby="descriptionHelp" value={description}  />
                                <small id="descriptionHelp" className="form-text text-muted">The entire description is searchable and can be up to 2000 characters.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="tags" className="text-muted">Tags</label>
                                <Select
                                    className=""
                                    isMulti
                                    placeholder="example: Major Equipment"
                                    name="tags"
                                    options={fixedTags.map((c, i) => (
                                            {
                                                value: c.name,
                                                label: c.name
                                            }
                                        ))}
                                    onChange={handleChange("tags")}
                                />
                                <small id="tagsHelp" className="form-text text-muted">Choose tags to help users filter on searches.</small>
                            </div>

                        </div>
                        <div className="col">
                            <h4>Content File</h4>
                            <div className="form-group">
                                <label className="btn btn-secondary">
                                    <input onChange={handleChange('modelFile')} type="file" name="modelFile" accept=".ipt, .stp, .iges, .dwg" />
                                    <small id="modelHelp" className="form-text text-warning">Choose any 3D model or 2D dwg</small>
                                </label>
                            </div>

                            <div className="form-group">
                                <label htmlFor="content_type" className="text-muted">Content Type</label>
                                <select onChange={handleChange('content_type')} value={content_type} className="form-control">                                    
                                    <option value="Model" selected>3D Object</option>
                                    <option value="Symbol">2D Symbol</option>
                                </select>
                                <small id="content_typeHelp" className="form-text text-muted">Choose which type of file this is.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="Brand" className="text-muted">Brand</label>                                
                                <Select
                                    className=""
                                    name="brand"
                                    placeholder="example: SBS"
                                    options={fixedBrands.map((c, i) => (
                                            {
                                                value: c._id,
                                                label: c.name
                                            }
                                        ))}
                                    onChange={handleChange("brand")}
                                />
                                <small id="brandHelp" className="form-text text-muted">Select one Vendor Brand for this Content. if there is none just leave Generic.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="category" className="text-muted">Category</label>
                                <Select
                                    className=""
                                    name="category"
                                    placeholder="example: connectors"
                                    options={categories &&
                                        categories.map((c, i) => (
                                            {
                                                value: c._id,
                                                label: c.name
                                            }
                                        ))}
                                    onChange={handleChange("category")}
                                />
                                <small id="categoryHelp" className="form-text text-muted">Start Typing to view all categories</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="verified" className="text-muted">Verified By Manufacturer</label>
                                <select onChange={handleChange('verifiedByManufacturer')} value={verifiedByManufacturer} className="form-control">                                    
                                    <option value="false" selected>No</option>
                                    <option value="true">Yes</option>
                                </select>
                                <small id="content_typeHelp" className="form-text text-muted">Has This Content been verified by a manufacturer?</small>
                            </div>

                        </div>
                    </div>
                </div>
                <button className="btn btn-outline-primary card-footer">Publish Content</button>
            </div>

            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Optional Inputs</h4>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="unique_product_ref" className="text-muted">Unique Product Reference</label>
                                <input onChange={handleChange('unique_product_ref')} type="text" className="form-control" value={unique_product_ref} 
                                    aria-describedby="unique_product_refHelp"/>
                                <small id="unique_product_refHelp" className="form-text text-muted">Add a unique product reference for your own reporting.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="product_family" className="text-muted">Product Family</label>
                                <input onChange={handleChange('product_family')} type="text" className="form-control" value={product_family} 
                                 aria-describedby="product_familyHelp" />
                                <small id="product_familyHelp" className="form-text text-muted">Add an additional product family to group your content.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="product_group" className="text-muted">Product Group</label>
                                <input onChange={handleChange('product_group')} type="text" className="form-control" value={product_group}
                                    aria-describedby="product_groupHelp"  />
                                <small id="product_groupHelp" className="form-text text-muted">Type a Category to view all.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="cad_comments" className="text-muted">Comments</label>
                                <textarea id="cad_comments" onChange={handleChange('cad_comments')} className="form-control" style={{ height: "60px" }} 
                                aria-describedby="cad_commentsHelp" value={cad_comments} />
                                <small id="cad_commentsHelp" className="form-text text-muted">Additional comments about this content.</small>                                
                            </div>


                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="design_country" className="text-muted">Design Country</label>
                                <input onChange={handleChange('design_country')} type="text" className="form-control" value={design_country}
                                    aria-describedby="design_countryHelp"  />
                                <small id="design_countryHelp" className="form-text text-muted">Select a country this content is designed in.</small>                                
                            </div>

                            <div className="form-group">
                                <label htmlFor="manufacture_country" className="text-muted">Manufacture Country</label>
                                <input onChange={handleChange('manufacture_country')} type="text" className="form-control" value={manufacture_country}
                                    aria-describedby="manufacture_country"  />
                                <small id="manufacture_country" className="form-text text-muted">Select a country this content is built in.</small>                                
                            </div>

                            <div className="form-group">
                                <label htmlFor="status" className="text-muted">Status</label>
                                <select onChange={handleChange('status')} className="form-control">
                                    <option>Published</option>
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                </select>
                                <small id="statusHelp" className="form-text text-muted">Published is ready immediately. Draft can be modified further before publishing.</small>                                
                          </div>
                        </div>

                    </div>
                </div>
            </div>

        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const resetPage = () => {
        window.location.reload();
    }
    
    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdContent ? '' : 'none' }}>
            <h2>{`${createdContent}`} is created!</h2>
            <button onClick={resetPage} className="btn btn-outline-primary">Create more content?</button>
        </div>
    ); 

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Loading...</h2>
                <Loader
                    type="Watch"
                    color="#5778db"
                    height={100}
                    width={100}                    
                />
            </div>
        );

    return (
        <Layout title="Add new content" description={`Hello ${name}, ready to add new content?`}>
            <div className="row">
                <div className="col-md-10 offset-md-1">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to admin dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page" >create content</li>
                        </ol>
                    </nav>
                    {showLoading()}
                    {showSuccess()}
                    {showError()}
                    {newPostForm()}
                </div>
            </div>
        </Layout>
    );
};

export default AddContent;
