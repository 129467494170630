import React, { useState, useEffect } from "react";
import { Container, Row, Col, CardDeck } from 'react-bootstrap';
import { getCategories, listContentsByCatId, countByCatId } from "../api/apiCore";
import Card from "./Card";
import Select from 'react-select'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

let fixedCats = []

const Search = () => {
    const [data, setData] = useState({
        categories: [],
        search: "",
        category: "",
        results: [],
        searched: false,
        loading: false
    });
    const [error, setError] = useState(false);
    const [categoryChosen, setCategoryChosen] = useState("");
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const { categories, search, category, results, searched } = data;
    const [searchResults, setSearchResults] = useState([]);
    const [totalsearchSize, settotalsearchSize] = useState(0);
    let loadingTest = false

    const init = () => {
        getCategories().then(data => {
            if (data.error) {
                setData({ ...data, error: data.error });
            } else {
                fixedCats = data
                setData({
                    ...data,
                    categories: data,
                    formData: new FormData()
                });
            }
        });

    };

    useEffect(() => {
        init();
    }, []);

    const searchData = () => {
        setCategoryChosen(data.category)
        if (data.category) {
            setData({ loading: true })
            getCount(data.category)
        }
    };

    const getCount = (cat) => {

        countByCatId(cat).then(
            data => {
                if (data.error) {

                } else {
                    settotalsearchSize(data.totalCount);
                    listContentsByCatId(skip, limit, cat).then(
                        data => {
                            if (data.error) {
                                //console.log(response.error);                        
                            } else {
                                setSearchResults(data.data);
                                setSize(data.size);
                                setSkip(0);
                                setData({ loading: false, searched: true })
                            }
                        }
                    );
                }
            }
        )
    };

    const loadMoreSearchResults = () => {
        let toSkip = skip + limit;
        listContentsByCatId(toSkip, limit, categoryChosen).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setSearchResults([...searchResults, ...data.data]);
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };

    const loadMoreSearchButton = () => {
        return (
            size > 0 &&
            size >= limit && (
                <button onClick={loadMoreSearchResults} className="btn btn-warning mb-5">
                    Load more search results from remaining {totalsearchSize - (skip + limit)}
                </button>
            )
        );
    };


    const searchSubmit = e => {
        e.preventDefault();
        searchData();
        loadingTest = true
    };

    const handleChange = name => event => {
        // Passing in an empty "search" parameter for now
        // may want to use a deeper search in the future      
        setData({ ...data, ["search"]: " ", [name]: event.value, searched: false });
    };

    const searchMessage = (searched, results) => {
        if (data.loading) {
            return (<div>
                <h2 color="#00BFFF">Searching...</h2>
                <Loader type="Circles" color="#00BFFF" height={80} width={80} />
            </div>)
        } else {
            if (totalsearchSize > 0) {
                return `Found ${totalsearchSize} items`;
            }
            if (searched && totalsearchSize < 1) {
                return <h2 className="text-center">Sorry, nothing found!</h2>;
            }
        }
    };

    const resultMessage = () => {
        return (
            <div role="alert">
                <h2 className="mb-4 d-flex justify-content-center">
                    <div role="success">{searchMessage(searched, results)}</div>
                </h2>
                <hr></hr>
                <br />
            </div>
        )
    }

    const searchedContents = (results = []) => {
        return (
            
            <div className="container-fluid">
                <CardDeck>
                    {searchResults.map((content, i) => (
                        <div>
                            <Card key={i}
                                cardStyle={{ 'min-width': '18rem', 'max-width': '28rem' }}
                                content={content}
                                imageSize={225}
                            />
                            <br />
                        </div>
                    ))}
                </CardDeck>
                <Row>
                    <Col>
                        <div className="row align-items-center justify-content-center">
                            {loadMoreSearchButton()}
                        </div>
                        <br />
                        <br />
                        <br />
                    </Col>
                </Row>

                {/* <div className="container-fluid">
                    <div className="card-deck justify-content-center">
                        {searchResults.map((content, i) => (
                            <div>
                                <Card key={i}
                                    cardStyle={{ width: '18rem' }}
                                    content={content}
                                    imageSize={225}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="mb-4 d-flex justify-content-center">
                        {loadMoreSearchButton()}
                    </div>
                    <hr />
                    <hr />
                </div> */}
            </div>
           
        );
    };




    const searchForm = () => (

        <Container>
            <Row>
                <Col lg={6}>
                    <Select
                        name="category"
                        options={fixedCats &&
                            fixedCats.map((c, i) => (
                                {
                                    value: c._id,
                                    label: c.name
                                }
                            ))}
                        onChange={handleChange("category")}
                        isSearchable={true}
                        placeholder={'type a category to start searching'}
                    />
                    <br />
                </Col>
                <Col lg={2}>
                    <button onClick={searchSubmit} className="btn btn-primary">Search</button>
                </Col>
            </Row>
        </Container>
        // <div className="container">
        //     <div className="row">
        //         <div className="col-md-3"></div>
        //         <div className="col-md-6">

        //         </div>

        //         <div className="col-md-4">

        //         </div>
        //     </div>
        // </div>
    );


    return (
        <div className="row">
            <div className="container mb-3">{searchForm()}</div>
            <div className="container">
                {resultMessage()}
            </div>
            <div>
                {searchedContents(results)}
            </div>
        </div>
    );
};

export default Search;
