import React, { useState, useEffect, Fragment } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { createCategory } from "../api/apiAdmin";
import { getCategories, list } from "../api/apiCore";
import Bulkupload from "./Bulkupload";


const AddCategory = () => {

    const loadCategories = () => {
        getCategories().then(data => {

            if (data.error) {
                console.log(data.error);
            } else {
                //console.log(data);
                setData({ ...data, categories: data });
            }
        });
    };


    const [data, setData] = useState({
        categories: [],
        subCategories: [],
        category: "",
        search: "",
        results: [],
        selectedOption: null,
        searched: false
    });


    useEffect(() => {
        loadCategories();
    }, []);

    const { categories, selectedOption, category, search, results, searched } = data;



    const catOptions = (
        categories.map((c, i) =>{
            return {
                value: c.name,
                label: c.name
            }
        })        
    )

    // ==================================================
    const [catname, setCatName] = useState("");
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    // destructure user and token from localstorage
    const { userId, token, name } = isAuthenticated();

    const handleChange = e => {
        setError("");
        setCatName(e.target.value );
    };

    const handleSearchChange = (data) => {
        setCatName(data.value);
        
    }
 
    const clickSubmit = e => {
        e.preventDefault();
        setError("");
        setSuccess(false);
        // make request to api to create category
        createCategory(userId, token, name).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setError("");
                setSuccess(true);
            }
        });
    };

    const newCategoryFom = () => (
        <form onSubmit={clickSubmit}>
            <div className="form-group">
                <label className="text-muted">Name</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={catname}
                    autoFocus
                    required
                />
            </div>
            <button className="btn btn-outline-primary">Create Category</button>
        </form>
    );

    const showSuccess = () => {
        if (success) {
            return <h3 className="text-success">{catname} is created</h3>;
        }
    };

    const showError = () => {
        if (error) {
            return <h3 className="text-danger">Category should be unique</h3>;
        }
    };

    const goBack = () => (
        <div className="mt-5">
            <Link to="/admin/dashboard" className="text-warning">
                Back to Dashboard
            </Link>
        </div>
    );

    return (
        <Layout
            title="Add a new category"
            description={`Hi ${name}, ready to add a new category?`}
        >        
            
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {showSuccess()}
                    {showError()}
                    {newCategoryFom()}                                                                     
                    {goBack()}                    
                </div>
            </div>
        </Layout>
    );
};

export default AddCategory;
