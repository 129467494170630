import React, { Component } from "react";
import { isAuthenticated } from '../auth';
import { getWorkItem, buildWorkItemDetails } from '../api/apiConfigs';
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import LoadingOverlay from 'react-loading-overlay';

class workItemDebug extends Component {

    constructor(props) {
        super(props)
        this.state = {
            debugDetails: '',
            loading: false,
            loadingMessage: ''
        }
    }

    token = isAuthenticated() && isAuthenticated().token;
    userId = isAuthenticated().userId

    // default model shown is the standard box size uploaded

    componentDidMount() {

        const body = {
            workitem: this.props.match.params.workitem
        }
        getWorkItem(body, this.token).then(data => {
            if (data.error) {
                console.log(data.error)
            } else {
                // console.log(data)
                buildWorkItemDetails(data.reportUrl, this.token).then(details => {
                    if (details.error) {
                        console.log(details.error)
                    } else {
                        this.setState({
                            debugDetails: details
                        })
                    }
                });

            }
        });
        if (window.uchInventorInterface) {
            this.setState({ runningInCAD: true })
        }
    };



    render() {
        const { debugDetails, loading, loadingMessage } = this.state
        console.log(debugDetails)
        return (
            <LoadingOverlay
                active={loading}
                spinner
                text={loadingMessage}
            >
                <Layout
                    title="Debugging Module"
                    description="This debugging information is turned on only for local administrators"
                    className="container-fluid"
                    cadType={this.state.runningInCAD}
                >
                    <div className="container-fluid">
                        
                        <div dangerouslySetInnerHTML={{ __html: `<p>${debugDetails}</p>` }} />;
                    </div>
                    <br />
                </Layout>
            </LoadingOverlay>
        )

    }
}

export default workItemDebug;