import React, { useState, useEffect } from 'react';
import Layout from '../core/Layout';
import { signup } from '../auth';
import { sendMessage } from '../api/apiCore'
import Footer from "../core/Footer"
import CookieConsent from "react-cookie-consent";


const Signup = () => {
    const [values, setValues] = useState({
        name: '',
        lastName: '',
        companyName: '',
        companyType: '',
        email: '',
        password: '',
        terms: false,
        error: '',
        success: false,
        signupnote: true
    });

    const { name, lastName, companyName, companyType, email, password, terms, success, error, signupnote } = values;

    const [runningInCAD, setRunningInCAD] = useState(false);

    const handleChange = name => event => {

        if (name === 'terms') {
            setValues({ ...values, error: false, [name]: event.target.checked });
        }
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    useEffect(() => {
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });
        // alert(name + ' ' + lastName + ' ' + companyName+ ' ' + companyType + ' ' + email + ' ' + password + '  ' + terms )
        signup({ name, lastName, companyName, companyType, email, password, terms }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false, terms: false });
            } else {

                const userMessage = {
                    "subject": 'Your UtilityContent.com account request',
                    "from": "no-reply-utilitycontent@spatialbiz.com",
                    "cc": "steve.kaufman@spatialbiz.com",
                    "to": email,
                    "html": `<html><body>Hello <b>${name}</b>, <p>Thank you for requesting to join <b>UtilityContent.com</b></p> <p>We are setting up your 
                    information now and when things are ready we will send you an email confirming your membership.</p> 
                    <p> Thank you!</p> <p>ps: Please be sure to check you junk or spam folders for emails. </p></body></html>`
                }

                // make request to api to send message
                sendMessage(userMessage).then(data => {
                    // Let the user know something is happening
                    const adminMessage = {
                        "subject": 'Request to join Utility Content',
                        "from": email,
                        "to": "steve.kaufman@spatialbiz.com",
                        "html": `<html><body>From user ${name}<p>from company: ${companyName}, 
                            has requested to join the UtilityContent.com site with email: ${email}</p><p>As a <b>${companyType}
                            </b></p></body></html>`
                    }

                    sendMessage(adminMessage).then(data => {
                        setValues({
                            ...values,
                            name: '',
                            lastname: '',
                            companyName: '',
                            companyType: '',
                            email: '',
                            password: '',
                            terms: false,
                            error: '',
                            success: true,
                            signupnote: false
                        });
                    })

                });


            }
        });
    };

    const signUpForm = () => (

        <form>
            <div className="form-group">
                <label className="text-muted">First Name</label>
                <input onChange={handleChange('name')} type="text" className="form-control" value={name} />
            </div>

            <div className="form-group">
                <label className="text-muted">Last Name</label>
                <input onChange={handleChange('lastName')} type="text" className="form-control" value={lastName} />
            </div>

            <div className="form-group">
                <label className="text-muted">Company Name</label>
                <input onChange={handleChange('companyName')} type="text" className="form-control" value={companyName} />
            </div>

            <div className="form-group">
                <label className="text-muted">Select a company "Type"</label>
                <select onChange={handleChange('companyType')} value={companyType} className="form-control">
                    <option value="" selected></option>
                    <option value="Utility">Utility Company</option>
                    <option value="Services">Engineering Services Provider</option>
                    <option value="Vendor">Vendor</option>
                </select>
            </div>

            <div className="form-group">
                <label className="text-muted">Email (will be forced to lowercase)</label>
                <input onChange={handleChange('email')} type="email" className="form-control" value={email.toLowerCase()} />
            </div>

            <div className="form-group">
                <label className="text-muted">Password</label>
                <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
            </div>
            <div className="form-group">
                <div className="checkbox">
                    <label><input type="checkbox" checked={terms} onChange={handleChange('terms')} />
                        I agree to the UtilityContent.com policies.</label>
                </div>
            </div>
            <button onClick={clickSubmit} className="btn btn-primary">
                Submit
            </button>
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-success" style={{ display: success ? '' : 'none' }}>
            Account request submitted. You will recieve and email when your account is approved.
        </div>
    );

    const showSignUpMessage = () => (
        <div className="alert alert-info" style={{ display: signupnote ? '' : 'none' }}>
            <h6>To gain access and start downloading content simply complete this request form!</h6>
        </div>
    );

    // old one here
    // const showSuccess = () => (
    //     <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
    //         New account is created. Please <Link to="/signin">Signin</Link>
    //     </div>
    // );

    return (
        <Layout
            title="Signup"
            description="Signup to the World's Largest Utilty Content Library"
            className="container-fluid"
            cadType={runningInCAD}
        >
            <CookieConsent
                location="bottom"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "19px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <div className="container col-md-6 offset-md-3">

                <br />
                {showSuccess()}
                {showSignUpMessage()}
                {showError()}
                {signUpForm()}
            </div>
            <br />
            <Footer />
        </Layout>
    );
};

export default Signup;
