import React, { useState, useEffect } from "react";
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import moment from "moment";
import { sendMessage } from '../api/apiCore';
import ReactGA from 'react-ga';
import Accordion from 'react-bootstrap/Accordion'
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { useMediaQuery } from 'react-responsive'

const GetHelp = () => {

    const [state, setState] = React.useState({
        from: '',
        message: '',
        subject: '',
        error: '',
        success: false,
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })



    const handleChange = name => event => {
        let value = "";
        value = event.target.value
        setState({
            ...state,
            [name]: value 
        });

    };


    useEffect(() => {
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/help");

    }, []);


    const sendHelpMessage = e => {
        e.preventDefault();
        const mailMessage = {
            "subject": `${state.subject}`,
            "from": state.from,            
            "to": "steve.kaufman@spatialbiz.com",
            "html": `<html><body>From user ${state.from}<p>${state.message}</p></body></html>`
        }

        console.log(mailMessage)
        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            setState({
                ...state,
                error: '',
                success: true,
                from: '',
                message: '',
                subject: ''
            });

        });

    };

    const showSuccess = () => {
        if (state.success) {
            return (
                <div className="alert alert-info" ><h4>Thanks for your message. </h4></div>
            )
        }
    };



    return (
        <Layout
            title="Utility Content Help"
            description="Q&A for all utilitycontent.com functionality"
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Frequently Asked Questions</h2>
                                <br />

                            </div>


                        </div>
                        <div className="row">

                            <div className="col-md-12">
                                <Accordion defaultActiveKey="1a">

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1a">
                                                <p><h5>Q: I am having trouble searching for content? </h5></p>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1a">
                                            <Card.Body>
                                                <p>A: The home page on utilitycontent.com allows you to search by the various categories
                                                    we've published content under.  The browse page allows you to enter more specific
                                                    searches such as by the file name (top header of each content detail) or
                                                    by any part of the file description. The description is the Largest amount of text
                                                    provided by vendors and is completely searchable.

                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                <p><h5>Q: Are there any costs associated to Utility Content? </h5></p>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <p>A: Access to download and configure content from this site is free. If you are interested in
                                                    having a configured download process specific to your company OR would like to translate some
                                                    special files or formats, contact us below to learn about our services.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                <p><h5>Q: We use our own Document Management system, but I have never heard of the Utility Content
                                                    portal before. What is the background on this platform?  </h5></p>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                <p>A: The Utility Content site started as a simple, searchable file sharing system and has evolved
                                                    into a utility industry platform that automates the transformation of native CAD files into
                                                    precisely prepared models for immediate use within the various AutoCAD software like Inventor
                                                    and AutoCAD Electrical.
                                                </p>
                                                <p>
                                                    This platform will not replace your document management system but would be used for designers
                                                    to search for content, download to your internal network and then load into your document
                                                    management system with your own company details on the content.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                <h5>Q: We have heard about the NAUCSI group. How do we obtain access to that group?  </h5>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                                <p>A: The “North American Utilities Content Sharing Initiative” to help our customers gain
                                                    access to a broader selection of design content in a variety of formats whether it be
                                                    3D models, 2D dwg blocks or even engineering data in spreadsheet formats.
                                                    The goal is the same. Convert the data to useable formats for all Utility Companies involved.
                                                </p>
                                                <p>
                                                    This group is really targeted at Utility Company members of the Substation Design Solutions Industry Consortium
                                                    (SDSIC) that are currently undertaking, have completed, or are investing in content development for Substation
                                                    Design.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                <h5>Q: : Do the downloaded parts include the Work Geometry required for SDS? </h5>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                                <p>A: Not all parts include SDS Intelligence.  Users must download a native Inventor
                                                    part file (ipt). Use the filter SDS Intelligence to list parts with the required
                                                    Work Geometry or refer to the Tags in the Details of the part.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                                <h5>Q: I saw a demo video showing advanced "Low Code" processing of custom downloads. Where can
                                                    I learn more about that? </h5>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                                <p>A: We have been developing a low-code environment for customers that would like to administer their
                                                    processes and create configurable content. We are implementing this with our NAUCSI partners, but
                                                    we can also set this up within current content for others. If you are interested in learning more
                                                    or discussing what this low-code environment could do for your team, contact us below.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                                <h5>Q: I saw a demo showing Utility Content tools inside Inventor or AutoCAD. How do I get those? </h5>
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                                <p>A: We are currently sharing the beta version of our CAD tools with customers who are open to adopting
                                                    the process, are willing to be testers, provide feedback and work with us to improve the functionality
                                                    of those tools. We ask that you have admin rights on your CAD setup to be involved as a beta tester.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                </Accordion>

                            </div>
                        </div>
                    </div>
                </div>

                <br />

                <div className="row">
                    <div className="col-md-12">
                    </div>
                </div>
                <br />
                <br />
                <br />
                <hr />
                <div className="row">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <h3>Need other help or want to learn more? Send us a Note!</h3>
                                {showSuccess()}
                                <form onSubmit={sendHelpMessage}>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">From (your email):</label>
                                        <input name="from" type="text" value={state.from} onChange={handleChange("from")} className="form-control" id="recipient-name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="subject" className="col-form-label">Subject:</label>
                                        <input name="subject" value={state.subject} onChange={handleChange("subject")} type="text" className="form-control" id="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Message:</label>
                                        <textarea name="message" value={state.message} onChange={handleChange("message")} className="form-control" id="message-text"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </form>

                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
            <Footer />
        </Layout>
    )

}

export default GetHelp;