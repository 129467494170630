import React from "react";
import Menu from "./Menu";
import SideBar from "../core/sidebar";
import MediaQuery from 'react-responsive';

// import "../styles.css";
import './sidemenuStyles.css'

const Layout = ({
    title = "Title",
    description = "Description",
    className,
    children,
    isMobile
}) => (
    <div>
        <MediaQuery minDeviceWidth={1224}>
            {!isMobile && <Menu />}
            {isMobile && <SideBar pageWrapId={"page-wrap"} outerContainerId={"App"} />}
        </MediaQuery>
        <div className="jumbotron" style={{ height: "10rem" }}>
            <h2>{title}</h2>
            {!isMobile && <><p className="lead">{description}</p>
             </>}
        </div>
            <div className={className}>{children}</div>
       
    </div>

);

export default Layout;
