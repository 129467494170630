import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from './Layout';
import { getCart } from './cartHelpers';
import Checkout from './Checkout';
import { removeItem } from './cartHelpers';
import ReactGA from 'react-ga';
import { Card, Button } from 'react-bootstrap';
import { isAuthenticated } from "../auth/index";
import { useMediaQuery } from 'react-responsive'


const Cart = () => {
    const [items, setItems] = useState([]);
    const [run, setRun] = useState(false);
    const [runningInCAD, setRunningInCAD] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const init = () => {
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }

    useEffect(() => {
        init();
        setItems(getCart());
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/cart");
    }, [run]);

    function roundUsing(func, number, prec) {
        var tempnumber = number * Math.pow(10, prec);
        tempnumber = func(tempnumber);
        return tempnumber / Math.pow(10, prec);
    }

    function returnLinkName() {
        if (isAuthenticated() && isAuthenticated().role === "admin") {
            return '/admin/downloads'
        } else {
            return '/downloads'
        }
    }

    const showItems = items => {

        return (
            <>

                {items.map((content, i) => (
                    <Card>
                        <Card.Header as="h5">{items[i].name}</Card.Header>
                        <Card.Body>
                            {/* <Card.Title>Special title treatment</Card.Title> */}
                            {/* <Card.Title><img alt="" style={{ height: "100px" }} src={items[i].photo} /></Card.Title> */}
                            <Card.Text>
                                <div className="row">
                                    <div className="col-md-5">
                                        <img alt="" style={{ height: "100px" }} src={items[i].photo} /> <br />
                                    </div>
                                    <div className="col-md-5">
                                        {items[i].description} <br />
                                        {items[i].brand} <br />
                                        {roundUsing(Math.floor, (items[i].fileSize / 1000000), 3)} Mb <br />
                                        {items[i].fileType} <br />
                                    </div>
                                    <div className="col-md-2">
                                        <Button className="btn btn-sm btn-danger text-right"
                                            onClick={() => { removeItem(content._id); setRun(!run); }}>Remove
                                </Button>
                                    </div>
                                </div>
                            </Card.Text>

                        </Card.Body>
                    </Card>

                    // <>
                    //     <tr>
                    //         <td><img alt="" style={{ height: "100px" }} src={items[i].photo} /> </td>
                    //         <td><Link to={`/content/${content._id}`} className="mr-2">
                    //             {items[i].name}
                    //         </Link>
                    //             <br />
                    //             {items[i].description} <br />
                    //             {items[i].brand}

                    //         </td>
                    //         <td>{roundUsing(Math.floor, (items[i].fileSize / 1000000), 3)} Mb</td>
                    //         <td>{items[i].fileType}</td>
                    //         <td className="text-right">
                    //             <button className="btn btn-sm btn-danger"
                    //                 onClick={() => { removeItem(content._id); setRun(!run); }}>Remove
                    //             </button>
                    //         </td>
                    //     </tr>
                    // </>
                ))}
            </>

        );
    };

    const noItemsMessage = () => (
        <>
        <h3>
            No Active Files
        </h3>       
        <p>
            <Link to={returnLinkName()} className="mr-2">
                click here to see all your past downloads
            </Link>
        </p>
        </>
    );

    return (
        <Layout
            title="Download List"
            description="Manage your active downloads. Add, remove or continue collecting before generating downloads."
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">

                        <div className="row">
                            <div className="col-md-5">
                                <h5> You have collected {`${items.length}`} files to download</h5>
                            </div>
                            <div className="col-md-2" >
                                <br />
                            </div>
                            <div className="col-md-5">
                                {!runningInCAD &&
                                    <a href="/browse" className="btn btn-primary btn-sm btn-block">Continue Browsing
								            </a>}
                                {runningInCAD &&
                                    <a href="/cadsearch" className="btn btn-primary btn-sm btn-block">Continue Browsing
								            </a>}
                            </div>
                        </div>
                        <br />
                    </div>
                </div>



                <div className="row">
                    <div className="col-12">
                        {items.length > 0 ? showItems(items) : noItemsMessage()}
                        <div className="table-responsive">
                            {/* <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col"> </th>
                                        <th scope="col">Content</th>
                                        <th scope="col">File Size</th>
                                        <th scope="col" className="text-center">File Types</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {items.length > 0 ? showItems(items) : noItemsMessage()}

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><strong>Total download Size</strong></td>
                                        <td className="text-right"><Checkout contents={items} setRun={setRun} run={run} /></td>
                                    </tr>
                                </tbody>
                            </table> */}
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <strong>Total download Size</strong>
                        <Checkout contents={items} setRun={setRun} run={run} />
                    </div>
                </div>
            </div>

            <div>
            </div>


        </Layout>
    );
};

export default Cart;
