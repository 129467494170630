import React, { useState, useEffect, useReducer } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Layout from './Layout';
import { read, listRelated, checkMembership } from '../api/apiCore';
import Card from './Card';
import Footer from './Footer';
import { addItem } from './cartHelpers';
import { FaSearch, FaFileDownload } from 'react-icons/fa'
import Image from 'react-image-resizer';
import { isAuthenticated } from "../auth";
import moment from 'moment';
import ReactGA from 'react-ga';


const Content = props => {
    const [content, setContent] = useState({});
    const [category, setCategory] = useState({});
    const [relatedContent, setRelatedContent] = useState([]);
    const [setError] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const [vendorInfo, setVendorInfo] = useState({ id: '', name: '', logo: '', employees: [] })
    const [contentOwner, setContentOwner] = useState({ id: '', name: '', email: '' });
    // destructure user and token from localstorage
    const { userId, token } = isAuthenticated();

    const [naucsi, setNaucsi] = useState(false);
    const [runningInCAD, setRunningInCAD] = useState(false);

    const loadSingleContent = contentId => {
        read(contentId).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setContent(data);
                setContentOwner(data.user);
                setVendorInfo(data.vendor);
                setCategory(data.category)                

                if (isAuthenticated()) {
                    // check for NAUCSI membership                    
                    checkMembership(data.vendor._id, userId, token).then(status => {
                        // isMemberofNAUCSI = status
                        if (status === true) {
                            setNaucsi(true)
                        } else {
                            setNaucsi(false)
                        }
                    })
                }
                // fetch related contents               
                listRelated(data._id).then(data => {
                    if (data.error) {
                        setError(data.error);
                    } else {
                        setRelatedContent(data);
                    }
                });

            }
        });
    };

    useEffect(() => {
        const contentId = props.match.params.contentId;
        loadSingleContent(contentId);
        window.scrollTo(0, 0);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/content/" + contentId);
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }

    }, [props]);

    const addToCart = () => {
        // console.log('added');
        addItem(content, setRedirect(true));
    };

    const shouldRedirect = redirect => {
        if (redirect) {
            return <Redirect to="/cart" />;
        }
    };

    const style = {
        image: {
            background: '#fefefe',
        },
    };

    function checkMemberStatus() {
        // if this content is part of NAUCSI then
        if (vendorInfo._id === '5ef0fa557693db248cafa992') {
            // if the current user is a NAUCSI member, show button, if not, show warning           
            if (naucsi === true) {
                return <button onClick={addToCart} className="btn btn-lg btn-outline-primary text-uppercase">Add to Downloads<FaFileDownload /></button>
            } else {
                return <div className="bg-warning"><h5 >You need to be a NAUCSI member to download this content <b><a href={`/vendor/${content.vendor._id}`} >learn more</a></b></h5></div>
            }
        } else {
            return <button onClick={addToCart} className="btn btn-lg btn-outline-primary text-uppercase">Add to Downloads<FaFileDownload /></button>
        }
    }

    function getVendorInfo() {
        return <a href={`/vendor/${vendorInfo._id}`} ><Image src={vendorInfo.logo} alt={vendorInfo.name} height={30} style={style.image} /></a>
    };

    function editAsAdmin() {
        if (isAuthenticated()) {
            if (userId === contentOwner._id) {
                return <a className="btn btn-danger" href={`/admin/content/update/${props.match.params.contentId}`} >Edit</a>
            }
        }
    };

    function roundUsing(func, number, prec) {
        var tempnumber = number * Math.pow(10, prec);
        tempnumber = func(tempnumber);
        return tempnumber / Math.pow(10, prec);
    }

    function getFileSize(filesize) {
        return roundUsing(Math.floor, (filesize / 1000000), 1)
    };

    function getFileTypes() {
        if (content.content_type === 'Model') {
            if (content.runConversions) {
                return ('IPT, STEP, DWG (3D)')
            } else {
                return ('IPT')
            }
        } else {
            return ('dwg')
        }
    }

    return (
        <Layout
            title={content && content.name}
            description={content && content.description && content.description.substring(0, 100)}
            className="container-fluid"
            cadType={runningInCAD}
        >

            {editAsAdmin()}

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">

                                {getVendorInfo()}
                                <Image
                                    src={content.photo}
                                    alt={content.name}
                                    height={450}
                                    width={450}
                                    style={style.image}
                                />

                            </div>
                            <div className="col-md-6">
                                <h3 className="title mb-3">{content.name}</h3>

                                <p>
                                    <span className="price h3 text-warning">
                                        <span className="currency">File Size: </span><span className="num">{getFileSize(content.fileSize)}</span>
                                    </span>
                                    <span> Mb</span>
                                </p>
                                <dl className="item-property">
                                    <dt>Description</dt>
                                    <dd><p>{content.description}</p></dd>
                                    <dt>Category</dt>
                                    <dd><p>{category.name}</p></dd>
                                </dl>
                                <dl className="param param-feature">
                                    <dt>Manufacturer details</dt>
                                    <dd><b>{content.brand}</b></dd>
                                    <dd>Uploaded by: <b>{contentOwner.email}</b></dd>
                                </dl>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <dl className="param param-inline">
                                            <dt>Available file Types: </dt>
                                            <dd>{getFileTypes()}</dd>
                                        </dl>
                                    </div>
                                </div>                                
                                {checkMemberStatus()}
                                <hr />
                                <dl className="param param-feature">
                                    <dt>Content Details</dt>
                                    <dd>Content Version Number: <b> {content.edition_number}</b></dd>
                                    <dd>Content Last Updated on: <b>{moment(content.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</b></dd>
                                    <dd>Product Family: <b>{content.product_family}</b></dd>
                                    <dd>Product Group: <b>{content.product_group}</b></dd>
                                    <dd>Unique Reference: <b>{content.unique_product_ref}</b></dd>
                                    <dd>Vendor Catalog Page: <b>{content.Links}</b></dd>
                                </dl>
                                <dl className="param param-feature">
                                    <dt>Other Details</dt>
                                    <dd>comments on CAD file: <b>{content.cad_comments}</b></dd>
                                    <dd>Tags to filter by: <b>{`${content.tags }`}{ ' '}</b></dd>
                                </dl>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="card">
                {shouldRedirect(redirect)}
                <div className="card justify-content-center">
                    <div className="row">
                        <aside className="col-sm-5 border-right">
                            <article className="gallery-wrap">
                                <div className="img-big-wrap">
                                    <div>
                                        {getVendorInfo()}
                                        <Image
                                            src={content.photo}
                                            alt={content.name}
                                            height={450}
                                            width={450}
                                            style={style.image}
                                        />
                                    </div>
                                </div>
                            </article>
                        </aside>
                        <aside className="col-sm-7">
                            <article className="card-body p-5">
                                <h3 className="title mb-3">{content.name}</h3>

                                <p>
                                    <span className="price h3 text-warning">
                                        <span className="currency">File Size: </span><span className="num">{getFileSize(content.fileSize)}</span>
                                    </span>
                                    <span> Mb</span>
                                </p>
                                <dl className="item-property">
                                    <dt>Description</dt>
                                    <dd><p>{content.description}</p></dd>
                                </dl>
                                <dl className="param param-feature">
                                    <dt>Manufacturer details</dt>
                                    <dd><b>{content.brand}</b></dd>
                                    <dd>Uploaded by: <b>{contentOwner.email}</b></dd>
                                </dl>
                                <dl className="param param-feature">
                                    <dt>Content Details</dt>
                                    <dd>Content Version Number: <b> {content.edition_number}</b></dd>
                                    <dd>Content Last Updated on: <b>{moment(content.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}</b></dd>
                                    <dd>Product Family: <b>{content.product_family}</b></dd>
                                    <dd>Product Group: <b>{content.product_group}</b></dd>
                                    <dd>Unique Reference: <b>{content.unique_product_ref}</b></dd>
                                    <dd>Vendor Catalog Page: <b>{content.Links}</b></dd>
                                </dl>
                                <dl className="param param-feature">
                                    <dt>Other Details</dt>
                                    <dd>comments on CAD file: <b>{content.cad_comments}</b></dd>
                                    <dd>Tags to filter by: <b>{content.tags}</b></dd>
                                </dl>

                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <dl className="param param-inline">
                                            <dt>Available file Types: </dt>
                                            <dd>{getFileTypes()}</dd>
                                        </dl>
                                    </div>
                                </div>
                                <hr />
                                <button onClick={addToCart} className="btn btn-lg btn-outline-primary text-uppercase">Add to Downloads<FaFileDownload /></button>
                            </article>
                        </aside>
                    </div>
                </div>
            </div> */}

            <div className="container justify-content-center">
                <br />
                <hr />
            <h4>Related Content from the {category.name} Category</h4>
            <br/>
            </div>
            <div className="row justify-content-center">
                {relatedContent.map((p, i) => (
                    <div key={i}>

                        <Card
                            cardStyle={{ width: '18rem' }}
                            content={p}
                            imageSize={225}
                        />
                    </div>
                ))}
            </div>



            <Footer />
        </Layout>

    );
};

export default Content;
