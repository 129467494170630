import React, { useState, useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import Image from 'react-image-resizer';
import moment from "moment";
import { sendMessage } from '../api/apiCore';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'

const ForVendors = () => {

    const [state, setState] = React.useState({
        from: "",
        message: "",
        subject: "",
        success: false
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const style = {
        image: {
            background: '#fefefe',
        },
    };


    useEffect(() => {
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/forvendors");
    }, []);

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
          ...state,
          [evt.target.name]: value
        });
      }

    const sendVendorMessage = e => {
        e.preventDefault();

        const mailMessage = {
            "subject": `${state.subject}`,
            "from": state.from,
            "to": "steve.kaufman@spatialbiz.com",
            "htmlContent": `<html><body>From user ${state.from},<p>${state.message}</p></body></html>`
        }

        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            setState({
                ...state,
                from: "",
                message: "",
                subject: "",
                success: true
              });

        });

    };

    const showSuccess = () => {
        if (state.success) {
            return (
                <div className="alert alert-info" ><h4>Thanks for your message. </h4></div>
            )
        }
    };



    return (
        <Layout
            title="Our Content Platform for Vendors"
            description="Our platform reaches thousands of Utility Content engineers and designers daily."
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>For Vendors</h2>
                                <br />
                                <p>

                                </p>
                            </div>

                            <div className="col-md-6">

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <h4>Put your content out in front</h4>
                                <br />
                                <p>
                                    We work with our growing consortium of over 190 Utilities and engineering firms, the SDSIC, to create
                                    standards that help streamline this Utility Content platform. The Substation Design Solutions Industry
                                    Consortium (SDSIC) is now 8 years old and is constantly growing as is the demand for content.
                                    Our members are actively using design software on a daily basis and are in constant need of 3D models and
                                    2D aymbols.
                                </p>
                                <p>
                                    Register for free and we'll setup your vendor account so you can get started sharing your products today.
                                </p>
                                <a href="/signup" className="btn btn-outline-primary" >Get Started</a>
                            </div>
                            <div className="col-md-6">
                                <a className="rounded" href={`/vendor/5e41c0d7fbbbccad48fba08c`} ><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/sdsiclogos.png' alt='SDSIC' height={350} style={style.image} /></a>
                                <small>Just a few of our current users</small>
                            </div>

                        </div>
                    </div>
                </div>

                <br />
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">

                            <h2>For Service Providers</h2>
                            <br />


                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <a className="rounded" href={`/vendor/5e3db7052dc86d9650a0e1b0`} ><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/station_example.jpg' alt='SEFCOR' height={300} style={style.image} /></a>
                                <small>Showcase projects or advanced models your team has built.</small>
                            </div>
                            <div className="col-lg-6">
                                <h4>Showcase Your Skills</h4>
                                <br />
                                <p>
                                    If you are a service provider creating designs for Utility Companies, this is a great opportunity to showcase your skills. Share
                                    some the content your team has created and draw attention to your capabilities.  All 3D models are automatically converted to
                                    'shrinkwrapped' parts so none of your unique design IP is shared. Only simple envelopes of exterior surfaces.
                                </p>
                                <p>
                                    Register for free and we'll setup your showcase page so you can get started showing your skills today.
                                </p>
                                <a href="/signup" className="btn btn-outline-primary" >Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <hr />
                <div className="row">

                    <div className="col-md-12 col-centered">
                        <h3 className="text-center">
                            Featured Manufacturers
                        </h3>
                        <br />
                        <div className="col-md-12">
                            <div className="row text-center">

                                <div className="col-md-4 ">
                                    <a className="rounded" href={`/vendor/5e41c0d7fbbbccad48fba08c`} ><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/SEFCOR_LOGO.png' alt='SEFCOR' height={35} style={style.image} /></a>
                                </div>
                                <div className="col-md-4">
                                    <a className="rounded" href={`/vendor/5e42a64c6e0fcb3e50b849f6`} ><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/HOMAC_LOGO.png' alt='HOMAC' height={35} style={style.image} /></a>
                                </div>
                                <div className="col-md-4">
                                    <a className="rounded" href={`/vendor/5e42f4681a52af55c0685315`} ><Image src='https://utilitycontentsite.s3.amazonaws.com/vendor_logos/Southern_States_Logo.png' alt='Southern States' height={35} style={style.image} /></a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    </div>
                </div>
                <br />
                <br />
                <br />
                <hr />
                <div className="row">

                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <h2>Send us a note to learn more!</h2>
                                {showSuccess()}
                                <form onSubmit={sendVendorMessage}>
                                    <div className="form-group">
                                        <label for="recipient-name" className="col-form-label">From (your email):</label>
                                        <input name="from" type="text" value={state.from} onChange={handleChange} className="form-control" id="recipient-name" />
                                    </div>
                                    <div className="form-group">
                                        <label for="subject" className="col-form-label">Subject:</label>
                                        <input name="subject" value={state.subject} onChange={handleChange} type="text" className="form-control" id="subject" />
                                    </div>
                                    <div className="form-group">
                                        <label for="message-text" className="col-form-label">Message:</label>
                                        <textarea name="message" value={state.message} onChange={handleChange} className="form-control" id="message-text"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </form>

                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div>
            <Footer />
        </Layout>
    )

}

export default ForVendors;