import { API } from '../config';
const jwt = require('jsonwebtoken'); // to generate signed token
const CryptoJS = require("crypto-js");

export const signup = user => {
    return fetch(`${API}/signup`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};

export const ucSignIn = async user => {
    try {
        const response = await fetch(`${API}/signin`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(user)
        });
        return await response.json();
    } catch (err) {
        console.log(err);
    }
};

export const verifyIPSource = (ipAddress) => {

    let decodedAndVerified = null;
    //if (sessionStorage.getItem('utilitycontent')) {
    if (localStorage.getItem('utilitycontent')) {
        // const token = JSON.parse(sessionStorage.getItem('utilitycontent'))
        const token = JSON.parse(localStorage.getItem('utilitycontent'))
         decodedAndVerified = jwt.verify(token.token, process.env.CRYPTO_SECRET);
    
        if (!decodedAndVerified) {
            // throw Error("verification returned null");
            // console.log({'error': "verification returned null" })            
            return false;            
        } else {
            // found token and decoded
            var bytes  = CryptoJS.AES.decrypt(decodedAndVerified, process.env.CRYPTO_SECRET);
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));                        
            
            if (decryptedData.ipInfo.IPv4 === ipAddress) {
                // IP matches
                return true;
            } else {
                // Ip no match
                return false;
            }
        }
    } else {
        return false;
    }
};


export const authenticate = (data, next) => {
    if (typeof window !== 'undefined') {
        // sessionStorage.setItem('utilitycontent', JSON.stringify(data));
        localStorage.setItem('utilitycontent', JSON.stringify(data));
        next();
    }
};

export const signout = next => {
    if (typeof window !== 'undefined') {
        // sessionStorage.removeItem('utilitycontent');
        localStorage.removeItem('utilitycontent');
        next();
        return fetch(`${API}/secureSignout`, {
            method: 'GET'
        })
            .then(response => {
                console.log('signout', response);
            })
            .catch(err => console.log(err));
    }
};

export const getIpData = async () => {
    return await fetch('https://geolocation-db.com/json/', {
        method: 'GET'
    })
        .then(response => {
            // console.log('IPdata', response.json());
            return response.json();
        })
        .catch(err => console.log(err));
};

export const isAuthenticated = () => {
    let decodedAndVerified = null;
    
    // if (sessionStorage.getItem('utilitycontent')) {
    if (localStorage.getItem('utilitycontent')) {
        // const token = JSON.parse(sessionStorage.getItem('utilitycontent'))
        const token = JSON.parse(localStorage.getItem('utilitycontent'))
        var finalTokenInfo = null;
        decodedAndVerified = jwt.verify(token.token, 'BEgriUqhJVjhGMKDAFLYAqYclmVUlPzh');
        if (!decodedAndVerified) {
            // throw Error("verification returned null");
            // console.log({'error': "verification returned null" })
            finalTokenInfo = {
                error: "verification returned null"
            }
        } else {

            var bytes  = CryptoJS.AES.decrypt(decodedAndVerified, 'BEgriUqhJVjhGMKDAFLYAqYclmVUlPzh');
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));            

            finalTokenInfo = {
                token: token.token,
                userId: decryptedData._id,
                name: decryptedData.name,
                lastName: decryptedData.lastName,
                email: decryptedData.email,
                companyName: decryptedData.companyName,
                companyType: decryptedData.companyType,
                role: decryptedData.role,
                ipInfo: decryptedData.ipInfo
            }

        }

        // return JSON.parse(localStorage.getItem('utilitycontent'));
        return finalTokenInfo
    } else {
        return false;
    }
};

export const forgotPassword = bodyData => {
    // console.log('email: ', email);
    return fetch(`${API}/forgot-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
    })
        .then(response => {
            console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

export const resetPassword = resetInfo => {
    // alert(resetInfo)
    return fetch(`${API}/reset-password/`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(resetInfo)
    })
        .then(response => {
            console.log('forgot password response: ', response);
            return response.json();
        })
        .catch(err => console.log(err));
};

