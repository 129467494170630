import React, { useState, useEffect } from "react";
import { isAuthenticated } from '../auth';
import { getUserDetails, getVendorIdByName } from "../api/apiAdmin";
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Form, Row, Col, Card, Button, Toast } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'

const UCinventorConfigurators = () => {

    const [state, setState] = useState({
        configProjectName: '',
        configTitle: '',
        description: '',
        rootFileName: '',
        inputDoc: '',
        downloadFileName: '',
        activityId: 'kdmeGbfUPp8lAnrUEYFefipXI6NcGIle.UCHInventorUpdater+Prod',
        outputTemplate: 'SDSTemplate2022.ipt',
        postProcess: 'createPart.iLogicVb',
        vendorId: '',
        userId: '',
        forgeToken: '',
        errorMessage: '',
        newConfigProjectId: '',
        success: false,
        iPropsRead: false,
        hasDwg: 1,
        isTabletOrMobile: useMediaQuery({ query: '(max-width: 1224px)' }),
        runningInCAD: true,
        forge_model_download_name: ''
    });

    // handleChange = handleChange.bind(this);
    // buildConfigurator = buildConfigurator.bind(this);
    // getiprops = getiprops.bind(this);
    // verifyInputs = verifyInputs.bind(this);


    const userId = isAuthenticated().userId
    // default model shown is the standard box size uploaded


    const handleChange = name => event => {
        let value = "";
        value = event.target.value
    
        if (name === 'configProjectName') {
            var specialChars = /[!-/:-@[-`{-~]/;
            var spaceChar = /^\S+$/;

            // test if the input value has no special characters or spaces in it 
            if (specialChars.test(value) || !spaceChar.test(value)) {
                setState({
                    ...state,
                    errorMessage: 'The Project Title must have no special characters, capitals or spaces',
                    [name]: value.toLowerCase()
                })

            } else {
                setState({
                    ...state,
                    errorMessage: '',
                    [name]: value.toLowerCase()
                })
            }
        } else {
            setState({
                ...state,
                errorMessage: '',
                [name]: value
            })
        }



    };

   

    useEffect(() => {
        // use the user details to grab the vendor Id associated to them
        getUserDetails(userId, isAuthenticated().token).then(data => {
            if (data.error) {
            } else {
                getVendorIdByName(data.companyName).then(vendor => {
                    if (vendor.error) {
                        
                    } else {
                        console.log(vendor)
                        setState({
                            ...state,
                            vendorId: vendor[0]._id,
                            userId: userId,                            
                        })
                    }
                })
            }
        });
        // if (window.uchInventorInterface) {
        //     setState({ runningInCAD: true })
        // }
    }, []);




    const showSuccess = () => {

        if (state.success && state.initialRun) {
            return (
                // <div className="alert alert-success" style={{ display: state.success ? '' : 'none' }}>
                //     <a href={forge_model_download_url}
                //         className="btn btn-outline-primary mt-2 mb-2 card-btn-1"> {forge_model_download_name} file <FaFileDownload />
                //     </a>
                // </div>
                <Toast>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                        <strong className="mr-auto">File Created!</strong>
                        <small>5 seconds ago</small>
                    </Toast.Header>
                    <Toast.Body>{state.forge_model_download_name}</Toast.Body>
                </Toast>
            )
        }
    };

    const getiprops = () => {
        //this will set the file name
        const rootFileName = window.uchInventorInterface.getCurrentFileName()
        if (rootFileName !== 'none') {
            setState({
                ...state,
                rootFileName: rootFileName,
                configProjectName: 'config_' + rootFileName.split('.')[0].replace(/\s/g, "").toLowerCase(),
                downloadFileName: rootFileName.split('.')[0].replace(/\s/g, "_") + "_configured.ipt",
                inputDoc: rootFileName,
                iPropsRead: true,
                errorMessage: ''
            })
        } else {
            setState({
                ...state,
                errorMessage: 'Please Load a Model First!'
            })
        }
    };

    const verifyInputs = async () => {
        var errorMessage = ''
        const rootFileName = window.uchInventorInterface.getCurrentFileName()

        if (rootFileName.split(".")[1] === 'iam') {
            const dwfName = rootFileName.replace(".iam", ".dwfx")
            const findDwfx = await window.uchInventorInterface.findFile(dwfName)

            if (findDwfx === false) {
                // check file inputs            
                errorMessage = 'There was no DWF file found. You must create a DWF file with the same name from the DWG file.'
            } else {
                errorMessage = ''
            }

            const zipName = rootFileName.replace(".iam", ".zip")
            const findZip = await window.uchInventorInterface.findFile(zipName)

            if (findZip === false) {
                // check file inputs                        
                errorMessage = 'There was no zip file found. There must be a zip of all files required for this configurator.'
            }

            if (state.configTitle === '') {
                // check file inputs                        
                errorMessage = 'Title is required.'
            }

            if (state.description === '') {
                // check file inputs                        
                errorMessage = 'Description is required.'
            } else {
                errorMessage = ''
            }

            // check typed inputs
            if (state.configTitle !== '' &&
                state.description !== ''
            ) {
                // flags that it's all good and ready to process
                setState({
                    ...state,
                    errorMessage: errorMessage
                })
                buildConfigurator()
            } else {
                setState({
                    ...state,
                    errorMessage: 'Check that your inputs are not empty'
                })
            }
        } else {
            if (state.configTitle === '') {
                // check file inputs                        
                errorMessage = 'Title is required.'
            } else {
                errorMessage = ''
            }

            if (state.description === '') {
                // check file inputs                        
                errorMessage = 'Description is required.'
            } else {
                errorMessage = ''
            }

            // check typed inputs
            if (state.configTitle !== '' &&
                state.description !== ''
            ) {
                // flags that it's all good and ready to process
                setState({
                    ...state,
                    errorMessage: errorMessage
                })
                buildConfigurator()
            } else {
                setState({
                    ...state,
                    errorMessage: 'Check that your inputs are not empty'
                })
            }
        }
    };

    const buildConfigurator = async () => {
        // if there are no messages on the page, populate the database
        if (state.errorMessage === '') {
            const configDetails = {
                downloadFileName: state.downloadFileName,
                inputDoc: state.inputDoc,
                configProjectName: state.configProjectName,
                title: state.configTitle,
                description: state.description,
                vendor: state.vendorId,
                activityId: state.activityId,
                rootFileName: state.rootFileName,
                outputTemplate: state.outputTemplate,
                postProcess: state.postProcess,
                userId: state.userId
            }
            console.log(configDetails)
            if (window.uchInventorInterface && (state.rootFileName.split('.')[1] === 'iam')) {
                const cadResponse = await window.uchInventorInterface.buildAssemblyConfiguratorProject(configDetails)
                // const cleanedResponse = String(cadResponse.replace('"', ''))
                if (cadResponse === 'The remote server returned an error: (400) Bad Request.') {
                    setState({
                        errorMessage: 'The remote server returned an error: (400) Bad Request.'
                    }) 
                } else {
                    setState({
                        ...state,
                        errorMessage: '',
                        configProjectName: '',
                        configTitle: '',
                        description: '',
                        newConfigProjectId: cadResponse
                    })
                }
            } else {
                const cadResponse = await window.uchInventorInterface.buildPartConfiguratorProject(configDetails)
                // const cleanedResponse = String(cadResponse.replace('"', ''))
                if (cadResponse === 'The remote server returned an error: (400) Bad Request.') {
                    setState({
                        ...state,
                        errorMessage: 'The remote server returned an error: (400) Bad Request.'
                    })
                } else {
                    setState({
                        ...state,
                        errorMessage: '',
                        configProjectName: '',
                        configTitle: '',
                        description: '',
                        newConfigProjectId: cadResponse
                    })
                }
            }
        }

    };

    return (
        <Layout
            title="Configurator Builder"
            description="Use this wizard to publish your your iLogic project to the web."
            className="container-fluid"
            isMobile={true}
        >
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/configAdmin/${userId}`}>Back to Config admin</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Configurator Builder</li>
                    </ol>
                </nav>
                <br />
                <Row>
                    <Col md={12}>
                        {state.errorMessage && <div className="alert alert-danger">{state.errorMessage}</div>}
                        {state.newConfigProjectId &&
                            <div className="alert alert-danger">
                                {/* <a href={`/uchconfigurator/${state.newConfigProjectId}`} >{`Preview the ${state.configProjectName} configurator`}</a>                                     */}
                                <a href={`https://www.utilitycontent.com/configTesting/${state.newConfigProjectId}`} >{`Preview the ${state.configProjectName} configurator`}</a>
                            </div>}
                        <Card>
                            <Card.Header>
                                <Card.Body>
                                    <Form>

                                        <Form.Group>
                                            <Form.Label>Title: </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                name='configTitle'
                                                defaultValue={state.configTitle}
                                                onChange={handleChange('configTitle')}
                                            />
                                            <Form.Text className="text-muted">
                                                Project Title for this configurator project
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Description: </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                name='description'
                                                defaultValue={state.description}
                                                onChange={handleChange('description')}
                                            />
                                            <Form.Text className="text-muted">
                                                Project description for this configurator project
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                name='rootFileName'
                                                defaultValue={state.rootFileName}
                                                onChange={handleChange('rootFileName')}
                                            />
                                            <Form.Text className="text-muted">
                                                Root File Name
                                            </Form.Text>
                                        </Form.Group>


                                        {state.iPropsRead && <Form.Group>
                                            <Button
                                                variant="outline-primary"
                                                onClick={verifyInputs}>Build Configurator
                                            </Button>
                                        </Form.Group>
                                        }

                                        <Form.Group>
                                            <Button
                                                variant="outline-warning"
                                                onClick={getiprops}>Read iprops
                                            </Button>
                                        </Form.Group>
                                        {/* // debuggin only  */}
                                            <p>{state.downloadFileName}</p>
                                            <p>{state.configProjectName}</p>
                                            <p>{state.configTitle}</p>
                                            <p>{state.description}</p>
                                                                                   
                                            <p>{state.rootFileName}</p>                                                                        
                                              
                                            <ht/>
                                            <p>{'activityId: ' + state.activityId}</p>   
                                            <p>{'output template: ' + state.outputTemplate}</p>
                                            <p>{'post process: ' + state.postProcess}</p>
                                            <p>{'user id: ' + state.userId}</p>   
                                            <p>{'vendor Id: ' + state.vendorId}</p>    
                                    </Form>
                                </Card.Body>
                            </Card.Header>
                        </Card>
                    </Col>
                </Row>

            </div>
            <br />
        </Layout>

    )

}

export default UCinventorConfigurators;