/* eslint-disable react/jsx-max-props-per-line */
import React, { useState } from 'react';
import AWS from "aws-sdk";
import {Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, TextField, Button, Typography} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ProgressBar from './progress-bar';
import { createNewFormaContent, getAllFormaContentByUser } from '../api/apiCore';
import { isAuthenticated } from '../auth';

// This should be moved to the .env file
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export default function FormAddfileDialog({ filesAdded, userId, S3_BUCKET, REGION }) {
    const [progress, setProgress] = useState(0);
    const token = isAuthenticated().token;
    const pcFileExtensionList = ['dwg', 'ipt', 'iam', 'rvt', 'dgn']


    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
        httpOptions: {
            timeout: 500000
        }
    });

    const [values, setValues] = useState({
        open: false,
        category: '',
        file: '',
        error: '',
        loading: false,
        success: false,
        fileName: '',
        fileType: '',
        fileSize: '',
        bucket: '',
        region: '',
        fileExtension: '',
        noCancel: false
    })

    const {
        open,
        file,
        error,
        loading,
        success,
        bucket,
        fileName,
        fileSize,
        fileExtension,
        noCancel
    } = values;


    const handleClickOpen = () => {
        setValues({ ...values, open: true });
    };

    const handleCancel = () => {
        setValues({
            category: '',
            file: '',
            error: '',
            loading: false,
            success: false,
            fileName: '',
            fileType: '',
            fileSize: '',
            bucket: '',
            region: '',
            fileExtension: ''
        });
        setProgress(0)
    };

    const handleClose = () => {

        if (fileSize > 0) {
            var cleanFileName = String(fileName).replace(/\s/g, "_")
            if (pcFileExtensionList.includes(cleanFileName.split(".")[1])) {
                
                createNewFormaContent(token, values, userId).then(data => {
                    if (data.error) {
                        setValues({ ...values, error: data.error, });
                    } else {
                    }
                });
            }

            getAllFormaContentByUser(token, userId).then(data => {
                filesAdded(data)
            });

            setValues({ open: false, success: false, file: '' });
            setProgress(0)

        } else {
            setValues({ open: false, success: false, file: '' });
            setProgress(0)
        }
    };

    const setupStorageLink = (fileExt, S3_BUCKET, userId, fileName) => {
        
        let coreBucketName = `https://${S3_BUCKET}.s3.amazonaws.com/formacontent`;

        return `${coreBucketName}/${userId}/${fileName}`        

    };

    const handleChange = name => event => {
        var fileName = '';
        var fileSize = '';
        var fileExt = '';
        let value = '';

        if (name === 'file' & event.target.files !== 0) {
            setValues({ ...values, fileSize: event.target.files[0].size })  // used for processing stream

            value = event.target.files[0]
            fileName = event.target.files[0].name
            fileSize = event.target.files[0].size
            fileExt = event.target.files[0].name.split(".")[1];

            // Remove spaces from file name            
            var cleanFileName = String(fileName).replace(/\s/g, "_");

            setValues({
                ...values,
                [name]: value,
                userId: userId,
                bucket: S3_BUCKET,
                region: REGION,
                error: '',
                fileName: cleanFileName,
                fileExtension: fileExt,
                fileSize: fileSize,
                // storageLink: `s3://${S3_BUCKET}/${fileName}`, 
                storageLink: setupStorageLink(fileExt, S3_BUCKET, userId, cleanFileName)
            });

        } else {
            value = event.target.value
            setValues({
                ...values,
                [name]: value,
                userId: userId,
                error: '',
                bucket: S3_BUCKET,
                region: REGION
            });
        }


    }

    const showError = () => (
        <Alert severity="error" style={{ display: error ? '' : 'none' }}>
            <p>Error</p>
            Error uploading file — <strong>{error}</strong>
        </Alert>

    );

    const showSuccess = () => (
        <Alert severity="success" style={{ display: success ? '' : 'none' }}>
            <p>Success</p>
            {`${file}`} was updloaded!<strong><br /><br />You will be notified as soon as file has been processed successfully!</strong>
        </Alert>

    );

    const uploadFile = async (file) => {

        const cleanFileName = String(file.name).replace(/\s/g, "_")

        const params = {
            ACL: "public-read",
            Body: file,
            Bucket: S3_BUCKET,
            Key: "formacontent/" + userId + "/" + cleanFileName,
        };
        console.log(params)
        myBucket
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
            })
            .send((err) => {
                console.log('error uploading ' + err)
                setValues({ ...values, error: err });
            });

    };

    const uploadModel = (event) => {
        event.preventDefault();
        if (values.file === '') {
            setValues({ ...values, error: 'Select a file first!' });
        } else {
            uploadFile(values.file)
        }
        
    }


    return (
        <>

            <Tooltip title="Add a new file" placement="top">
                <Button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="contained"
                    endIcon={<AddCircleIcon />}
                >
                    Add File
                </Button>


            </Tooltip>

            <Dialog open={open} onClose={handleCancel}>
                <DialogTitle>Upload New Model For Forma Conversion</DialogTitle>
                <DialogContent>


                    <TextField
                        color="primary"
                        variant="filled"                     
                        id="file"
                        inputProps={{ accept: ".dwg, .ipt, .iam, .rvt, .dgn" }}
                        type="file"
                        name="file"
                        onChange={handleChange("file")}
                    />
                    <Typography variant="caption" display="block" gutterBottom>
                        file types: [.dwg, .ipt, .iam, .rvt, .dgn] <br />
                    </Typography>
                    <ProgressBar bgcolor={"#00695c"} completed={progress} />
                    {(!progress === 0) &&
                        <>
                            <div>Upload is {progress}% Complete </div>                            
                            <ProgressBar bgcolor={"#00695c"} completed={progress} />
                        </>}
                    {showSuccess()}
                    {showError()}

                    {/* </Stack> */}
                </DialogContent>
                <DialogActions>
                    {!noCancel && <Button color="secondary" variant="contained" onClick={handleCancel}>Cancel</Button>}
                    {!(progress === 100) && <Button color="primary" variant="contained"  onClick={uploadModel}>Upload</Button>}
                    {(progress === 100) && <Button color="primary" variant="contained"  onClick={handleClose}>Close</Button>}
                </DialogActions>

                

            </Dialog>
        </>
    );
}