import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from 'react-router-dom';
import { listDownloads, getStatusValues, updateDowloadStatus } from "../api/apiAdmin";
import GetModelLinks from '../admin/GetModelLinks'
import moment from 'moment';
import { FaSearch, FaFileDownload } from 'react-icons/fa';
import LoadingOverlay from 'react-loading-overlay';
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'

const UserDownloads = () => {
    const [downloads, setDownloads] = useState([]);
    const [statusValues, setStatusValues] = useState([]);

    const [loading, setLoading] = useState([])

    const { userId, token, name } = isAuthenticated();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    const loadDownloads = () => {
        listDownloads(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setDownloads(data);
            }
        });
    };

    const loadStatusValues = () => {
        getStatusValues(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setStatusValues(data);
            }
        });
    };

    useEffect(() => {
        loadDownloads();
        loadStatusValues();
        setLoading(false);
        ReactGA.initialize('G-F4M83JTFLH'); 
        ReactGA.pageview("/downloads");
        ReactGA.set({ userId: userId });        
    }, []);

    const showOrdersLength = () => {
        if (downloads.length > 0) {
            return (
                <h2 className="text-black display-5">
                    Total downloads: {downloads.length}
                </h2>
            );
        } else {
            return <h2 className="text-danger">No downloads</h2>;
        }
    };


    const handleStatusChange = (e, downloadId) => {
        updateDowloadStatus(userId, token, downloadId, e.target.value).then(
            data => {
                if (data.error) {
                    console.log("Status update failed");
                } else {
                    loadDownloads();
                }
            }
        );
    };


    const getAllFileLinks = (modelLink, content_type, runConversions, brand, awsVersion) => {
        // This generates the links for 3D model (step, ipt and dwg) or Symbol (just dwg)
        if (!window.uchInventorInterface) {
            if (content_type === 'Model') {
                if (runConversions) {
                    return <td>
                        <GetModelLinks
                            modelLink={modelLink}
                            filetomake={'step'}
                            brand={brand}
                            awsVersion={awsVersion}
                        />
                        <GetModelLinks
                            modelLink={modelLink}
                            filetomake={'ipt'}
                            brand={brand}
                            awsVersion={awsVersion}
                        />
                        <GetModelLinks
                            modelLink={modelLink}
                            filetomake={'dwg'}
                            brand={brand}
                            awsVersion={awsVersion}
                        />
                        {/* on hold due to glitch with Forge
                        <GetModelLinks
                            item={fileId}
                            fileType={'2D_dwg'}
                            brand={brand}
                            awsVersion={awsVersion}
                        /> */}
                    </td>
                } else {
                    return <td>
                        <GetModelLinks
                            modelLink={modelLink}
                            filetomake={'ipt'}
                            brand={brand}
                            awsVersion={awsVersion}
                        />
                    </td>
                }
            } 
            if (content_type === 'Acade') {
                return <td>
                    <GetModelLinks
                        modelLink={modelLink}
                        filetomake={'dwg'}
                        brand={brand}
                        awsVersion={awsVersion}
                    />
                </td>
            }
            if (content_type === 'Configurator') {
                return <td>
                    <GetModelLinks
                        modelLink={modelLink}
                        filetomake={'ipt'}
                        brand={brand}
                        awsVersion={awsVersion}
                    />
                    <GetModelLinks
                        modelLink={modelLink}
                        filetomake={'dwg'}
                        brand={brand}
                        awsVersion={awsVersion}
                    />
                </td>
            }
        }
    };

    const insertFromUCHSite = async (modelLink, brand) => {
        const fileExt = modelLink.split(".")
        if (fileExt[1] === "ipt") {
            const fileName = fileExt[0] + ".dwg"
            const response = await window.uchInventorInterface.insertFileFromS3(fileName, "utilitycontentsite", "content/" + brand, "");
        } else {
            const fileName = modelLink
            const response = await window.uchInventorInterface.insertFileFromS3(fileName, "utilitycontentsite", "content/" + brand, "");
        }
    };

    const buildFromS3Stepfile = async (brand, modelLink) => {
        setLoading(true);
        // Inventor Command to start a new file and import the step file
        const tmpFileName = modelLink.split(".")
        const stepFileName = tmpFileName[0] + ".step"
        await window.uchInventorInterface.createFromS3step("content/" + brand, stepFileName)
        setLoading(false)
    };

    const openS3Iptfile = async (brand, modelLink) => {

        setLoading(true);
        // Inventor Command to start a new file and import the step file
        let response = await window.uchInventorInterface.openIPTfromS3("content/" + brand, modelLink)
        if (response) { setLoading(false) };
    };

    const getAllInventorCommands = (brand, modelLink, content_type, runConversions) => {
        // This generates the links for 3D model (step, ipt and dwg) or Symbol (just dwg)
        if (window.uchInventorInterface) {            
            if (content_type === 'Model') {
                if (runConversions) {
                    return <td>
                        {/* Need commands for each type of file */}
                        <button type="submit" onClick={() => { buildFromS3Stepfile(brand, modelLink) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            step file <FaFileDownload />
                        </button>
                        <button type="submit" onClick={() => { openS3Iptfile(brand, modelLink) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            ipt file <FaFileDownload />
                        </button>
                    </td>
                } else {
                    return <td>
                        <button type="submit" onClick={() => { openS3Iptfile(brand, modelLink) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            ipt file <FaFileDownload />
                        </button>
                    </td>
                }
            } else {
                return <td>
                    <button type="submit" onClick={() => { openS3Iptfile(brand, modelLink) }}
                        className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                        ipt file <FaFileDownload />
                    </button>
                </td>
            }
        }
    };

    const getAllACADCommands = (brand, modelLink, content_type, runConversions) => {
        // This generates the links for 3D model (step, ipt and dwg) or Symbol (just dwg)
        if (window.uchInventorInterface) {
            if (content_type === 'Model') {
                if (runConversions) {
                    return <td>
                        {/* Need commands for each type of file */}
                        <button type="submit" onClick={() => { insertFromUCHSite(modelLink, brand) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            3D DWG <FaFileDownload />
                        </button>
                    </td>
                } else {
                    return <td>
                        <button type="submit" onClick={() => { insertFromUCHSite(modelLink, brand) }}
                            className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                            3D DWG<FaFileDownload />
                        </button>
                    </td>
                }
            } else {
                return <td>
                    <button type="submit" onClick={() => { insertFromUCHSite(modelLink, brand) }}
                        className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                        ACAD Symbol <FaFileDownload />
                    </button>
                </td>
            }
        }
    };

    function round(value, precision) {
        if (Number.isInteger(precision)) {
            var shift = Math.pow(10, precision);
            // Limited preventing decimal issue
            return (Math.round(value * shift + 0.00000000000001) / shift);
        } else {
            return Math.round(value);
        }
    }

    function getFileSize(filesize) {
        return round(filesize / 1000000, 3)
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout
                title="Downloads"
                description={`Hello ${name},
                 you can manage all the downloads here`}
                className="container-fluid"
                isMobile={isTabletOrMobile}
            >
                <div className="container">

                    {showOrdersLength()}

                    {downloads.map((o, oIndex) => {
                        return (
                            <div
                                className="mt-5"
                                key={oIndex}
                                style={{ borderBottom: "5px solid indigo" }}
                            >
                                <hr />
                                <h5>Download Id: {o._id}</h5>
                                <h6>Created By: {o.user.name} on {moment(o.createdAt).format('MMMM Do YYYY, h:mm:ss a')} </h6>
                                <p>Total <b>{o.contents.length}</b> files for a total of: <b>{o.totalSize}</b></p>

                                <table className="table table-sm table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>File Name</th>
                                            <th>Rev</th>
                                            <th>File Size</th>
                                            <th>Downloads</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {o.contents.map((p, pIndex) => (
                                            <tr key={pIndex}>
                                            {(p.content_type === 'Model') && <td><Link to={`/content/${p._id}`} className="mr-2">{p.name}</Link></td>}
                                            {(p.content_type === 'Configurator') && 
                                                <td>
                                                    <Link 
                                                        to={`/configurator/${p._id}`} 
                                                        className="mr-2">{`${p.name} configured from ${p.modelLink.replace('uchconfig_', '').replace('/'+p.name, '')}`}
                                                    </Link>
                                                </td>}
                                            <td>{p.edition_number}</td>
                                            <td>{getFileSize(p.fileSize)}</td>
                                            {getAllInventorCommands(p.brand, p.modelLink, p.content_type, p.runConversions)}
                                            {/* {getAllACADCommands(p.brand, p.modelLink, p.content_type, p.runConversions)} */}
                                            {getAllFileLinks(p.modelLink, p.content_type, p.runConversions, p.brand, p.awsVersion)}
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        );
                    })}
                </div>

            </Layout>
        </LoadingOverlay>
    );
};


export default UserDownloads;
