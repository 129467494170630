import React, { useState, useEffect } from 'react';
import { createDownload, createDownloadLink } from '../api/apiCore';
import { emptyCart } from './cartHelpers';
import { isAuthenticated } from '../auth';
import { Link } from 'react-router-dom';
import Select from 'react-select'

const Checkout = ({ contents, setRun = f => f, run = undefined }) => {
    console.log(contents)
    const [data, setData] = useState({
        loading: false,
        success: false,
        clientToken: null,
        error: '',
        instance: {},
        address: ''
    });

    const userId = isAuthenticated() && isAuthenticated().userId;
    const token = isAuthenticated() && isAuthenticated().token;


    useEffect(() => {

    }, []);

    const handleAddress = event => {
        setData({ ...data, address: event.target.value });
    };

    function roundUsing(func, number, prec) {
        var tempnumber = number * Math.pow(10, prec);
        tempnumber = func(tempnumber);
        return tempnumber / Math.pow(10, prec);
    }

    const getTotal = () => {
        return contents.reduce((currentValue, nextValue) => {
            const calcCurrentValue = currentValue + (nextValue.fileSize / 1000000);
            return roundUsing(Math.floor, calcCurrentValue, 2)
        }, 0);

    };

    const showCheckout = () => {
        return isAuthenticated() ? (
            <>{showDropIn()}</>
        ) : (
                <Link to="/signin">
                    <button className="btn btn-primary">Sign in to download</button>
                </Link>
            );
    };
 
    let deliveryAddress = data.address;

    const download = () => {
        setData({ loading: true });

        const size = getTotal(contents)
        //const contentDownloadLink = createDownloadLink(token, contents[0]._id)
       
        const createDownloadData = {
            contents: contents,
            transaction_id: 1,
            totalSize: size
        };
        console.log(createDownloadData)
        createDownload(userId, token, createDownloadData)
            .then(response => {
                emptyCart(() => {
                    setRun(!run); // run useEffect in parent Cart
                    console.log('download succeeded and cart is emptied for this session');
                    setData({
                        loading: false,
                        success: true
                    });
                });
            })
            .catch(error => {
                console.log(error);
                setData({ loading: false });
            });
    };

    // not
    // const buy = () => {
    //     console.log("into the buy command")
    //     setData({ loading: true });
    //     // send the nonce to your server
    //     // nonce = data.instance.requestPaymentMethod()
    //     let nonce;
    //     let getNonce = data.instance
    //         .requestPaymentMethod()
    //         .then(data => {
    //             // console.log(data);
    //             nonce = data.nonce;
    //             // once you have nonce (card type, card number) send nonce as 'paymentMethodNonce'
    //             // and also total to be charged
    //             // console.log(
    //             //     "send nonce and total to process: ",
    //             //     nonce,
    //             //     getTotal(contents)
    //             // );
    //             const paymentData = {
    //                 paymentMethodNonce: nonce,
    //                 amount: getTotal(contents)
    //             };

    //             processPayment(userId, token, paymentData)
    //             console.log("into the processPayment command")
    //                 .then(response => {
    //                     console.log(response);
    //                     // empty cart
    //                     // create order
    //                     const contentDownloadLink = createDownloadLink(token, contents[0]._id)

    //                     const createOrderData = {
    //                         contents: contents,
    //                         transaction_id: response.transaction.id,
    //                         amount: response.transaction.amount,
    //                         address: deliveryAddress,
    //                         contentDownloadLink: contentDownloadLink
    //                     };

    //                     createOrder(userId, token, createOrderData)
    //                     console.log("into the createOrder command")
    //                         .then(response => {
    //                             emptyCart(() => {
    //                                 setRun(!run); // run useEffect in parent Cart
    //                                 console.log('payment success and empty cart');
    //                                 setData({
    //                                     loading: false,
    //                                     success: true
    //                                 });
    //                             });
    //                         })
    //                         .catch(error => {
    //                             console.log(error);
    //                             setData({ loading: false });
    //                         });
    //                 })
    //                 .catch(error => {
    //                     console.log(error);
    //                     setData({ loading: false });
    //                 });
    //         })
    //         .catch(error => {
    //             // console.log("dropin error: ", error);
    //             setData({ ...data, error: error.message });
    //         });
    // };

    const showDropIn = () => (
        <div onBlur={() => setData({ ...data, error: '' })}>
            {contents.length > 0 ? (
                <p>
                    <button onClick={download} className="btn btn-success btn-block">
                        Generate Secure Download Links
                    </button>
                    {/* TODO: incorporate the custom FORGE download processes
                    <button onClick={download} className="btn btn-success btn-block">
                        Run Automated Download Process
                    </button>
                    <Select
                        className=""
                        name="downloadProcess"
                        placeholder="download process"
                        options={[
                            { value: 'BIMcontentdemo', label: 'BIM Content Demo' },
                            { value: 'BIM200download', label: 'BIM 200 download' },
                            { value: 'forge', label: 'Forge test' }
                          ]}
                        // onChange={handleChange("brand")}
                    /> */}
                </p>
            ) : null}
        </div>
    );

    const showError = error => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = success => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            Your download links were generated successfully!
            <br />
            {isAuthenticated() && isAuthenticated().role === "admin" && (
                <Link to="/admin/downloads"><button className="btn btn-primary">Go to Your Secure Download links!</button></Link>
            )}
            {isAuthenticated() && isAuthenticated().role === "vendor" && (
                <Link to="/admin/downloads"><button className="btn btn-primary">Go to Your Secure Download links!</button></Link>
            )}
            {isAuthenticated() && isAuthenticated().role === "subscriber" && (
                <Link to="/downloads"><button className="btn btn-primary">Go to Your Secure Download links!</button></Link>
            )}
        </div>
    );

    const showLoading = loading => loading && <h2 className="text-danger">Loading...</h2>;

    return (
        <>
            <strong>{getTotal()} Mb</strong>
            {showLoading(data.loading)}
            {showSuccess(data.success)}
            {showError(data.error)}
            {showCheckout()}
        </>
    );
};

export default Checkout;
