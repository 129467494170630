import React, { useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { useMediaQuery } from 'react-responsive'
import {
    Container,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PageviewIcon from '@material-ui/icons/Pageview';
import FormAddFormaDialog from '../dialogs/addFormaDialog';
import FormDeleteFileDialog from '../dialogs/deleteFormaContentDialog';
import FormFileDetails from '../dialogs/fileDetailsDialog';
import { getAllFormaContentByUser, getAwsFormaContentLink } from '../api/apiCore';
import { useHistory } from "react-router-dom";

//https://v4.mui.com/components/about-the-lab/
//https://v4.mui.com/components/material-icons/


const FormaContent = () => {
    const [state, setState] = React.useState({
        users: [],
        user: '',
        fullUserList: [],
        userList: [],
        updateMessage: '',
        searchText: '',
        fileList: [],
        formaItems: ''
    })
    const history = useHistory();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // These parameters will be changed in the future once storage decisions are made
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    const refreshPage = () => {
        setTimeout(() => {
            console.log(state.user.token)
            getAllFormaContentByUser(state.user.token, state.user.userId).then(data => {
                // checkAllfileStatus(data)
                setState({
                    ...state,
                    fileList: data
                });
            })
        }, 1000);

    };

    useEffect(() => {
        getAllFormaContentByUser(isAuthenticated().token, isAuthenticated().userId).then(data => {
            setState({
                ...state,
                user: isAuthenticated(),
                fileList: data
            });
        });
    }, []);


    const setFileList = (userId) => {
        getAllFormaContentByUser(isAuthenticated().token, userId).then(data => {
            setState({
                ...state,
                fileList: data
            })
        })
    }

    const filesAdded = () => {
        setFileList(isAuthenticated().userId)
    }

    const handleDownload = (token, fileId, userId, fileName) => {

        getAwsFormaContentLink(token, fileId, userId, fileName).then(data => {
            if (data.error) {
                setState({
                    ...state,
                    error: data.error
                })

            } else {
                window.open(data);
                // history.push(data);
            }
        });
    };

    const filesRemoved = (fileListRemoved) => {
        // add function to remove files
        setFileList(fileListRemoved)
    }

    const handleFileView = () => {
        history.push(`/admin/formaViewer`);
    }

    const handleFormaClick = async () => {
        // const selectedPaths = await window.Forma.selection.getSelection()
        // setState({
        //     ...state,
        //     formaItem: selectedPaths
        // })

        // Fetch mesh representation for the entire proposal and count the number of triangles.
        const mesh = await window.Forma.geometry.getTriangles()
        const numberOfTriangles = mesh.length / 3

        // const siteLimitPaths = await window.Forma.geometry.getPathsByCategory({ category: "site_limit" })
        // const siteLimitFootprint = await window.Forma.geometry.getFootprint({ path: siteLimitPaths[0] })

        console.log('total triangles: ' + numberOfTriangles)
        const currentlySelected = await window.Forma.selection.getSelection()
        const areaMetrics = await window.Forma.areaMetrics.calculate({
            paths: currentlySelected
        })
        console.log(areaMetrics)
        setState({
            ...state,
            formaItem: 'total triangles: ' + numberOfTriangles
        })

    };


    return (
        <Layout
            title={`${isAuthenticated().name}'s Forma Content`}
            description={`for Admin beta testing only`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Admin Forma Content {state.formaItem}</li>
                    </ol>
                </nav>
                <br />
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell className="default-file">
                                                {'File Name'}
                                            </TableCell >
                                            <TableCell className="file-list">
                                                {'Status'}
                                            </TableCell  >
                                            <TableCell className="file-commands">
                                                {'Commands'}
                                            </TableCell  >
                                            <TableCell className="file-status">
                                                {'Status'}
                                            </TableCell >
                                            <TableCell className="file-category">
                                                {'Category'}
                                            </TableCell >
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.fileList && state.fileList.map((file) => {
                                            return (
                                                <>
                                                    <TableRow
                                                        hover
                                                        role="checkbox" tabIndex={-1} key={file._id}>
                                                        <TableCell size="small">
                                                            <p>{file.fileName} </p>
                                                        </TableCell>
                                                        <TableCell size="small">
                                                            {/* {getFileStatus(file.storageLink, file._id)} */}
                                                            {file.fileStatus}
                                                        </TableCell>

                                                        <TableCell size="medium">

                                                            {/* {(file.fileStatus == 'Translation completed') && 
                                                            <FormForgePreview
                                                                disabled={true}
                                                                fileUrn={getFileUrn(file.storageLink)}
                                                                fileExt={file.fileExtension}
                                                                fileStatus={file.fileStatus}
                                                            />} */}

                                                            <FormFileDetails
                                                                fileId={file._id}
                                                            />

                                                            <Tooltip title="download file" placement="top" >
                                                                <IconButton
                                                                    name={"download"}
                                                                    onClick={() => handleDownload(isAuthenticated().token, file._id, isAuthenticated().userId, file.fileName)}
                                                                    size="small"
                                                                    sx={{ ml: 2 }}
                                                                    aria-controls={state.open ? 'account-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={state.open ? 'true' : undefined}
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                            <FormDeleteFileDialog
                                                                fileId={file._id}
                                                                fileName={file.fileName}
                                                                filesRemoved={filesRemoved}
                                                                userId={isAuthenticated().userId}
                                                                fileList={state.fileList}
                                                            />

                                                            <Tooltip title="View file" placement="top" >
                                                                <IconButton
                                                                    name={"view"}
                                                                    onClick={() => handleFileView(file.Name)}
                                                                    size="small"
                                                                    sx={{ ml: 2 }}
                                                                    aria-controls={state.open ? 'account-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={state.open ? 'true' : undefined}
                                                                >
                                                                    <PageviewIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </TableCell>

                                                        <TableCell size="medium">
                                                            <p>Processed</p>
                                                        </TableCell>
                                                        <TableCell size="small">
                                                            {file.category}
                                                        </TableCell>


                                                    </TableRow>
                                                </>
                                            );
                                        })
                                        }
                                        {!state.fileList &&
                                            <TableRow
                                                hover
                                                role="checkbox">
                                                <Alert severity="error" >
                                                    <p>No Forma Content in your library</p>

                                                </Alert>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >
                            <Grid container spacing={1}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => (handleFormaClick())}
                                >
                                    Get Selection
                                </Button>
                                <Grid item xs={2} md={2}></Grid>
                                <Grid item xs={2} md={2}></Grid>
                                <Grid item xs={4} md={4}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        endIcon={<RefreshIcon />}
                                        onClick={() => (refreshPage())}
                                    >
                                        Refresh
                                    </Button>
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <FormAddFormaDialog
                                        filesAdded={filesAdded}
                                        userId={isAuthenticated().userId}
                                        S3_BUCKET={S3_BUCKET}
                                        REGION={REGION}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>

                </Container>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default FormaContent;