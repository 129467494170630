import React, { useState } from "react";
import { ListGroup } from 'react-bootstrap';


const RadioBox = ({ brands, handleFilters }) => {
    const [value, setValue] = useState(0);

    const handleChange = event => {
        handleFilters(event.target.value);
        setValue(event.target.value);
    };

    return brands.map((p, i) => (
        <ListGroup variant="flush">            
                <ListGroup.Item style={{width: "300px"}}>                   
                    <input
                        onChange={handleChange}
                        value={`${p.name}`}
                        name={p}
                        type="radio"
                        className="form-check-input"
                    />
                     {"  " + p.name} 
            </ListGroup.Item>
        </ListGroup>
    ));
};

export default RadioBox;
