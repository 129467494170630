import React, { Component } from "react";
import { forgotPassword } from "../auth";
import Layout from '../core/Layout';

class ForgotPassword extends Component {
    state = {
        email: "",
        message: "",
        error: "",
        runningInCAD: false
    };

    componentDidMount() {
        if (window.uchInventorInterface) {
            this.setState({runningInCAD: true})
        }
    };

    forgotPassword = e => {
        e.preventDefault();
        this.setState({ message: "", error: "" });
        const bodyData = {
            email: this.state.email
        }
        forgotPassword(bodyData).then(data => {
            if (data.error) {
                console.log(data.error);
                this.setState({ error: data.error });
            } else {
                console.log(data.message);
                this.setState({ message: data.message });
            }
        });
    };

    render() {
        return (
           
             <Layout
                    title={'Forgot Your Password?'}
                    description={'Enter your email below and recieve a reset id'}
                    className="container-fluid"
                    cadType={this.state.runningInCAD}
                >
            
            <div className="container">
                <h2 className="mt-5 mb-5">Ask for Password Reset</h2>

                {this.state.message && (
                    <h4 className="bg-success">{this.state.message}</h4>
                )}
                {this.state.error && (
                    <h4 className="bg-warning">{this.state.error}</h4>
                )}

                <form>
                    <div className="form-group mt-5">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Your email address"
                            value={this.state.email}
                            name="email"
                            onChange={e =>
                                this.setState({
                                    email: e.target.value,
                                    message: "",
                                    error: ""
                                })
                            }
                            autoFocus
                        />
                    </div>
                    <button
                        onClick={this.forgotPassword}
                        className="btn btn-raised btn-primary"
                    >
                        Send Password Reset Link
                    </button>
                </form>
            </div>
            </Layout>
           
        );
    }
}

export default ForgotPassword;