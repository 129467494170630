import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Signup from './user/Signup';
import Signin from './user/Signin';
import Home from './core/Home';
import errorPage from './user/errorPage';

// import Calculators from './core/Calculators';
import PrivateRoute from './auth/PrivateRoute';
import Dashboard from './user/UserDashboard';
import AdminRoute from './auth/AdminRoute';
import AdminDashboard from './user/AdminDashboard';
import AddCategory from './admin/AddCategory';
import Bulkupload from './admin/Bulkupload';
import AddContent from './admin/AddContent';
import ManageUsers from './admin/ManageUsers';
import browse from './core/Browse';

import Content from './core/Content'
import Cart from './core/Cart';
import Downloads from './admin/Downloads';
import Profile from './user/Profile';
import ManageContents from './admin/ManageContents';
import UpdateContent from './admin/UpdateContent';
import UpdateCategory from './admin/updateCategory';
import UpdateTags from './admin/UpdateTags';
import AdminReports from './admin/adminReports';
import UserDownloads from './user/UserDownloads'
import ForVendors from './user/ForVendors'
import Naucsi from './user/Naucsi';
import ForgotPassword from "./user/ForgotPassword";
import ResetPassword from "./user/ResetPassword";
import LoadSingleVendor from './core/LoadSingleVendor';
import gethelp from './core/gethelp';

//footer
import Privacy from './core/Privacy';
import Terms from './core/Terms';

// uchConfigurators
import configAdmin from './uchconfigs/uchconfigAdmin';
import singleconfigurator from './uchconfigs/singleconfigurator';
import configTesting from './uchconfigs/configTesting';
import configList from './uchconfigs/uchconfigList';
import configTestList from './uchconfigs/uchconfigListTesting';
import workItemDebug from './uchconfigs/workItemDebug';
import testconfigurator from './uchconfigs/testconfigurator';

// Utility Content CAD Addin Pages
// The following routes will only be used inside the CAD Addin
// For Inventor:
import UCinventorParts from './cad/ucInventorParts';
import UCinventorConfigurators from './cad/ucInventorConfigurators';
import UCinventorConfiguratorUpdate from './cad/ucInventorConfiguratorUpdate';

// Point Cloud pages
// This is for admins only BETA
import PointClouds from './admin/PointClouds';
import PointCloudViewer from './admin/PointCloudViewer';

// Forma Content Pages
// This is for admins only BETA
import FormaContent from './admin/FormaContent';
import FormaViewer from './admin/FormaViewer';
import FormaAnalysis from './admin/FormaAnalysis';


// 2023 Updates
import Newhome from './core/Newhome';

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/Newhome" exact component={Newhome} />
                <Route path="/browse" exact component={browse} />                
                <Route path="/signin" exact component={Signin} />
                <Route path="/signup" exact component={Signup} />
                <Route path="/errorPage" exact component={errorPage} />
                
                <PrivateRoute path="/user/dashboard" exact component={Dashboard} />
                <AdminRoute path="/admin/dashboard" exact component={AdminDashboard} />
                <AdminRoute path="/create/category" exact component={AddCategory} />   
                <AdminRoute path="/create/bulkupload" exact component={Bulkupload} />   
                <AdminRoute path="/create/content" exact component={AddContent} />
                <AdminRoute path="/admin/manageusers" exact component={ManageUsers} />
                <AdminRoute path="/admin/pointclouds" exact component={PointClouds} />
                <AdminRoute path="/admin/pointcloudviewer" exact component={PointCloudViewer} />

                <AdminRoute path="/admin/formaContent" exact component={FormaContent} />
                <AdminRoute path="/admin/formaViewer" exact component={FormaViewer} />
                <AdminRoute path="/admin/formaAnalysis" exact component={FormaAnalysis} />

                <Route path="/content/:contentId" exact component={Content} />
                <Route path="/cart" exact component={Cart} />
                <Route path="/downloads" exact component={UserDownloads} />
                <Route path="/forvendors" exact component={ForVendors} />
                <Route path="/naucsi" exact component={Naucsi} />
                <AdminRoute path="/admin/downloads" exact component={Downloads} />
                <PrivateRoute path="/profile/:userId" exact component={Profile} />
                <PrivateRoute path="/admin/contents" exact component={ManageContents} />
                <PrivateRoute path="/admin/reports" exact component={AdminReports} />
                <AdminRoute path="/admin/content/update/:contentId" exact component={UpdateContent} />
                <AdminRoute path="/admin/category/update/:categoryId" exact component={UpdateCategory} />
                <AdminRoute path="/admin/tags" exact component={UpdateTags} />
                <Route path="/privacy" exact component={Privacy} />
                <Route path="/terms" exact component={Terms} />
                <Route path="/vendor/:vendorId" exact component={LoadSingleVendor} />               
                <Route path="/gethelp" exact component={gethelp} /> 
                <Route exact path="/forgot-password" component={ForgotPassword} />                
                <Route exact path="/reset-password/:resetPasswordToken" component={ResetPassword}/>

                {/* configurators Routes  for running and testing                */}
                <Route path="/configurators" exact component={configList} />
                <PrivateRoute path="/configuratorTests" exact component={configTestList} />
                <PrivateRoute path="/configTesting/:configId" exact component={configTesting} />
                <PrivateRoute path="/configurator/:configId" exact component={singleconfigurator} />
                <PrivateRoute path="/testconfigurator/:configId" exact component={testconfigurator} />
                <PrivateRoute path="/workitem/:workitem" exact component={workItemDebug} />   
                <PrivateRoute path="/configAdmin/:userId" exact component={configAdmin} />     

                {/* Utility Content CAD Addin Pages */}
                {/* The following routes will only be used inside the CAD Addin */}
                <PrivateRoute path="/ucInventorParts" exact component={UCinventorParts} />             
                <PrivateRoute path="/ucInventorConfigurators" exact component={UCinventorConfigurators} />
                <PrivateRoute path="/ucInventorConfiguratorUpdate/:configId" exact component={UCinventorConfiguratorUpdate} />

                {/* Route for old C360 configurator pages */}
                {/* <PrivateRoute path="/configurator/:configuratorId" exact component={LoadSingleConfigurator} /> */}
                {/* <Route path="/configurators" exact component={Configurators} /> */}


            </Switch>
        </BrowserRouter>
    );
};

export default Routes;
