/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import Layout from '../core/Layout';
import { isAuthenticated } from '../auth';
import { Redirect } from 'react-router-dom';
import { getContent, getCategories, updateContent, getTags, updateContentPhoto, updateContentFile } from '../api/apiAdmin';
import Select from 'react-select'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { Container } from 'react-bootstrap';

//import { fixedTags } from "../core/fixedTags";

const UpdateContent = ({ match }) => {
    const [values, setValues] = useState({
        user: '',
        newFileSelected: false,
        contentName: '',
        description: '',
        unique_product_ref: '',
        categories: [],
        tags: [],
        incomingTags: '',
        brand: '',
        edition_number: '',
        product_family: '',
        product_group: '',
        content_type: 'Object',
        design_country: '',
        manufacture_country: '',
        verifiedByManufacturer: '',
        cad_comments: '',
        category: '',
        status: '',
        photo: '',
        modelFile: '',
        photoFile: '',
        loading: false,
        error: '',
        createdContent: '',
        redirectToProfile: false,
        formData: ''
    });
    const [categories, setCategories] = useState([]);
    const [runningInCAD, setRunningInCAD] = useState(false);

    const [tagvalues, setTagValues] = useState({
        fixedTags: [],
    });

    const { fixedTags } = tagvalues;

    const { userId, token, name } = isAuthenticated();
    const {
        contentName,
        description,
        unique_product_ref,
        product_family,
        product_group,
        content_type,
        design_country,
        manufacture_country,
        cad_comments,
        verifiedByManufacturer,
        loading,
        error,
        createdContent,
        redirectToProfile,
        formData
    } = values;

    const init = contentId => {
        getContent(contentId).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                // populate the state
                setValues({
                    ...values,
                    contentName: data.name,
                    tags: [],
                    photo: data.photo,
                    modelLink: data.modelLink,
                    description: data.description,
                    unique_product_ref: data.unique_product_ref,
                    incomingTags: data.tags,
                    categoryName: data.category.name,
                    verifiedByManufacturer: data.verifiedByManufacturer,
                    brand: data.brand,
                    edition_number: data.edition_number,
                    product_family: data.product_family,
                    product_group: data.product_group,
                    design_country: data.design_country,
                    manufacture_country: data.manufacture_country,
                    cad_comments: data.cad_comments,
                    status: data.status,
                    formData: new FormData()
                });
                // load categories
                initCategories();
            }
        });
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }

    };

    // load categories and set form data
    const initCategories = () => {
        getCategories().then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setCategories(data);
            }
        });
        getTags().then(data => {
            if (data.error) {
                setTagValues({ ...tagvalues, error: data.error });
            } else {
                setTagValues({
                    ...tagvalues,
                    fixedTags: data
                });
            }
        });
    };

    useEffect(() => {
        init(match.params.contentId);
    }, []);

    const handleChange = name => event => {
        let value = "";
        if (name === 'photo') {
            value = event.target.files[0]
        } else if (name === 'tags') {
            const finalVal = (event && event.map((c, i) => (
                [
                    c.value
                ]
            )))
            value = finalVal
        } else if (name === 'modelFile') {
            value = event.target.files[0]
        } else if (name === 'category') {
            value = event.value
        } else if (name === 'verifiedByManufacturer') {
            value = event.target.value
        } else {
            value = event.target.value
        }
        //const value = name === 'photo' ? event.target.files[0] : event.target.value;
        //alert(name + ":" + value )   
        //formData.set(name, value);  // changed for IE11 error
        formData.append(name, value);
        setValues({ ...values, [name]: value });
    };

    const handleFileChanges = name => event => {
        let value = "";
        if (name === 'photoFile') {
            value = event.target.files[0]
        } else if (name === 'modelFile') {
            value = event.target.files[0]
        } else {
            value = event.target.value
        }
        //formData.set(name, value); // Changed for IE 11 error

        formData.append(name, value);
        //formData.set('brand', values.brand);
        formData.append('brand', values.brand);

        formData.append('edition_number', (values.edition_number + 1));
        formData.append('categoryName', values.categoryName);
        setValues({ ...values, brand: values.brand });
        setValues({ ...values, categoryName: value });
        setValues({ ...values, [name]: value });
    };

    const clickSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: '', loading: true });
        updateContent(match.params.contentId, userId, token, formData).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    ...values,
                    contentName: data.name,
                    tags: [],
                    description: data.description,
                    unique_product_ref: '',
                    incomingTags: data.tags,
                    categoryName: data.categoryName,
                    brand: data.brand,
                    product_family: data.product_family,
                    product_group: data.product_group,
                    design_country: data.design_country,
                    manufacture_country: data.manufacture_country,
                    verifiedByManufacturer: data.verifiedByManufacturer,
                    cad_comments: data.cad_comments,
                    status: data.status,
                    loading: false,
                    error: false,
                    redirectToProfile: true,
                    createdContent: data.name
                });
            }
        });
    };

    const clickPhotoUpdate = event => {
        event.preventDefault();
        setValues({ ...values, error: '', loading: true });
        updateContentPhoto(match.params.contentId, userId, token, formData).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    loading: false,
                    error: false,
                    redirectToProfile: true,
                    createdContent: data.name
                });
            }
        });
        // } else {
        //     setValues({ ...values, error: 'Please Choose a photo for Updating', loading: false, redirectToProfile: false, });
        // }
    };

    const clickFileUpdate = event => {

        event.preventDefault();
        setValues({ ...values, error: '', loading: true });

        updateContentFile(match.params.contentId, userId, token, formData).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error });
            } else {
                setValues({
                    loading: false,
                    error: false,
                    // redirectToProfile: true,
                    createdContent: data.name
                });
            }
        });
        //    } else {
        //         setValues({ ...values, error: 'Please Choose a file for Updating', loading: false });
        //     } 
    };

    const newFilesForm = () => (
        <div className="row">
            <form className="col-lg-6" onSubmit={clickPhotoUpdate}>
                {/* <div className="card">
                    <button className="btn btn-outline-primary card-header">Update the Photo</button>
                    <div className="card-body">
                        <h5>Current Photo</h5>
                        <img src={values.photo} alt="photo" style={{ height: "100px" }}></img>
                        <div className="form-group">
                            <label className="btn btn-secondary">
                                <input onChange={handleFileChanges('photoFile')} type="file" name="photoFile" accept="image/*" />
                                <small id="photoHelp" className="form-text text-warning">Choose any product image</small>
                            </label>
                        </div>

                    </div>
                </div> */}

                <div className="form-group">
                    <label className="text-muted"><h5>Current Photo</h5></label> <br />
                    <img src={values.photo} alt="photo" style={{ height: "100px" }}></img>
                    <input
                        onChange={handleFileChanges('photoFile')}
                        type="file"
                        accept="image/*"
                        className="form-control"
                    />
                </div>
                {values.photoFile === '' && <button className="btn btn-outline-primary disabled" type="submit">Update the Photo</button>}
                {values.photoFile !== '' && <button className="btn btn-outline-primary" type="submit">Update the Photo</button>}

            </form>
            <br />
            <form className="col-lg-6" onSubmit={clickFileUpdate}>
                {/* <div className="card">
                    <button className="btn btn-outline-primary card-header">Update the CAD File</button>
                    <div className="card-body">
                        <h5>Current CAD File</h5>
                        <br />
                        <small className="form-text text-muted">current file is: {values.modelLink} </small>
                        <br />
                        <div className="form-group">
                            <label className="btn btn-secondary">
                                <input onChange={handleFileChanges('modelFile')} type="file" name="modelFile" accept=".ipt, .stp, .iges|file/*" />
                                <small id="photoHelp" className="form-text text-warning">Choose any CAD File</small>
                            </label>
                        </div>

                    </div>
                </div> */}
                <div className="form-group">
                    <label className="text-muted"><h5>Current CAD File</h5></label>
                    <br />

                    <small className="form-text text-muted">current file is: {values.modelLink} </small>
                    <br />
                    <br />
                    <br />
                    <input
                        onChange={handleFileChanges('modelFile')}
                        type="file"
                        accept="image/*"
                        className="form-control"
                    />
                </div>
                {values.modelFile === '' && <button className="btn btn-outline-primary disabled" type="submit">Update the CAD File</button>}
                {values.modelFile !== '' && <button className="btn btn-outline-primary" type="submit">Update the CAD File</button>}

            </form>
        </div>
    );

    const newPostForm = () => (
        <form className="mb-3" onSubmit={clickSubmit} style={{ display: createdContent ? 'none' : '' }}>
            <div className="card">
                {/* <button className="btn btn-outline-primary card-header">Update Content</button> */}

                <div className="card-body">
                    <h4 className="card-title">Required Inputs</h4>
                    <hr />

                    <div className="row">
                        <div className="col">
                            {/* <h5>Content Photo</h5>
                            <div className="form-group">
                                <label className="btn btn-secondary">
                                    <input onChange={handleChange('photo')} type="file" name="photo" accept="image/*" />
                                    <small id="photoHelp" className="form-text text-warning">Choose any product image</small>
                                </label>
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="name" className="text-muted">Content Name</label>
                                <input id="name" type="text" value={name} onChange={handleChange('name')} className="form-control"
                                    aria-describedby="nameHelp" ></input>
                                <small id="nameHelp" className="form-text text-muted">This will be the file name once you Update.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="description" className="text-muted">Description</label>
                                <textarea id="description" onChange={handleChange('description')} className="form-control" style={{ height: "60px" }}
                                    aria-describedby="descriptionHelp" value={description} />
                                <small id="descriptionHelp" className="form-text text-muted">The entire description is searchable and can be up to 2000 characters.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="tags" className="text-muted">Tags</label>
                                <Select
                                    className=""
                                    isMulti
                                    placeholder="example: Major Equipment"
                                    name="tags"
                                    options={fixedTags.map((c, i) => (
                                        {
                                            value: c.name,
                                            label: c.name
                                        }
                                    ))}
                                    onChange={handleChange("tags")}
                                />
                                <small id="tagsHelp" className="form-text text-muted">Current Tag Choices are. <b>{values.incomingTags}</b></small>
                            </div>

                        </div>
                        <div className="col">
                            {/* <h4>Content File</h4>
                            <div className="form-group">
                                <label className="btn btn-secondary">
                                    <input onChange={handleChange('modelFile')} type="file" name="modelFile" accept=".ipt, .stp, .iges|file/*" />
                                    <small id="modelHelp" className="form-text text-warning">Choose any 3D model or 2D dwg</small>
                                </label>
                            </div> */}

                            <div className="form-group">
                                <label htmlFor="content_type" className="text-muted">Content Type</label>
                                <select onChange={handleChange('content_type')} value={content_type} className="form-control">
                                    <option value="Model" selected>3D Object</option>
                                    <option value="Symbol">2D Symbol</option>
                                </select>
                                <small id="content_typeHelp" className="form-text text-muted">Choose which type of file this is.</small>
                            </div>
                            <div className="form-group">
                                <label className="text-muted"> Verified by Manufacturer</label>
                                <select id="verifiedByManufacturer" onChange={handleChange('verifiedByManufacturer')} value={verifiedByManufacturer}
                                    className="form-control">
                                    <option value="false">No</option>
                                    <option value="true">Yes</option>
                                </select>
                                <small className="form-text text-muted">
                                    Only Manufacturers can designate content as "Verified".</small>
                            </div>
                            <div className="form-group">
                                <br />
                                <label className="text-muted">Current Brand is: <b>{values.brand}</b></label>
                                <br />
                                <label className="text-muted">Current Category is: <b>{values.categoryName}</b></label>
                                <br />
                                <label className="text-muted">Category and Brand can't be modified at this time as they are direclty related to the file naming conventions. If you need to modify these, please delete the content and reload it using the Utility Content tools.</label>
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="Brand" className="text-muted">Brand</label>
                                <select onChange={handleChange('brand')} value={brand} className="form-control">                                    
                                <option value="GENERIC" selected>GENERIC</option>
                                    <option value="SEFCOR">SEFCOR</option>
                                    <option value="SBS">SBS</option>
                                </select>
                                <small id="brandHelp" className="form-text text-muted">Select one Vendor Brand for this Content. if there is none just leave Generic.</small>
                            </div> */}

                            {/* <div className="form-group">
                                <label htmlFor="category" className="text-muted">Category</label>
                                <input id="category" type="text" value={category} onChange={handleChange('category')} className="form-control"
                                 aria-describedby="categoryHelp" style={{enabled: false}} ></input>                                
                                <small id="categoryHelp" className="form-text text-muted">You cannot </small>
                            </div> */}


                        </div>
                    </div>
                </div>
                {/* <button className="btn btn-outline-primary card-footer">Update Content</button> */}
            </div>

            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">Optional Inputs</h4>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="unique_product_ref" className="text-muted">Unique Product Reference</label>
                                <input onChange={handleChange('unique_product_ref')} type="text" className="form-control" value={unique_product_ref}
                                    aria-describedby="unique_product_refHelp" />
                                <small id="unique_product_refHelp" className="form-text text-muted">Add a unique product reference for your own reporting.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="product_family" className="text-muted">Product Family</label>
                                <input onChange={handleChange('product_family')} type="text" className="form-control" value={product_family}
                                    aria-describedby="product_familyHelp" />
                                <small id="product_familyHelp" className="form-text text-muted">Add an additional product family to group your content.</small>
                            </div>
                            <div className="form-group">
                                <label htmlFor="product_group" className="text-muted">Product Group</label>
                                <input onChange={handleChange('product_group')} type="text" className="form-control" value={product_group}
                                    aria-describedby="product_groupHelp" />
                                <small id="product_groupHelp" className="form-text text-muted">Type a Category to view all.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="cad_comments" className="text-muted">Comments</label>
                                <textarea id="cad_comments" onChange={handleChange('cad_comments')} className="form-control" style={{ height: "60px" }}
                                    aria-describedby="cad_commentsHelp" value={cad_comments} />
                                <small id="cad_commentsHelp" className="form-text text-muted">Additional comments about this content.</small>
                            </div>


                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="design_country" className="text-muted">Design Country</label>
                                <input onChange={handleChange('design_country')} type="text" className="form-control" value={design_country}
                                    aria-describedby="design_countryHelp" />
                                <small id="design_countryHelp" className="form-text text-muted">Select a country this content is designed in.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="manufacture_country" className="text-muted">Manufacture Country</label>
                                <input onChange={handleChange('manufacture_country')} type="text" className="form-control" value={manufacture_country}
                                    aria-describedby="manufacture_country" />
                                <small id="manufacture_country" className="form-text text-muted">Select a country this content is built in.</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="status" className="text-muted">Status</label>
                                <select onChange={handleChange('status')} className="form-control">
                                    <option>Published</option>
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                </select>
                                <small id="statusHelp" className="form-text text-muted">Published is ready immediately. Draft can be modified further before publishing.</small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br />


            <div className="col-lg-8">{' '}</div>
            <div className="col-lg-4">
                <button className="btn btn-outline-primary" type="submit">Update the Content</button>
            </div>



        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdContent ? '' : 'none' }}>
            <h2>{`${createdContent}`} is updated!</h2>
        </div>
    );

    const showLoading = () =>
        loading && (
            <div className="alert alert-success">
                <h2>Loading...</h2>
                <Loader
                    type="Watch"
                    color="#5778db"
                    height={100}
                    width={100}
                />
            </div>
        );

    const redirectUser = () => {
        if (redirectToProfile) {
            if (!error) {
                return <Redirect to={`/content/${match.params.contentId}`} />;
            }
        }
    };

    return (
        <Layout
            title="Update content"
            description={`Hello ${name}, ready to update ${name}?`}
            cadType={runningInCAD}
        >
            <Container >
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        {showLoading()}
                        {showSuccess()}
                        {showError()}
                        {newFilesForm()}
                        <br />
                        {newPostForm()}
                        {redirectUser()}
                    </div>
                </div>
            </Container>
        </Layout>
    );
};

export default UpdateContent;
