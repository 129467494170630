import React, { Component } from "react";
import { isAuthenticated } from '../auth';
import LoadingOverlay from 'react-loading-overlay';
import { getCategories, getVendors } from '../api/apiAdmin';
import { createContentFromInventor, getTags } from '../api/apiCAD';
import Select from 'react-select'
import Layout from '../core/Layout';

class UCinventorParts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            vendor: '', // vendorId
            name: '',
            description: '',
            unique_product_ref: '',
            brand: '',
            product_family: '',
            product_group: '',
            cad_comments: '',
            design_country: '',
            manufacture_country: '',
            category: '',  // categoryId
            categoryName: '', // name used for file naming
            verifiedByManufacturer: '',
            modelLink: '',
            runConversions: false, // generates additional files
            shrinkWrapPart: false,
            bim_data: '',
            Links: '',
            tags: '',
            status: 'Published',
            newFileName: '',
            fileName: '',
            fileType: '', // used to run rules for different types
            fullFileName: '',
            uploadReady: false,
            iPropsChecked: true,
            error: '',
            formData: '',
            categories: [],
            fixedBrands: [],
            loading: false,
            uploadComplete: false,
            categoryKey: '',
            fixedTags: [],
            tagvalues: [],
            runningInCAD: false
        }
        this.readiPropVal = this.readiPropVal.bind(this);
        this.showUploadButton = this.showUploadButton.bind(this);
        this.showiPropsButton = this.showiPropsButton.bind(this);
        this.showError = this.showError.bind(this);
        this.getAllcategories = this.getAllcategories.bind(this)
        this.runProcess = this.runProcess.bind(this)
        this.buildName = this.buildName.bind(this);

        this.uploadNativeIPT = this.uploadNativeIPT.bind(this);
        this.uploadSimpleIPT = this.uploadSimpleIPT.bind(this);

    }

    token = isAuthenticated() && isAuthenticated().token;
    userId = isAuthenticated().userId

    readiPropVal = event => {
        event.preventDefault();
        this.setState({ ...this.state, error: '', loading: true });
        if (window.uchInventorInterface) {

            window.uchInventorInterface.getCurrentFileType().then(result => {
                if (result) {
                    this.setState({ fileType: result })
                }
            })

            window.uchInventorInterface.getCurrentFileName().then(result => {
                if (result) {
                    this.setState({ fileName: result })
                }
            })

            window.uchInventorInterface.getCurrentFullFileName().then(result => {
                if (result) {
                    this.setState({ fullFileName: result })
                }
            })

            window.uchInventorInterface.getCurrentFileType().then(result => {
                if (result) {
                    // Handle the true case
                    if (result === 'kPartDocumentObject') {
                        // alert('here')
                        // const tmpcontentName = window.uchInventorInterface.readiProps("Design Tracking Properties", "Part Number").split(".")
                        window.uchInventorInterface.readiProps("Design Tracking Properties", "Part Number").then(result => {
                            this.setState({ name: result.split()[0] })
                        })

                        window.uchInventorInterface.readiProps("Design Tracking Properties", "Description").then(result => {
                            this.setState({ description: result })
                        })

                        window.uchInventorInterface.readiProps("Inventor Document Summary Information", "Category").then(result => {
                            this.setState({ category: result })
                        })

                        window.uchInventorInterface.readiProps("Inventor Summary Information", "Comments").then(result => {
                            this.setState({ cad_comments: result })
                        })

                        window.uchInventorInterface.readiProps("Design Tracking Properties", "Part Number").then(result => {
                            this.setState({
                                fileFolder: this.state.fullFileName,
                                fileName: this.state.fileName,
                                fileType: this.state.isFileType,
                                name: this.state.name,
                                description: this.state.description,
                                categoryKey: this.state.category ? this.state.category : +new Date(),
                                cad_comments: this.state.cad_comments,
                                loading: false,
                                uploadReady: true,
                                iPropsChecked: false
                            })
                        })


                    } else {
                        this.setState({
                            fileFolder: '',
                            fileName: '',
                            fileType: '',
                            name: '',
                            description: '',
                            brand: '',
                            uploadReady: false,
                            stepfile: '',
                            dwgfile: '',
                            dwg2dfile: '',
                            error: 'Load any Inventor PART Type file first',
                            loading: false,
                            iPropsChecked: true
                        })
                    }
                } else {
                    // Handle the false case
                    // alert(result)                                        
                    this.setState({
                        fileFolder: '',
                        fileName: '',
                        fileType: '',
                        name: '',
                        description: '',
                        brand: '',
                        uploadReady: false,
                        stepfile: '',
                        dwgfile: '',
                        dwg2dfile: '',
                        error: 'Load any Inventor PART Type file first',
                        loading: false,
                        iPropsChecked: true
                    })
                }
            })


            // if (this.isFileType === 'kPartDocumentObject') {
            //     alert('here')
            //     const tmpcontentName = window.uchInventorInterface.readiProps("Design Tracking Properties", "Part Number").split(".")

            //     this.setState({
            //         fileFolder: window.uchInventorInterface.getCurrentFullFileName(),
            //         fileName: window.uchInventorInterface.getCurrentFileName(),
            //         fileType: isFileType,
            //         name: tmpcontentName[0],
            //         description: window.uchInventorInterface.readiProps("Design Tracking Properties", "Description"),
            //         categoryKey: this.state.category ? window.uchInventorInterface.readiProps("Inventor Document Summary Information", "Category") : +new Date(),
            //         cad_comments: window.uchInventorInterface.readiProps("Inventor Summary Information", "Comments"),
            //         loading: false,
            //         uploadReady: true,
            //         iPropsChecked: false
            //     })
            // } else {
            //     this.setState({
            //         fileFolder: '',
            //         fileName: '',
            //         fileType: '',
            //         name: '',
            //         description: '',
            //         brand: '',
            //         uploadReady: false,
            //         stepfile: '',
            //         dwgfile: '',
            //         dwg2dfile: '',
            //         error: 'Load any Inventor PART Type file first',
            //         loading: false,
            //         iPropsChecked: true
            //     })
            // }
        }
    }


    inventorForm = () => (
        <form>
            <div className="form-group">
                <label className="text-muted">Content Name</label>
                <input
                    onChange={this.handleChange("name")}
                    type="text"
                    className="form-control"
                    value={this.state.name}
                />
            </div>
            <div className="form-group">
                <label className="text-muted">Content Description (searchable)</label>
                <input
                    onChange={this.handleChange("description")}
                    type="text"
                    className="form-control"
                    value={this.state.description}
                />
            </div>

            <div className="form-group">
                <label htmlFor="keepNative" className="text-muted">Simplify the Part File</label>
                <select onChange={this.handleChange('shrinkWrapPart')} value={this.state.shrinkWrapPart} className="form-control">
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="runConversions" className="text-muted">Generate Other Files (step, 3d dwg and 2D dwg)</label>
                <select onChange={this.handleChange('runConversions')} value={this.state.runConversions} className="form-control">
                    <option value='false'>No</option>
                    <option value='true'>Yes</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="Brand" className="text-muted">Brand </label>
                <Select
                    className=""
                    name="brand"
                    options={this.state.fixedBrands.map((c, i) => (
                        {
                            value: c._id,
                            label: c.name
                        }
                    ))}
                    onChange={this.handleChange("brand")}
                // value={this.state.brand}
                />
            </div>
            <div className="form-group">
                <label htmlFor="category" className="text-muted">Category</label>
                <Select
                    className=""
                    key={this.state.categoryKey}
                    name="category"
                    options={this.state.categories &&
                        this.state.categories.map((c, i) => (
                            {
                                value: c._id,
                                label: c.name
                            }
                        ))}
                    onChange={this.handleChange("category")}
                />

            </div>

            <div className="form-group">
                <label className="text-muted">Comments:</label>
                <input
                    onChange={this.handleChange("cad_comments")}
                    type="text"
                    className="form-control"
                    value={this.state.cad_comments}
                />
            </div>

            <div className="form-group">
                <label htmlFor="tags" className="text-muted">Tags</label>
                <Select
                    className=""
                    isMulti
                    placeholder="example: Major Equipment"
                    name="tags"
                    options={this.state.fixedTags.map((c, i) => (
                        {
                            value: c.name,
                            label: c.name
                        }
                    ))}
                    onChange={this.handleChange("tags")}
                />
                <small id="tagsHelp" className="form-text text-muted">Choose tags to help users filter on searches.</small>
            </div>


        </form>
    )

    handleChange = name => event => {
        let value = "";
        if (name === 'category') {
            value = event.value
            this.state.formData.append('categoryName', event.label)
            this.state.formData.append('category', value);
            this.setState({ category: value })
            this.setState({ categoryName: event.label })
            this.setState({ uploadReady: true })
        } else if (name === 'tags') {
            const finalVal = (event && event.map((c, i) => (
                [
                    c.value
                ]
            )))
            value = finalVal
        } else if (name === 'brand') {
            value = event.label
            this.state.formData.append('vendor', event.value)
            this.setState({ vendor: event.value });
        } else if (name === 'runConversions') {
            value = event.target.value
            this.state.formData.append('runConversions', value)
        } else if (name === 'shrinkWrapPart') {
            value = event.target.value
        } else {
            value = event.target.value
        }

        //formData.set(name, value);  // changed this for IE11 only.
        this.state.formData.append(name, value);
        this.setState({ [name]: value });
        this.setState({ error: '' })
    };

    componentDidMount() {
        this.getAllcategories()
        getVendors().then(data => {
            if (data.error) {
                this.setState({ ...this.state, error: data.error });
            } else {
                this.setState({
                    ...this.state,
                    fixedBrands: data,
                });
            }
        });
        getTags().then(data => {
            if (data.error) {
                this.setState({ ...this.state.tagvalues, error: data.error });
            } else {
                this.setState({
                    ...this.state.tagvalues,
                    fixedTags: data
                });
            }
        });
        if (window.uchInventorInterface) {
            this.setState({ runningInCAD: true })
        }
    };

    getAllcategories() {
        getCategories().then(data => {
            if (data.error) {
                this.setState({ ...this.state, error: data.error });
            } else {
                this.setState({
                    ...this.state,
                    categories: data,
                    formData: new FormData()
                });
            }
        });
    }

    async buildName() {
        const catName = this.state.categoryName.replace(':', '_');
        this.state.fileFolder = this.state.fileFolder.replace(this.state.fileName, "");
        const cleanName = String(this.state.name).replace(/\s/g, "_")
        this.state.newFileName = this.state.brand + '_' + catName + '_' + cleanName + '.ipt'
    }

    async uploadNativeIPT() {
        const catName = this.state.categoryName.replace(':', '_');
        const cleanName = String(this.state.name).replace(/\s/g, "_")

        // uploadNativeIPTfiletoS3(fileName, awsSiteName, awsFolder, aswImageFolder)
        // uploadNativeIPTfiletoS3("sds_cable-fitting_somename.ipt", "utilitycontentsite", "content/SBS", "images/SBS")
        const response = await window.uchInventorInterface.uploadNativeIPTfiletoS3(this.state.brand + '_' + catName + '_' + cleanName + '.ipt',
            "utilitycontentsite", "content/" + this.state.brand, "images/" + this.state.brand)
        const fileUploaded = await response;
        this.state.formData.append('newFileName', this.state.brand + '_' + catName + '_' + cleanName + '.ipt');
    };

    async uploadSimpleIPT() {
        const catName = this.state.categoryName.replace(':', '_');
        const cleanName = String(this.state.name).replace(/\s/g, "_")

        // uploadNativeIPTfiletoS3(fileName, awsSiteName, awsFolder, aswImageFolder)
        // uploadNativeIPTfiletoS3("sds_cable-fitting_somename.ipt", "utilitycontentsite", "content/SBS", "images/SBS")
        const response = await window.uchInventorInterface.uploadSimpleIPTfiletoS3(this.state.brand + '_' + catName + '_' + cleanName + '.ipt',
            "utilitycontentsite", "content/" + this.state.brand, "images/" + this.state.brand)
        const fileUploaded = await response;
        
        this.state.formData.append('newFileName', this.state.brand + '_' + catName + '_' + cleanName + '.ipt');
    };

    async uploadAllextraFilestoS3() {
        const catName = this.state.categoryName.replace(':', '_');
        const cleanName = String(this.state.name).replace(/\s/g, "_")

        // uploadNativeIPTfiletoS3(fileName, awsSiteName, awsFolder, aswImageFolder)
        // uploadNativeIPTfiletoS3("sds_cable-fitting_somename.ipt", "utilitycontentsite", "content/SBS", "images/SBS")
        const response = await window.uchInventorInterface.uploadAllcadfilestoS3(this.state.brand + '_' + catName + '_' + cleanName + '.ipt',
            "utilitycontentsite", "content/" + this.state.brand)
        const fileUploaded = await response;
        this.state.formData.append('newFileName', this.state.brand + '_' + catName + '_' + cleanName + '.ipt');
    };



    async runProcess() {
        this.setState({ ...this.state, loading: true });
        if (this.state.name && this.state.description && this.state.brand && this.state.category) {
            if (window.uchInventorInterface) {
                await this.buildName()
                if (this.state.shrinkWrapPart) {
                    await this.uploadSimpleIPT()
                } else {
                    await this.uploadNativeIPT()
                }
                if (this.state.runConversions) {
                    await this.uploadAllextraFilestoS3()
                }
            }
            this.state.formData.append('user', this.userId);
            this.state.formData.append('name', this.state.name);
            this.state.formData.append('description', this.state.description);
            this.state.formData.append('runConversions', this.state.runConversions);
            this.state.formData.append('cad_comments', this.state.cad_comments);
            this.state.formData.append('fileName', this.state.fileName);

            await createContentFromInventor(this.userId, this.token, this.state.formData).then(data => {
                if (data.error) {
                    this.setState({ ...this.state, error: data.error, loading: false });
                } else {
                    this.setState({
                        name: '',
                        description: '',
                        comments: '',
                        error: '',
                        tags: '',
                        loading: false,
                        uploadComplete: true
                    })
                }
            })

        } else {
            this.setState({ ...this.state, error: 'All fields are required.' });
        }

    }

    showUploadButton() {
        return (
            <button onClick={this.runProcess} className="btn btn-raised btn-success"
                style={{ display: this.state.uploadReady ? true : "none" }}>
                Ready for upload
            </button>
        )
    }

    showiPropsButton() {
        return (
            <button onClick={this.readiPropVal} className="btn btn-raised btn-primary"
                style={{ display: this.state.iPropsChecked ? true : "none" }}>
                Import File iProps
            </button>
        )
    };

    showSuccess() {
        if (this.state.uploadComplete) {
            return (
                <div className="alert alert-success" style={{ display: this.state.uploadComplete ? '' : 'none' }}>
                    Your file has been created successfully!
                </div>
            )
        }
    };


    checkIfCADloaded() {
        if (!window.uchInventorInterface) {
            return (
                <div className="alert alert-danger" style={{ display: true }}>
                    <p>WARNING: This panel MUST be run inside a CAD system!</p>
                    <br />
                    <p>The above tools are meant to run within CAD tools and will NOT function as a web page alone.
                        Please install the Utility Content addin and login through those pages instead.
                    </p>
                </div>
            )
        }
    };

    showError() {
        return (
            <div className="alert alert-danger" style={{ display: this.state.error ? "" : "none" }}>
                {this.state.error}
            </div>
        )
    };


    render() {
        return (
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text='Loading your content...'
            >
                <Layout
                    title="Upload Content"
                    description="Use this page to upload new content."
                    className="container-fluid"
                    isMobile={true}
                >
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page" >Upload an Inventor Part File</li>
                            </ol>
                        </nav>
                        <div className="container-fluid">
                            {this.checkIfCADloaded()}
                        </div>
                        <div className="container-fluid">
                            {this.showError()}
                            {this.showSuccess()}
                            {this.showUploadButton()}
                            {this.showiPropsButton()}
                        </div>
                        <div className="container-fluid">
                            {this.inventorForm()}
                        </div>

                    </div>
                </Layout>
            </LoadingOverlay>
        )
    }

}
export default UCinventorParts;