import React, { useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { useMediaQuery } from 'react-responsive'
import {
    Container,
    Box,
    Button,
    Card,
    Grid,
    CardContent
} from '@material-ui/core';

import RefreshIcon from '@material-ui/icons/Refresh';


//https://v4.mui.com/components/about-the-lab/
//https://v4.mui.com/components/material-icons/


const FormaAnalysis = () => {
    const [state, setState] = React.useState({
        users: [],
        user: '',
        updateMessage: '',
        formaItems: '',
        formaId: ''
    })
   
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    
    useEffect(() => {
        
    }, []);



    const handleFormaClick = async () => {
        const selectedPaths = await window.Forma.selection.getSelection()


        // Fetch mesh representation for the entire proposal and count the number of triangles.
        const mesh = await window.Forma.geometry.getTriangles()
        const numberOfTriangles = mesh.length / 3

        // const siteLimitPaths = await window.Forma.geometry.getPathsByCategory({ category: "site_limit" })
        // const siteLimitFootprint = await window.Forma.geometry.getFootprint({ path: siteLimitPaths[0] })

        
        const currentlySelected = await window.Forma.selection.getSelection()
        const areaMetrics = await window.Forma.areaMetrics.calculate({
            paths: currentlySelected
        })
        console.log(areaMetrics)
        setState({
            ...state,
            formaItem: numberOfTriangles,
            formaId: selectedPaths
        })

    };


    return (
        <Layout
            title={`${isAuthenticated().name}'s Forma Content`}
            description={`for Admin beta testing only`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Analyze Forma</li>
                    </ol>
                </nav>
                <br />
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <p>Selected Form Id: {state.formaId}</p>
                            <p>total triangles: {state.formaItem}</p>
                        </CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >
                           
                            <Grid container spacing={1}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<RefreshIcon />}
                                    onClick={() => (handleFormaClick())}
                                >
                                    Get Selection
                                </Button>


                            </Grid>
                        </Box>
                    </Card>

                </Container>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default FormaAnalysis;