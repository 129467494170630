import React, { useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { useMediaQuery } from 'react-responsive'
import {
    Container,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PageviewIcon from '@material-ui/icons/Pageview';
import FormAddfileDialog from '../dialogs/addFileDialog';
import FormDeleteFileDialog from '../dialogs/deleteFileDialog';
import FormFileDetails from '../dialogs/fileDetailsDialog';
import { getAllPointCloudsByUser, getAwsPointCloudLink } from '../api/apiCore';
import { useHistory } from "react-router-dom";

//https://v4.mui.com/components/about-the-lab/
//https://v4.mui.com/components/material-icons/


const PointClouds = () => {
    const [state, setState] = React.useState({
        users: [],
        user: '',
        fullUserList: [],
        userList: [],
        updateMessage: '',
        searchText: '',
        fileList: []
    })
    const history = useHistory();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // These parameters will be changed in the future once storage decisions are made
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    const refreshPage = () => {
        setTimeout(() => {
            console.log(state.user.token)
            getAllPointCloudsByUser(state.user.token, state.user.userId).then(data => {
                // checkAllfileStatus(data)
                setState({
                    ...state,
                    fileList: data
                });
            })
        }, 1000);

    };

    useEffect(() => {
        getAllPointCloudsByUser(isAuthenticated().token, isAuthenticated().userId).then(data => {
            setState({
                ...state,
                user: isAuthenticated(),
                fileList: data
            });
        });
    }, []);


    const setFileList = (userId) => {
        getAllPointCloudsByUser(isAuthenticated().token, userId).then(data => {
            setState({
                ...state,
                fileList: data
            })
        })
    }

    const filesAdded = () => {
        setFileList(isAuthenticated().userId)
    }

    const handleDownload = (token, fileId, userId, fileName) => {
      
        getAwsPointCloudLink(token, fileId, userId, fileName).then(data => {
            if (data.error) {
                setState({
                    ...state,
                    error: data.error
                })

            } else {
                window.open(data);
                // history.push(data);
            }
        });
    };

    const filesRemoved = (fileListRemoved) => {
        // add function to remove files
        setFileList(fileListRemoved)
    }

    const handleFileView = () => {
        history.push(`/admin/pointcloudviewer`);
    }

    return (
        <Layout
            title={`${isAuthenticated().name}'s Point Clouds`}
            description={`for Admin beta testing only`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to Dashboard</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Admin Point Clouds</li>
                    </ol>
                </nav>
                <br />
                <Container maxWidth="lg">
                    <Card>
                        <CardContent>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead >
                                        <TableRow>
                                            <TableCell className="default-file">
                                                {'File Name'}
                                            </TableCell >
                                            <TableCell className="file-list">
                                                {'Status'}
                                            </TableCell  >
                                            <TableCell className="file-commands">
                                                {'Commands'}
                                            </TableCell  >
                                            <TableCell className="file-status">
                                                {'Status'}
                                            </TableCell >
                                            <TableCell className="file-category">
                                                {'Category'}
                                            </TableCell >
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.fileList && state.fileList.map((file) => {
                                            return (
                                                <>
                                                    <TableRow
                                                        hover
                                                        role="checkbox" tabIndex={-1} key={file._id}>
                                                        <TableCell size="small">
                                                            <p>{file.fileName} </p>
                                                        </TableCell>
                                                        <TableCell size="small">
                                                            {/* {getFileStatus(file.storageLink, file._id)} */}
                                                            {file.fileStatus}
                                                        </TableCell>

                                                        <TableCell size="medium">

                                                            {/* {(file.fileStatus == 'Translation completed') && 
                                                            <FormForgePreview
                                                                disabled={true}
                                                                fileUrn={getFileUrn(file.storageLink)}
                                                                fileExt={file.fileExtension}
                                                                fileStatus={file.fileStatus}
                                                            />} */}

                                                            <FormFileDetails
                                                                fileId={file._id}
                                                            />

                                                            <Tooltip title="download file" placement="top" >
                                                                <IconButton
                                                                    name={"download"}
                                                                    onClick={() => handleDownload(isAuthenticated().token, file._id, isAuthenticated().userId, file.fileName)}
                                                                    size="small"
                                                                    sx={{ ml: 2 }}
                                                                    aria-controls={state.open ? 'account-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={state.open ? 'true' : undefined}
                                                                >
                                                                    <CloudDownloadIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            
                                                            <FormDeleteFileDialog
                                                                fileId={file._id}
                                                                fileName={file.fileName}
                                                                filesRemoved={filesRemoved}
                                                                userId={isAuthenticated().userId}
                                                                fileList={state.fileList}
                                                            />

                                                            <Tooltip title="View file" placement="top" >
                                                                <IconButton
                                                                    name={"view"}
                                                                    onClick={() => handleFileView(file.Name)}
                                                                    size="small"
                                                                    sx={{ ml: 2 }}
                                                                    aria-controls={state.open ? 'account-menu' : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={state.open ? 'true' : undefined}
                                                                >
                                                                    <PageviewIcon />
                                                                </IconButton>
                                                            </Tooltip>

                                                        </TableCell>

                                                        <TableCell size="medium">
                                                            <p>Processed</p>
                                                        </TableCell>
                                                        <TableCell size="small">
                                                            {file.category}
                                                        </TableCell>


                                                    </TableRow>
                                                </>
                                            );
                                        })
                                        }
                                        {!state.fileList.length &&
                                            <TableRow
                                                hover
                                                role="checkbox">
                                                <Alert severity="error" >
                                                    <p>No Point Cloud Files in your library</p>

                                                </Alert>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </CardContent>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={2}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        endIcon={<RefreshIcon />}
                                        onClick={() => (refreshPage())}
                                    >
                                        Refresh
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <FormAddfileDialog
                                        filesAdded={filesAdded}
                                        userId={isAuthenticated().userId}
                                        S3_BUCKET={S3_BUCKET}
                                        REGION={REGION}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>

                </Container>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default PointClouds;