/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useContext } from "react";
import { Container, Row, Col, Button, Form, Toast, Card, Accordion, AccordionContext, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Layout from '../core/Layout';
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaFileDownload } from 'react-icons/fa';
import Footer from '../core/Footer';
import {
    runAssyConfig, runPartConfig, checkConfigStatus, checkifModelExists, readUCHConfigurator,
    getSignedUrl, getResultsParam, addDownloadedBy
} from "../api/apiConfigs";
import Checkmark from '../images/Check-mark-3-xxl.png';
import Xmark from '../images/x-mark-xxl.png';
import Viewer from '../forgeViewer/Viewer';
import { FaPencilAlt } from 'react-icons/fa'
import { isAuthenticated } from '../auth';
import ReactGA from 'react-ga';
import Image from 'react-image-resizer';
import { confirmAlert } from 'react-confirm-alert';
import { addViewable } from '../forgeViewer/Viewer-helpers';

import { addItem, itemTotal } from '../core/cartHelpers';
import Loader from 'react-loader-spinner';

class configTesting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modelBuildStatus: new Date().toLocaleString(),
            modelUrn: '',
            modelUrnKey: Math.random(),
            step: 10,
            configuratorData: [],
            parameters: [],
            inputs: [],
            configuredParameters: [],
            configuredControls: [],
            inputsUpdated: false,
            loading: false,
            loadingMessage: 'Building Model',
            error: '',
            success: false,
            resultParams: '',
            debugging: true,    // this turns on some inputs on the screen to help with debugging. Must be off before going production
            partBuilt: false,
            forge_model_download_url: '',
            forge_model_download_name: '',
            runningInCAD: false,
            workItem: '',
            viewerExtensions: ['NestedViewerExtension', 'Autodesk.Measure'],
            photo: '',
            hasDwg: 0,
            numberOfConfigsRun: 1,
            previewImage: ''
        }
        this.resetValue = this.resetValue.bind(this);
        this.runConfiguration = this.runConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputType = this.handleInputType.bind(this);
        this.configureInputs = this.configureInputs.bind(this);
        this.showInputs = this.showInputs.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.populateReadOnlyValues = this.populateReadOnlyValues.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.loadNewModel = this.loadNewModel.bind(this);
    }

    userId = isAuthenticated() && isAuthenticated().userId;
    token = isAuthenticated() && isAuthenticated().token;

    componentWillMount = async () => {
        await readUCHConfigurator(this.props.match.params.configId).then(data => {
            if (data.error) {
                this.setState({ errorMessage: data.error });
            } else {
                this.setState({
                    configuratorData: data,
                    inputs: data.forms[0].items,
                    parameters: data.parameters,
                    modelUrn: data.defaultFile,
                    modelUrnKey: Math.random(),
                    outputModelUrn: '',
                    hasDwg: (data.rootFileName.split(".")[1] == 'iam') ? 1 : 0,
                    viewerExtensions: (data.rootFileName.split(".")[1] == 'iam') ? ['NestedViewerExtension'] : [],
                    photo: data.photo
                })

                // collect google analytics data
                ReactGA.initialize('G-F4M83JTFLH');
                ReactGA.pageview(`/configurator/${data._id}`);

                window.localStorage.setItem('currentUrn', data.defaultFile);
                // setup the preview image url
                getSignedUrl({ folderName: data.forgeProjectFolder, objectName: 'preview.png' }).then(data => {
                    this.setState({
                        previewImage: data
                    })
                });
                this.configureInputs(data.forms[0].items)
                // put here just to help debug
                // // grab the json signed url for the return params
                // getSignedUrl({ folderName: data.forgeProjectFolder, objectName: 'result.json' }).then(data => {
                //     getResultsParam(data).then(jsondata => {
                //         // put the result params into a JSON 
                //         this.setState({
                //             resultParams: jsondata
                //         })
                //     })
                // });               
            }
        })
        if (window.uchInventorInterface) {
            this.setState({ runningInCAD: true })
        }
    };

    configureInputs(inputs) {
        var configuredControls = []

        for (var i in inputs) {
            if (inputs[i].type === 'ControlTabGroupSpec') {
                // this configurator has multiple tabs for inputs                
                configuredControls.push({
                    tabName: inputs[i].name,
                    tabDisplayName: inputs[i].displayName,
                    tabtype: inputs[i].type,
                    formInputs: inputs[i].items
                })
            } else {
                configuredControls.push({
                    formInputs: inputs[i]
                })
            }
        }
        this.setState({
            configuredControls: configuredControls
        })
    };

    showResultParams = () => {

        if (this.state.resultParams !== '') {
            return (
                <div className="col-sm-12">
                    <Toast>
                        <Toast.Header>
                            <div className="alert alert-success">
                                {/* <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" /> */}
                                <div><pre>{JSON.stringify(this.state.resultParams, null, 2)}</pre></div>
                            </div>
                        </Toast.Header>
                    </Toast>
                </div>
            )
        };
    };

    resetValue(name) {

        var oldParams = this.state.parameters.find(item => item.name === name)

        for (var i = 0; i < this.state.configuredParameters.length; i++) {
            if (this.state.configuredParameters[i].name === [name]) {
                this.state.configuredParameters[i].expression = oldParams.expression
                break;
            }
        }

        if (document.getElementById(name).type === 'text') {
            document.getElementById(name).value = oldParams.expression
        } else {
            var element = document.getElementById(name);
            element.value = oldParams.expression;
        }

        // look and see if the configured parameter is already in the list        
        var configuredItemFound = this.state.configuredParameters.find(item => item.name === name)
        if (configuredItemFound) {
            if (configuredItemFound.expression !== oldParams.expression) {
                configuredItemFound.expression = oldParams.expression
                // } else {
                //     // add it to the list
                //     var newUpdatedParam = {
                //         name: name,
                //         type: oldParams.type,
                //         unit: oldParams.unit,
                //         expression: oldParams.expression
                //     }
                //     // console.log(parameterFound)
                //     // console.log(newUpdatedParam)
                //     // push the new parmeter info to the configured list
                //     this.state.configuredParameters.push(newUpdatedParam)
            }
        }
        this.setState({ inputsUpdated: true })
    };

    handleChange = name => event => {
        // the change handles both updating previously change params as well as 
        // adding any that have just been changed.
        const { configuredParameters, parameters } = this.state;

        var value = event.target.value
        // look and see if the configured parameter is already in the list        
        var configuredItemFound = configuredParameters.find(item => item.name === name)
        // grab the info on the parameter from the global list
        var parameterFound = parameters.find(item => item.name === name)

        // if it exists in the configured list
        if (configuredItemFound) {
            if (configuredItemFound.expression !== value) {
                configuredItemFound.expression = value
            }
        } else {
            // add it to the list
            var newUpdatedParam = {
                name: name,
                type: parameterFound.type,
                unit: parameterFound.unit,
                expression: value
            }
            configuredParameters.push(newUpdatedParam)
        }
        this.setState({
            inputsUpdated: true,
            success: false,
            partBuilt: false
        })
    };

    addToCart = () => {
        // console.log('added');
        addItem({
            brand: 'configurators',
            _id: this.state.configuratorData._id,
            name: this.state.forge_model_download_url,
            description: this.state.configuratorData.description,
            vendor: '5e42a64c6e0fcb3e50b849f6',
            user: this.userId,
            modelLink: this.state.configuratorData.forgeProjectFolder + '/' + this.state.forge_model_download_url,
            content_type: 'Configurator',
            fileType: 'ipt',
            fileSize: '54338',
            photo: this.state.photo,
            edition_number: 1
        });
        // gather and send info about download
        addDownloadedBy({ configId: this.state.configuratorData._id, userId: this.userId }).then(downloadCount => { })
    };

    buildOutPutDocName(configuredParameters) {

        var filename = ''
        let outputFileName = '' // this is for non-caching version
        // use every single input value to generate hashed file nam
        // this is used to find out if the values exist or not so Forge
        //doesn't generate a new file
        for (var i = 0; i < configuredParameters.length; i++) {
            filename = filename + configuredParameters[i].expression
        }
        var hash = 0, i, chr;

        for (i = 0; i < filename.length; i++) {
            chr = filename.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        hash = hash + ".ipt"

        hash = hash.replaceAll('-', '')
        return hash
    };

    runConfiguration = async () => {
        const { activityId, forgeProjectFolder, inputDoc, rootFileName } = this.state.configuratorData;
        let fileExists = true

        this.setState({ loading: true })
        const { configuredParameters } = this.state;

        var paramList = '{'
        for (var i = 0; i < configuredParameters.length; i++) {
            paramList = paramList + `"${configuredParameters[i].name}": {"value": "${configuredParameters[i].expression}","unit": "${configuredParameters[i].unit}"},`
        }
        paramList = paramList.slice(0, -1) + '}' // removes the last digit "," and adds the closing "}"        

        var outputDoc = this.buildOutPutDocName(configuredParameters);

        // if running with file caching, check if the file exists
        // check if the file exists already so we don't have to regenerate
        if (inputDoc.split(".")[1] === 'ipt') {
            var searchableDoc = outputDoc
        } else {
            var searchableDoc = outputDoc.replace('.ipt', '.dwf')
        }

        await checkifModelExists({ inputFileName: searchableDoc, folderName: forgeProjectFolder }).then(data => {
            if (data.error) {
                // error is file not found so flag the variable to                 
                this.fileExists = false
            } else {
                this.fileExists = true
                this.setState({
                    forge_model_download_url: outputDoc,
                })
                this.checkStatus(data.viewablefile)
            }
            this.setState({ initialRun: true })
        });

        // if file doesn't exist
        if (this.fileExists === false) {
            // body of the configuration to send to web service
            const configBody = {
                "activityId": activityId,
                "forgeProjectFolder": forgeProjectFolder,
                "inputDoc": inputDoc,
                "outputDoc": outputDoc,
                "rootFileName": rootFileName,
                "params": paramList
            }
            if (inputDoc.split(".")[1] === 'zip') {
                // console.log(configBody)
                await runAssyConfig(configBody).then(data => {
                    if (data.error) {
                        this.setState({ error: data.error })
                    } else {
                        this.setState({
                            loading: true,
                            modelOffset: this.state.modelOffset,    // add a value to offset each new model offset from last                    
                            previewImage: this.state.previewImage + '?' + Math.random(),
                            partBuilt: false,
                            workItem: data.workItem,
                            inputsUpdated: false,
                            outputModelUrn: data.urn,
                            forge_model_download_url: outputDoc,
                            hasDwg: 1,
                            viewerExtensions: ['NestedViewerExtension']
                        })
                        // alert(data.downloadFile)
                        // grab the json signed url for the return params
                        getSignedUrl({ folderName: forgeProjectFolder, objectName: 'result.json' }).then(data => {
                            getResultsParam(data).then(jsondata => {
                                // put the result params into a JSON 
                                this.setState({
                                    resultParams: jsondata
                                })
                            })
                        });
                    }
                });
            } else {
                await runPartConfig(configBody, this.token).then(data => {
                    if (data.error) {
                        this.setState({ error: data.error })
                    } else {
                        this.setState({
                            loading: true,
                            modelOffset: this.state.modelOffset,    // add a value to offset each new model offset from last                    
                            previewImage: this.state.previewImage + '?' + Math.random(),
                            partBuilt: false,
                            workItem: data.workItem,
                            inputsUpdated: false,
                            outputModelUrn: data.urn,
                            forge_model_download_url: outputDoc,
                            hasDwg: 0,
                            viewerExtensions: []
                        })

                        // alert(data.downloadFile)
                        // grab the json signed url for the return params
                        getSignedUrl({ folderName: forgeProjectFolder, objectName: 'result.json' }).then(data => {
                            getResultsParam(data).then(jsondata => {
                                // put the result params into a JSON 
                                this.setState({
                                    resultParams: jsondata
                                })
                            })
                        });
                    }
                });
            }
            // check the final status of the translated model for viewing        
            await this.checkStatus(this.state.outputModelUrn)
        }
    };

    checkStatus = async (urn) => {
        // this ensures that the SVF file is indeed ready before updating the Viewer
        const body = { urn: urn }
        // make the call and wait for an answer.
        await checkConfigStatus(body).then(data => {
            if (data.error) {
                this.setState({ error: data.error })
            } else {
                // if the status comes back "success", set the states and load the model
                if (data.status === 'success') {
                    this.setState({
                        loading: false,
                        success: true,
                        //modelUrn: urn,
                        //modelUrnKey: Math.random()
                    })
                    // read the returned JSON parameter results into the readonly values in the interface
                    this.populateReadOnlyValues()
                    this.loadNewModel(urn)        // was testing if speed was increased by inserting model instead of re-loading                    
                } else {
                    this.populateReadOnlyValues()
                    this.setState({ loading: false, success: false, error: 'configuration failed!' })
                }

                return (data)
            }
        });
        window.localStorage.setItem('currentUrn', urn);

    };

    showPreConfigParameters = () => {
        // Generate a parameter List from the controls array
        // that is formatted to send to our Forge Addin on the server.
        var paramList = '{'
        for (var i = 0; i < this.state.configuredParameters.length; i++) {
            paramList = paramList + `"${this.state.configuredParameters[i].name}": {"value": "${this.state.configuredParameters[i].expression}","unit": "${this.state.configuredParameters[i].unit}"},`
        }
        paramList = paramList.slice(0, -1) + '}' // removes the last digit "," and adds the closing "}"        


        return (
            <div className="col-sm-12">
                {/* <div>default file URN: {this.state.configuratorData.defaultFile}</div>
                <div>rootFileName: {this.state.configuratorData.rootFileName}</div>
                <div>inputDoc: {this.state.configuratorData.inputDoc}</div>
                <div>forgeProjectFolder: {this.state.configuratorData.forgeProjectFolder}</div> */}
                <Toast>
                    <Toast.Header>
                        <div className="alert alert-success">
                            {/* <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" /> */}

                            <div><pre>{JSON.stringify(JSON.parse(paramList), null, 2)}</pre></div>
                        </div>
                    </Toast.Header>
                </Toast>
            </div>
        )

    };

    removeItem(index) {
        const list = this.state.list;

        list.splice(index, 1);
        this.setState({ list });
    }

    showReset(inputName) {
        if (this.state.inputsUpdated === true) {
            var parameter = this.state.parameters.find(item => item.name === inputName)
            var configuredParam = this.state.configuredParameters.find(item => item.name === inputName)

            if (configuredParam !== undefined) {
                if (parameter.expression !== configuredParam.expression) {
                    return (
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset Input</Tooltip>}>
                            <Button onClick={() => { this.resetValue(inputName) }} className="mb-2">
                                <FaPencilAlt />
                            </Button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    };

    handleInputType(input) {
        // for each form item, parse the type and details.       
        var parametersToBuild = this.state.parameters

        // build out the list of parameters to build out based on parameters read from iLogic
        let selectedProduct = parametersToBuild.find(inputName => inputName.name === input.parameterName);

        if (input.type === 'MultiValueTextParameterControlSpec') {
            return (
                <>
                    <Form.Label>{input.displayName}</Form.Label>
                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        type="select"
                                        readOnly={input.readonly}
                                        defaultValue={selectedProduct.expression}
                                        name={selectedProduct.name}
                                        value={selectedProduct.value}
                                        onChange={this.handleChange(selectedProduct.name)} >
                                        {selectedProduct.expressions.map((expression, x) => (
                                            <option value={expression}>{
                                                expression
                                            }</option>
                                        ))
                                        }
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>
                </>
            )
        } else if (input.type === 'NumericParameterControlSpec') {
            // the following if statement helps handle inputs that were "derived" and 
            // cannot be entered.
            if (input.parameterName) {

                var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

                return (
                    <>
                        <Form.Label>{input.displayName}</Form.Label>
                        <Form.Row className="align-items-center">
                            <Col>
                                <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            // size="sm"                                            
                                            controlid={input.parameterName}
                                            type="text"
                                            readOnly={input.readonly}
                                            name={selectedProduct.name}
                                            value={selectedProduct.value}
                                            defaultValue={realValue}
                                            onChange={this.handleChange(selectedProduct.name)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                {this.showReset(selectedProduct.name)}
                            </Col>
                        </Form.Row>
                    </>
                )
            }
        } else if (input.type === 'MultiValueNumericParameterControlSpec') {

            var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

            return (
                <>
                    <Form.Label>{input.displayName}</Form.Label>
                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        type="select"
                                        readOnly={input.readonly}
                                        defaultValue={realValue}
                                        name={selectedProduct.name}
                                        value={selectedProduct.value}
                                        onChange={this.handleChange(selectedProduct.name)}
                                    >
                                        {selectedProduct.expressions.map((expression, x) => (
                                            <option value={expression}>{
                                                expression
                                            }</option>
                                        ))
                                        }
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>
                </>
            )
        } else if (input.type === 'BooleanParameterControlSpec') {
            return (
                <>

                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Label>{input.displayName}</Form.Label>
                        </Col>
                        <Col>

                            <Form.Control
                                size="sm"
                                controlid={input.parameterName}
                                type="checkbox"
                                readOnly={input.readonly}
                                name={selectedProduct.name}
                                value={selectedProduct.value}
                                defaultValue={realValue}
                                onChange={this.handleChange(selectedProduct.name)}
                            />


                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>



                </>
            )
        } else if (input.type === 'TextParameterControlSpec') {
            // the following if statement helps handle inputs that were "derived" and 
            // cannot be entered.
            if (input.parameterName) {

                var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

                return (
                    <>
                        <Form.Label>{input.displayName}</Form.Label>
                        <Form.Row className="align-items-center">
                            <Col>
                                <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            // size="sm"                                            
                                            controlid={input.parameterName}
                                            type="text"
                                            readOnly={input.readonly}
                                            name={selectedProduct.name}
                                            value={selectedProduct.value}
                                            defaultValue={realValue}
                                            onChange={this.handleChange(selectedProduct.name)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                {this.showReset(selectedProduct.name)}
                            </Col>
                        </Form.Row>
                    </>
                )
            }
        } else if (input.type === 'EmptySpaceSpec') {

            return (
                <>
                    <Form.Label>{' '}</Form.Label>
                </>
            )

        } else if (input.type === "LabelSpec") {

            return (
                <>
                    <h6>{input.displayName}</h6>
                </>
            )
        }

    };

    ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        if (isCurrentEventKey) {
            return (
                <b>
                    <a variant="link" onClick={decoratedOnClick}>
                        {children}
                        <FaArrowAltCircleUp />
                    </a>
                </b>
            )
        } else {
            return (
                <a
                    onClick={decoratedOnClick}
                >
                    {children}
                    <FaArrowAltCircleDown />
                </a>

            )
        }
    }


    populateReadOnlyValues() {

        const { configuredControls, resultParams } = this.state
        console.log(configuredControls)

        for (var n = 0; n < configuredControls.length; n++) {
            if (configuredControls[n].tabtype != 'ControlTabGroupSpec') {
                for (var i = 0; i < configuredControls.length; i++) {
                    if (configuredControls[i].formInputs.readonly === true) {
                        let resultParamName = configuredControls[i].formInputs.parameterName;
                        if (resultParamName) { document.getElementById(resultParamName).value = resultParams[resultParamName].value }
                    }
                }
            } else {
                for (var i = 0; i < configuredControls[n].formInputs.length; i++) {
                    if (configuredControls[n].formInputs[i].readonly === true) {
                        let resultParamName = configuredControls[n].formInputs[i].parameterName;
                        if (resultParamName) {document.getElementById(resultParamName).value = resultParams[resultParamName].value }
                    }
                }
            }
        }
    };

    loadNewModel(urnString) {
        var numberOfRuns = this.state.numberOfConfigsRun

        const hasADwg = this.state.hasDwg
        addViewable('urn:' + urnString,
            0, numberOfRuns, hasADwg)
        this.setState({ numberOfConfigsRun: numberOfRuns + 1 })
        // this.showNewConfigurator()
    };

    showInputs() {

        return (
            <>
                <Row>
                    <Col>
                        {this.state.configuredControls.map((input, i) => (
                            <div>
                                {/* The next line checks to see if there are tabs in the form or just inputs */}
                                {(input.tabtype != undefined) && (input.formInputs != undefined) && <>
                                    <Card key={i}>
                                        <Accordion defaultActiveKey={1}>
                                            <Card.Header>
                                                <this.ContextAwareToggle eventKey={i + 1}>{input.tabName} {` `} </this.ContextAwareToggle>
                                                <Accordion.Collapse eventKey={i + 1}>
                                                    <Card.Body>
                                                        {/* for each control, build the input */}
                                                        {input.formInputs.map((control, n) => (
                                                            <>
                                                                {this.handleInputType(control)}
                                                            </>
                                                        ))}
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card.Header>
                                        </Accordion>
                                    </Card>
                                </>
                                }
                                {/* if there are no tabs, the form only has inputs so build those */}
                                {(input.tabName == undefined) && <>
                                    {this.handleInputType(input.formInputs)}
                                </>
                                }

                            </div>
                        ))}

                    </Col>
                </Row>
                <Row>
                    <Col md="4">
                        <Form.Group>
                            <br />
                            <Button onClick={this.runConfiguration} disabled={!this.state.inputsUpdated | this.state.loading} variant="primary">Run Configuration</Button>{' '}
                        </Form.Group>


                    </Col>
                </Row>

                {/* 
                For debugging purposes only
                <div className="col-sm-12">
                    <div><pre>{JSON.stringify(this.state.configuredControls, null, 2)}</pre></div>
                </div> */}
            </>
        )
    };

    showSuccess() {
        if (this.state.success) {
            const { partBuilt, forge_model_download_url } = this.state
            const { forgeProjectFolder, downloadFileName } = this.state.configuratorData;

            return (
                <>
                    <br />
                    <Card>
                        <Card.Body className="alert alert-success">
                            <Card.Title>
                                <p>
                                    {(this.state.error === '') && <><img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                        <strong className="mr-auto">Configuration Successfull!</strong></>}
                                    {(this.state.error !== '') && <><img src={Xmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                        <strong className="mr-auto">Configuration Successfull!</strong></>}
                                </p>
                            </Card.Title>
                            <Card.Text>
                                {this.state.debugging &&
                                    <Link to={`/workitem/${this.state.workItem}`} className="text-warning" target="_blank">
                                        {`workitem: ${this.state.workItem}`}
                                    </Link>
                                }
                            </Card.Text>


                            <p>{(this.state.error === '') && "Add the file to your downloads below"}</p>

                            <button onClick={this.addToCart} className="btn btn-outline-success mt-2 mb-2 card-btn-1">add to your downloads<FaFileDownload /></button>

                        </Card.Body>
                    </Card>

                </>
            )
        } else {
            if (this.state.error != '') {
                return (
                    <div className="col-sm-12">
                        <Toast>
                            <Toast.Header>
                                <img src={Xmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                <strong className="mr-auto">{this.state.error}</strong>
                                {this.state.debugging &&
                                    <div><Link to={`/workitem/${this.state.workItem}`} className="text-warning" target="_blank">
                                        {`workitem: ${this.state.workItem}`}
                                    </Link>
                                    </div>
                                }
                            </Toast.Header>
                        </Toast>
                    </div>
                )
            }
        }
    };



    showFileDownload() {
        const { partBuilt, forge_model_download_url } = this.state
        const { forgeProjectFolder, downloadFileName } = this.state.configuratorData;

        if (partBuilt) {
            return (
                <Toast>
                    <Toast.Header>
                        <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                        <strong className="mr-auto">File Created!</strong>
                        <small>1 second ago</small>
                    </Toast.Header>
                    <Toast.Body>
                        <p>Download file below</p>

                        {this.showAddToCartBtn()}
                    </Toast.Body>
                </Toast>

            )

        }
    };

    runLoader = () => {
        if (this.state.loading) {
            return (
                <div className="col-sm-12">
                    <div className="alert alert-primary">
                        <center>
                            <Loader type="Rings" color="#00BFFF" height={120} width={120} />
                            <h4 color="#00BFFF">Running Configuration and Building Model</h4>
                        </center>
                    </div>
                </div>
            )
        }
    }

    showNewConfigurator = () => {
        confirmAlert({
            title: 'Your new configuration is ready!',
            message: 'Your new model will be loaded in the viewer so you can check your inputs.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                    }
                },
            ]
        });
    };

    render() {
        const { configuratorData, loading, modelUrn, modelUrnKey, debugging, loadingMessage, runningInCAD, hasDwg, viewerExtensions } = this.state;
        // alert(modelUrn)
        return (
            <Layout
                title={configuratorData.title}
                description={configuratorData.description}
                className="container-fluid"
                cadType={runningInCAD}
            >
                <Container fluid>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={`/configuratorTests`}>Back to configurators</a></li>
                            <li className="breadcrumb-item active" aria-current="page" >{configuratorData.title}</li>
                        </ol>
                    </nav>
                    <br />
                    <Row>
                        <Col md="4">
                            {this.showFileDownload()}
                            {this.showSuccess()}
                            {this.runLoader()}
                            {this.showInputs()}


                        </Col>
                        <Col md="8">
                            {/* <Image
                                    src={this.state.previewImage}                                    
                                    className="mb-3"
                                    width={100}
                                    height={100}
                                    // style={style.image}
                                /> */}
                        
                            (modelUrn) &&
                                <Viewer
                                    key={modelUrnKey}
                                    urn={modelUrn}
                                    extensions={viewerExtensions}
                                    hasDwg={hasDwg}
                                />
                        
                            
                        </Col>

                    </Row>
                    {(debugging) && <Row>
                        <Col md="4">
                            {(debugging) && this.state.inputsUpdated && this.showPreConfigParameters()}
                            {(debugging) && this.showResultParams()}
                        </Col>
                    </Row>}
                </Container>
                <Footer />
            </Layout >
        )
    };
};

export default configTesting;
