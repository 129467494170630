import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Layout from "../core/Layout";
import { ucSignIn, authenticate, isAuthenticated, getIpData } from "../auth";
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import Footer from "../core/Footer";

const errorPage = () => {
    
    

    return (
        <Layout
            title="Signin"
            description="Signin to the World's Largest Utilty Content Library"
            className="container-fluid"
            cadType={false}
        >
            
            <div className="container col-md-6 offset-md-3">

                <br />
                <h3>Sorry for the invonveniene. We are currently upgrading our servers to better serve you. Please check back later today.</h3>
                
            </div>
            <br />
            <Footer />
        </Layout>
    );
};

export default errorPage;
