import React, { useEffect } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import Footer from '../core/Footer';
import { useMediaQuery } from 'react-responsive'
import {
    Container,
    Box,
    Button,
    Grid,
} from '@material-ui/core';

import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { getAllFormaContentByUser } from '../api/apiCore';
import JsonTreeView from '../dialogs/jsonTreeView';
import Viewer from '../forgeViewer/Viewer';
import tempDataDumpforTesting from './tempFormaData.json';

//https://v4.mui.com/components/about-the-lab/
//https://v4.mui.com/components/material-icons/

const FormaViewer = () => {
    const [state, setState] = React.useState({
        users: [],
        user: '',
        fullUserList: [],
        userList: [],
        updateMessage: '',
        searchText: '',
        fileList: [],
        modelUrn: 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6cDRhX3Rlc3RpbmcvTmFwZXJ2aWxsZV9Tb3V0aFN1Yl9QNEFkZXNpZ25fZWxldmF0aW9uLmR3Zw',
        modelUrnKey: Math.random(),
    })

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // These parameters will be changed in the future once storage decisions are made

    const refreshPage = () => {
        setTimeout(() => {
            console.log(state.user.token)
            getAllFormaContentByUser(state.user.token, state.user.userId).then(data => {
                // checkAllfileStatus(data)
                setState({
                    ...state,
                    fileList: data
                });
            })
        }, 1000);

    };

    useEffect(() => {
        getAllFormaContentByUser(isAuthenticated().token, isAuthenticated().userId).then(data => {
            setState({
                ...state,
                user: isAuthenticated(),
                fileList: data
            });
        });
    }, []);


    const setFileList = (userId) => {
        getAllFormaContentByUser(isAuthenticated().token, userId).then(data => {
            setState({
                ...state,
                fileList: data
            })
        })
    }



    return (
        <Layout
            title={`${isAuthenticated().name}'s Forma Viewer`}
            description={`for Admin beta testing only`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href={`/admin/formaContent`}>Back to Forma Content</a></li>
                        <li className="breadcrumb-item active" aria-current="page" >Forma Content Viewer</li>
                    </ol>
                </nav>
                <br />
                <Container maxWidth="xl">

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >

                    </Box>

                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <JsonTreeView data={tempDataDumpforTesting.data} />
                        </Grid>
                        <Grid item xs={9}>
                            {state.modelUrn &&
                                <Viewer
                                    key={state.modelUrnKey}
                                    urn={state.modelUrn}
                                    extensions={['Autodesk.DocumentBrowser', 'Autodesk.Hyperlink', 
                                                 'Autodesk.LayerManager', 'Autodesk.ModelStructure', 'Autodesk.PropertiesManager']}
                                    hasDwg={1}
                                />
                            }
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<ImportExportIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Add To Forma Lib
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    endIcon={<ScreenShareIcon />}
                                    onClick={() => (refreshPage())}
                                >
                                    Share
                                </Button>
                            </Grid>


                        </Grid>
                    </Box>



                </Container>
            </div>
            <br />
            <br />

            <Footer />
        </Layout >

    )

};

export default FormaViewer;