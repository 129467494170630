import { API } from "../config";


// Gets authentication for Forge
export const forgeAuth = () => {
    return fetch(`${API}/forge/auth`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            return err.json();
        })
};


// download counts tracking
export const addDownloadedBy = (body) => {
    return fetch(`${API}/configurators/addtodownloads`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



// read Configurator Information for UCH configurator
// Atlas Mongodb collection uchconfigurators
export const readUCHConfigurator = configuratorId => {
    return fetch(`${API}/configurator/${configuratorId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const translateToSvfSimple = (body, token) => {
    return fetch(`${API}/forge/translateToSvfSimple`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
}


export const runAssyConfigSimple = (body, token) => {

    return fetch(`${API}/forge/designautomation/runAssyConfigSimple`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const runPartConfigSimple = (body, token) => {
    return fetch(`${API}/forge/designautomation/runPartConfigSimple`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
}

export const runPartConfig = (body, token) => {
    return fetch(`${API}/forge/designautomation/runPartConfig`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
}

export const updateConfigStatus = (body) => {
    return fetch(`${API}/configurators/updateStatus`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
}

// run a full configuration 
export const runAssyConfig = (body, token) => {

    return fetch(`${API}/forge/designautomation/runAssyConfig`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};



export const checkConfigStatus = (body) => {
    return fetch(`${API}/forge/modelderivative/checkTranslationStatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};


// Lis all Configurators to populate cards
export const uchConfigList = (status) => {
    return fetch(`${API}/configurators`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({'status': status}) 
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};



export const uchConfigListTests = () => {
    return fetch(`${API}/configuratorTests`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
}

export const uchConfigListSearch = (searchText) => {
    
    return fetch(`${API}/configListSearch`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({searchText})        
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const buildWorkItemDetails = (url, token) => {

    return fetch(`${API}/forge/designautomation/workItemDetails`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ workItemFile: url })
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

// go get workitem
export const getWorkItem = (body, token) => {
    return fetch(`${API}/forge/designautomation/checkWorkItemStatus`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

// create a simplified download link
export const getDownloadLink = (body) => {
    return fetch(`${API}/forge/getSimplifiedPartfromAssembly`, { 
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const checkifModelExists = (body) => {

    return fetch(`${API}/forge/modelderivative/modelexists`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};


export const getSignedUrl = (body) => {
    return fetch(`${API}/forge/designautomation/createSignedObject`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
}

export const getResultsParam = (fileUrl) => {
    return fetch(fileUrl)
        .then(response => {
            return response.json();
        })
        .catch((error) => {
            console.error(error);
        });
};


export const deleteBucketObjects = (body) => {
    return fetch(`${API}/forge/emptybucket`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"           
        },
        body: JSON.stringify(body)        
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};


export const deleteUCHConfig = configuratorId => {
    return fetch(`${API}/configurator/${configuratorId}`, {
        method: "DELETE"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};