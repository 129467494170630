import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core/styles';

// Define the custom styles
const useStyles = makeStyles({
  treeItem: {
    fontSize: '0.875rem', // Smaller font size, adjust as needed
  },
});

function JsonTreeView({ data }) {
  const classes = useStyles();

  const renderProperties = (properties, parentId) => {
    return Object.keys(properties).map((key, index) => {
      const nodeId = `${parentId}-${key}-${index}`;
      return (
        <TreeItem key={nodeId} nodeId={nodeId} label={`${key}`}>
          {typeof properties[key] === 'object' ?
            renderProperties(properties[key], nodeId) :
            <TreeItem nodeId={`${nodeId}-value`} label={`${properties[key]}`} />
          }
        </TreeItem>
      );
    });
  };

  const renderCollection = (collection) => {
    return collection.map((item, index) => (
      <TreeItem key={item.objectid} nodeId={`item-${item.objectid}`} label={`${item.name} [${item.externalId}]`}>
        {renderProperties(item.properties, `item-${item.objectid}`)}
      </TreeItem>
    ));
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="root" label="PROPS" classes={{ label: classes.treeItem }}>
        {renderCollection(data.collection)}
      </TreeItem>
    </TreeView>
  );
}

export default JsonTreeView;
