import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { uchConfigList, uchConfigListSearch, uchConfigListTests } from '../api/apiConfigs';
import Layout from '../core/Layout';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Container, Row, Col, Card, CardDeck, CardColumns, Image, CardGroup, Form } from 'react-bootstrap';
import { FaSearch, FaFileDownload } from 'react-icons/fa'
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import Select from 'react-select'
import Creatable, { makeCreatableSelect } from 'react-select/creatable';

class uchconfigListTesting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            configList: '',
            userId: '',
            errorMessage: '',
            success: false,
            runningInCAD: false,
            loading: false,
            loadingMessage: 'Loading Configurator List',
        }
    }

    user = isAuthenticated().user
    configOptionsList = []
    // default model shown is the standard box size uploaded

    componentWillMount() {
        // use the user details to grab the vendor Id associated to them
        uchConfigList("Draft").then(data => {
            if (data.error) {
            } else {
                console.log(data)
                this.setState({
                    configList: data
                })
            }
            data.map((item, i) => {
                this.configOptionsList.push({
                    'value': item._id,
                    'label': item.title
                })
            })

        });

        if (window.uchInventorInterface) {
            this.setState({ runningInCAD: true })
        }
    };

    style = {
        image: {
            'background': '#fefefe',
            'textAlign': 'center',
            'display': 'block',
            'justifyContent': 'center',
            'alignItems': 'center',
            'margin': 'auto',
            'width': '18rem',

            //'min-height': '18rem', 
            //'max-height': '28rem'             
        },
        vendorimage: {
            background: '#fefefe',
            height: 20
        },
    };

    filterConfigList(newValue) {

        uchConfigListSearch(newValue).then(data => {
            if (data.error) {
            } else {
                console.log(data)
                this.setState({
                    configList: data
                })
            }
        });
    }

    handleInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed');
        console.log(inputValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };

    handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue.label);
        this.filterConfigList(newValue.label)
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
    };


    render() {
        const { configList, loading, loadingMessage } = this.state

        if (configList.length > 0) {
            return (
                <LoadingOverlay
                    active={loading}
                    spinner
                    text={loadingMessage}
                >
                    <Layout
                        title="Configurator Testing - Unreleased Configs"
                        description=""
                        className="container-fluid"
                        cadType={this.state.runningInCAD}
                    >
                        <div className="container-fluid">
                            <Row className="justify-content-md-center">
                                <Col xs lg="6">
                                    <Form.Label>Search Configurators:</Form.Label>
                                    <Select
                                        onChange={this.handleChange}
                                        options={this.configOptionsList}
                                        onInputChange={this.handleInputChange}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row className="justify-content-md-center">

                                <CardDeck>
                                    {configList.map((configurator, index) => (
                                        <Card style={{ 'minWidth': '18rem', 'maxWidth': '24rem' }} border="primary" key={index}>
                                            <Card.Body >
                                                <a href={`/vendor/${configurator.vendor._id}`} >
                                                    <Image
                                                        src={configurator.vendor.logo}
                                                        alt={configurator.vendor.name}
                                                        style={this.style.vendorimage}
                                                    />
                                                </a>
                                                <Image
                                                    src={configurator.photo}
                                                    alt={configurator.title}
                                                    style={this.style.image}
                                                />
                                                <Card.Title>{configurator.title}</Card.Title>
                                                <Card.Text>
                                                    {configurator.description}
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <small className="text-muted">{moment(configurator.lastUpdated).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                                            <br />
                                                        </div>
                                                        <div className="row">
                                                            <span className="badge badge-primary badge-pill">{configurator.downloads.length} downloads </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <a href={`https://www.utilitycontent.com/testconfigurator/${configurator._id}`} className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Preview on live site</a>
                                                       

                                                        {/* <Link to={`/testconfigurator/${configurator._id}`} className="mr-2">
                                                            <button className="btn btn-outline-primary mt-2 mb-2 card-btn-1">Details <FaSearch /></button>
                                                        </Link> */}
                                                    </div>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    ))}

                                </CardDeck>
                            </Row>

                        </div>
                        <br />
                    </Layout>
                </LoadingOverlay>
            )
        } else {
            return (
                <Layout
                    title="Configurator Admin"
                    description="Update your configurators"
                    className="container-fluid"
                >
                    <div className="container-fluid">

                        <Row>
                            <Col lg={12}>
                                <h3>There are no configurators.</h3>
                            </Col>
                        </Row>
                    </div>
                </Layout>
            )
        }
    }
}

export default uchconfigListTesting;