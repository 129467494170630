/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import Layout from "./Layout";
import CardContent from "./Card";
import { getVendors } from '../api/apiAdmin';
import { getFilteredContents, countBySearch } from "../api/apiCore";
import TagsCheckbox from "./TagsCheckbox";
import RadioBox from "./RadioBox";
// import TextSearch from './TextSearch';
import Footer from './Footer';
import ReactGA from 'react-ga';
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import './widget.css';
import { Accordion, AccordionContext, Container, Card, Row, Col, CardGroup } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive'

const Browse = () => {
    const [myFilters, setMyFilters] = useState({
        filters: { tags: [], brand: [] }
    });
    const [searchFileText, setFileSearchText] = useState([]);
    const [searchDescText, setSearchDescText] = useState([]);
    const [error, setError] = useState(false);
    const [limit] = useState(10);
    const [skip, setSkip] = useState(0);
    const [size, setSize] = useState(0);
    const [filteredResults, setFilteredResults] = useState([]);

    const [brandvalues, setBrandValues] = useState({
        fixedBrands: [],
    });
    const { fixedBrands } = brandvalues;

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

    /* styles.css */

    const [totalsearchSize, settotalsearchSize] = useState(0);
    const [loading, setLoading] = useState([])

    const [ranSearch, setRanSearch] = useState([])

    const init = () => {
        if (sessionStorage.getItem('fileSearchText') === null) { sessionStorage.setItem('fileSearchText', '') }
        if (sessionStorage.getItem('searchDescText') === null) { sessionStorage.setItem('searchDescText', '') }
        if (sessionStorage.getItem('ranSearch') === null) { sessionStorage.setItem('ranSearch', false) }

        setRanSearch(sessionStorage.getItem('ranSearch'))
        setFileSearchText(sessionStorage.getItem('fileSearchText'));
        setSearchDescText(sessionStorage.getItem('searchDescText'));

        setLoading(true);
        getVendors().then(data => {
            if (data.error) {
                setBrandValues({ ...brandvalues, error: data.error });
            } else {
                setBrandValues({
                    ...brandvalues,
                    fixedBrands: data,
                });
            }
        });
    };

    const loadFilteredResults = (newFilters) => {

        getFilteredContents(skip, limit, newFilters, sessionStorage.getItem('fileSearchText'), sessionStorage.getItem('searchDescText')).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setFilteredResults(data.data);
                setSize(data.size);
                setSkip(0);
                getCount(newFilters)
            }
        });

    };


    const getCount = async (newFilters) => {

        await countBySearch(newFilters, sessionStorage.getItem('fileSearchText'), sessionStorage.getItem('searchDescText')).then(
            data => {
                if (data.error) {
                } else {
                    settotalsearchSize(data.totalCount);
                    setLoading(false);
                }
            }
        )
    };

    const loadMore = () => {
        let toSkip = skip + limit;
        // console.log(newFilters);
        getFilteredContents(toSkip, limit, myFilters.filters, sessionStorage.getItem('fileSearchText'), sessionStorage.getItem('searchDescText')).then(data => {
            if (data.error) {
                setError(data.error);
            } else {
                setFilteredResults([...filteredResults, ...data.data]);
                setSize(data.size);
                setSkip(toSkip);
            }
        });
    };

    const loadNumberofItemsFound = (results) => {
        // if there is a description of text and or a file name to search, do it.
        if (sessionStorage.getItem('fileSearchText') !== '' || sessionStorage.getItem('searchDescText') !== '') {
            return (
                <>
                    <span class="badge badge-success">showing {size} of {totalsearchSize} found </span>
                </>
            )
        }
    }

    const runLoader = () => {
        if (loading) {
            return (
                <div className="row">
                    <div className="col-mb-3">
                        <h4 color="#00BFFF">Searching...</h4>
                    </div>
                    <div className="col-mb-3">
                        <Loader type="Circles" color="#00BFFF" height={40} width={40} />
                    </div>
                </div>
            )
        }
    }
    const loadMoreButton = () => {

        if (sessionStorage.getItem('fileSearchText') !== '' || sessionStorage.getItem('searchDescText') !== '') {
            if (totalsearchSize >= size) {
                return (
                    size > 0 &&
                    size >= limit && (
                        <button onClick={loadMore} className="btn btn-warning mb-5">
                            Load {size} more from {totalsearchSize - (skip + limit)} remaining
                        </button>
                    )
                );
            } else {
                return (
                    size > 0 &&
                    size >= limit && (
                        <button onClick={loadMore} className="btn btn-warning mb-5">
                            Load {size} more
                        </button>
                    )
                );
            }
        } else {
            return (
                size > 0 &&
                size >= limit && (
                    <button onClick={loadMore} className="btn btn-warning mb-5">
                        Load {size} more
                    </button>
                )
            );
        }
    };

    useEffect(() => {
        init();
        loadFilteredResults(myFilters.filters);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/browse");

    }, []);

    const handleFilters = (filters, filterBy) => {

        const newFilters = { ...myFilters };
        newFilters.filters[filterBy] = filters;

        if (filterBy === "brand") {
            let brandValues = handleBrand(filters);
            newFilters.filters[filterBy] = brandValues;
        }
        loadFilteredResults(myFilters.filters);
        setMyFilters(newFilters);
    };

    const handleBrand = value => {
        const data = fixedBrands;
        let array = [];

        for (let key in data) {
            if (data[key].name === value) {
                array = data[key].name;
            }
        }

        return array;
    };

    const resetPage = () => {
        window.location.reload();
    }

    const noContentMessage = (results) => {
        if (results < 1) {
            return <h3>Nothing found!</h3>
        }
    };

    const handleDescSearchChange = name => event => {
        // Passing in an empty "search" parameter for now
        // may want to use a deeper search in the future   
        // alert(event.target.value)  
        setSearchDescText(event.target.value)
    };

    const handleFileSearchChange = name => event => {
        // Passing in an empty "search" parameter for now
        // may want to use a deeper search in the future   
        // alert(event.target.value)  
        setFileSearchText(event.target.value)
    };

    const searchSubmit = e => {
        e.preventDefault();
        sessionStorage.setItem('searchDescText', searchDescText);
        sessionStorage.setItem('fileSearchText', searchFileText);
        sessionStorage.setItem('ranSearch', true)
        if (sessionStorage.getItem('fileSearchText') === null) { sessionStorage.setItem('fileSearchText', '') }
        if (sessionStorage.getItem('searchDescText') === null) { sessionStorage.setItem('searchDescText', '') }
        loadFilteredResults(myFilters.filters)

    };

    const clearSearch = e => {
        e.preventDefault();
        setSearchDescText('');
        setFileSearchText('');
        setRanSearch(false);
        sessionStorage.setItem('searchDescText', '');
        sessionStorage.setItem('fileSearchText', '');
        sessionStorage.setItem('ranSearch', false)
        loadFilteredResults(myFilters.filters)
    }

    

    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        if (isCurrentEventKey) {
            return (
                <b>
                    <a
                        variant="link"
                        onClick={decoratedOnClick}
                    >
                        {children}
                        <FaArrowAltCircleUp />
                    </a>
                </b>
            )
        } else {
            return (
                <a
                    onClick={decoratedOnClick}
                >
                    {children}
                    <FaArrowAltCircleDown />
                </a>

            )
        }
    }

    return (
        <Layout
            title="Browse Our Entire Content Library"
            description="Search file names, descriptions and use filters"
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <Container fluid>
                <Row>
                    <Col xs={5} md={3} lg={2}> 
                        <Row>
                        <Col>
                            <Accordion defaultActiveKey="brands">
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle eventKey="brands">Filter by Vendors </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="brands">
                                        <Card.Body>
                                            <RadioBox
                                                brands={fixedBrands.map((c, i) => (
                                                    {
                                                        name: c.name
                                                    }
                                                ))}
                                                handleFilters={filters =>
                                                    handleFilters(filters, "brand")
                                                }
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                           <Col>
                            <Accordion defaultActiveKey="tags">
                                <Card>
                                    <Card.Header>
                                        <ContextAwareToggle eventKey="tags">Filter by Tags </ContextAwareToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="tags">
                                        <Card.Body>
                                            <TagsCheckbox
                                                handleFilters={filters =>
                                                    handleFilters(filters, "tags")
                                                }
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            </Col>                            
                        </Row>
                        <br />
                    </Col>
                    <Col xs={7} md={9} lg={10}>
                        <Row className="justify-content-md-center">

                            <Col md={6}>
                                <h5>Search File Names:  <span class="label label-default">
                                    <Link data-tip data-for='SearchFileName' className="badge badge-info" to="/gethelp">?
                            </Link></span></h5>
                                <div className="input-group">
                                    <ReactTooltip id='SearchFileName' type='dark' place="right">
                                        <span>
                                            <div className="row" style={{ width: "400px" }}>
                                                <div className="col-lg-12">
                                                    <p>Search By File Name</p>
                                                    <p>
                                                        Search for a File by Name or a specific Vendor Part Number
                                                    like "2709-1-4"</p>
                                                    <p>
                                                        You can combine both some file specific criteria with some
                                                    specific text to search for in the description using the next search box too.</p>
                                                    <p>Select the search button when done.</p>
                                                </div>
                                            </div>
                                        </span>
                                    </ReactTooltip>
                                    <input
                                        id="searchFileString"
                                        type="text"
                                        value={searchFileText}
                                        onChange={handleFileSearchChange('searchFileString')}
                                        className="form-control"
                                        aria-describedby="nameHelp"
                                        placeholder="Type Any File Name"
                                    >
                                    </input>
                                </div>
                            </Col>

                        </Row>
                        <br />
                        <Row className="justify-content-md-center">

                            <Col md={6}>
                                <h5>Search descriptions:  <span class="label label-default"><Link data-tip data-for='SearchDesc' className="badge badge-info" to="/gethelp">?</Link></span></h5>
                                <div className="input-group">
                                    <ReactTooltip id='SearchDesc' type='dark' place="right">
                                        <span>
                                            <div className="row" style={{ width: "400px" }}>
                                                <div className="col-lg-12">
                                                    <p>Search By Description</p>
                                                    <p>
                                                        Search for any text that can be found in the file's description
                                                        field like "bronze" or "#4"
                                                </p>
                                                    <p>
                                                        You can combine both specific text and some file name criteria
                                                        using the previous search box too.
                                                </p>
                                                    <p>Select the search button when done.</p>
                                                </div>
                                            </div>
                                        </span>
                                    </ReactTooltip>
                                    <input
                                        id="searchDescString"
                                        type="text"
                                        value={searchDescText}
                                        onChange={handleDescSearchChange('searchDescString')}
                                        className="form-control"
                                        aria-describedby="nameHelp"
                                        placeholder="Type Any Description"
                                    >
                                    </input>
                                    {/* <button onClick={searchSubmit} className="btn btn-primary">Search</button> */}
                                </div>
                            </Col>

                        </Row>
                        <Row className="justify-content-md-center">
                            <Col xs lg="3">
                                <br />
                                <button onClick={searchSubmit} className="btn btn-primary">Search</button>
                            </Col>
                            <Col xs lg="1">
                                <br />
                                <button onClick={clearSearch} enabled="false" className="btn btn-secondary" >
                                    Clear</button>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <hr />
                        </Row>
                        <Row className="justify-content-md-center">
                            {runLoader()}
                        </Row>
                        <Row className="justify-content-md-center">
                            {loadNumberofItemsFound(filteredResults)}

                        </Row>
                        <Row className="justify-content-md-center">
                            <hr />
                        </Row>
                        <Row md={10}>
                            <Col lg>
                                <CardGroup>
                                    <div className="card-deck ">
                                        {filteredResults.map((content, i) => (
                                            <div >
                                                <CardContent key={i}
                                                    cardStyle={{ 'minWidth': '24rem', 'maxWidth': '24rem', 'marginTop': "1%"   }}
                                                    content={content}
                                                    imageSize={225}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                    {loadMoreButton()}
                                </CardGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            {/* <Widget
                        handleNewUserMessage={handleNewUserMessage}
                        title="your Content Robot"
                        subtitle="find answers here!"
                    /> */}
            < Footer />
        </Layout >

    );

};

export default Browse;
