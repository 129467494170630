/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useContext } from "react";
import { isAuthenticated } from '../auth';
import Layout from '../core/Layout';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { Row, Col, Button, Form, Toast, Card, Accordion, AccordionContext, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    runPartConfigSimple, runAssyConfigSimple, translateToSvfSimple, getSignedUrl, getResultsParam,
    readUCHConfigurator, checkifModelExists, addDownloadedBy
} from "../api/apiConfigs";
import { FaArrowAltCircleDown, FaArrowAltCircleUp, FaFileDownload, FaPencilAlt } from 'react-icons/fa';
import Xmark from '../images/x-mark-xxl.png';
import Checkmark from '../images/Check-mark-3-xxl.png';
import { sendMessage } from '../api/apiCore';
import Viewer from '../forgeViewer/Viewer';
import Footer from '../core/Footer'
import { addItem } from '../core/cartHelpers';
import LoadingOverlay from 'react-loading-overlay';
import ReactLoading from 'react-loading';
import ReactGA from 'react-ga';

class singleConfigurator extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activityId: '',
            forgeProjectFolder: '',
            inputDoc: '',
            rootFileName: '',
            previewImage: '',
            message: '',
            status: '',
            report: '',
            workitemId: '',
            processTimeInSecs: 0,
            emailSent: false,
            outputModelUrn: '',
            modelUrn: '',
            modelUrnKey: Math.random(),
            runningInCAD: false,
            inputsUpdated: false,
            configuredControls: [],
            configuredParameters: [],
            forge_model_download_url: '',
            configuratorData: [],
            viewerExtensions: ['NestedViewerExtension', 'Autodesk.Measure'],
            forgeLoading: false,     // was trying to control when forge viewer was done loading
            progressValue: 10
        }
        this.runConfiguration = this.runConfiguration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clickSendErrorReport = this.clickSendErrorReport.bind(this);
        this.configureInputs = this.configureInputs.bind(this);
        this.resetValue = this.resetValue.bind(this);
    }

    token = isAuthenticated() && isAuthenticated().token;
    userId = isAuthenticated().userId

    populateReadOnlyValues() {
        const { configuredControls, resultParams } = this.state
        console.log(configuredControls)

        for (var n = 0; n < configuredControls.length; n++) {
            if (configuredControls[n].tabtype != 'ControlTabGroupSpec') {
                for (var i = 0; i < configuredControls.length; i++) {
                    if (configuredControls[i].formInputs.readonly === true) {

                        let resultParamName = configuredControls[i].formInputs.parameterName;
                        if (resultParamName) { document.getElementById(resultParamName).value = resultParams[resultParamName].value }
                    }
                }
            } else {
                for (var i = 0; i < configuredControls[n].formInputs.length; i++) {
                    if (configuredControls[n].formInputs[i].readonly === true) {
                        let resultParamName = configuredControls[n].formInputs[i].parameterName;
                        console.log(resultParams)
                        if (resultParamName) { document.getElementById(resultParamName).value = resultParams[resultParamName].value }
                    }
                }
            }
        }
    };

    resetValue(name) {

        var oldParams = this.state.parameters.find(item => item.name === name)

        for (var i = 0; i < this.state.configuredParameters.length; i++) {
            if (this.state.configuredParameters[i].name === [name]) {
                this.state.configuredParameters[i].expression = oldParams.expression
                break;
            }
        }

        if (document.getElementById(name).type === 'text') {
            document.getElementById(name).value = oldParams.expression
        } else {
            var element = document.getElementById(name);
            element.value = oldParams.expression;
        }

        // look and see if the configured parameter is already in the list        
        var configuredItemFound = this.state.configuredParameters.find(item => item.name === name)
        if (configuredItemFound) {
            if (configuredItemFound.expression !== oldParams.expression) {
                configuredItemFound.expression = oldParams.expression
                // } else {
                //     // add it to the list
                //     var newUpdatedParam = {
                //         name: name,
                //         type: oldParams.type,
                //         unit: oldParams.unit,
                //         expression: oldParams.expression
                //     }
                //     // console.log(parameterFound)
                //     // console.log(newUpdatedParam)
                //     // push the new parmeter info to the configured list
                //     this.state.configuredParameters.push(newUpdatedParam)
            }
        }
        this.setState({ inputsUpdated: true })
    };

    ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        if (isCurrentEventKey) {
            return (
                <b>
                    <a variant="link" onClick={decoratedOnClick}>
                        {children}
                        <FaArrowAltCircleUp />
                    </a>
                </b>
            )
        } else {
            return (
                <a
                    onClick={decoratedOnClick}
                >
                    {children}
                    <FaArrowAltCircleDown />
                </a>

            )
        }
    }

    showReset(inputName) {
        if (this.state.inputsUpdated === true) {
            var parameter = this.state.parameters.find(item => item.name === inputName)
            var configuredParam = this.state.configuredParameters.find(item => item.name === inputName)

            if (configuredParam !== undefined) {
                if (parameter.expression !== configuredParam.expression) {
                    return (
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Reset Input</Tooltip>}>
                            <Button onClick={() => { this.resetValue(inputName) }} className="mb-2">
                                <FaPencilAlt />
                            </Button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    };


    handleInputType(input) {
        // for each form item, parse the type and details.       
        var parametersToBuild = this.state.parameters

        // build out the list of parameters to build out based on parameters read from iLogic
        let selectedProduct = parametersToBuild.find(inputName => inputName.name === input.parameterName);

        if (input.type === 'MultiValueTextParameterControlSpec') {
            return (
                <>
                    <Form.Label>{input.displayName}</Form.Label>
                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        type="select"
                                        readOnly={input.readonly}
                                        defaultValue={selectedProduct.expression}
                                        name={selectedProduct.name}
                                        value={selectedProduct.value}
                                        onChange={this.handleChange(selectedProduct.name)} >
                                        {selectedProduct.expressions.map((expression, n) => (
                                            <option value={expression}>{
                                                expression
                                            }</option>
                                        ))
                                        }
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>
                </>
            )
        } else if (input.type === 'NumericParameterControlSpec') {
            // the following if statement helps handle inputs that were "derived" and 
            // cannot be entered.
            if (input.parameterName) {

                var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

                return (
                    <>
                        <Form.Label>{input.displayName}</Form.Label>
                        <Form.Row className="align-items-center">
                            <Col>
                                <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            // size="sm"                                            
                                            controlid={input.parameterName}
                                            type="text"
                                            readOnly={input.readonly}
                                            name={selectedProduct.name}
                                            value={selectedProduct.value}
                                            defaultValue={realValue}
                                            onChange={this.handleChange(selectedProduct.name)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                {this.showReset(selectedProduct.name)}
                            </Col>
                        </Form.Row>
                    </>
                )
            }
        } else if (input.type === 'MultiValueNumericParameterControlSpec') {

            var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

            return (
                <>
                    <Form.Label>{input.displayName}</Form.Label>
                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        as="select"
                                        type="select"
                                        readOnly={input.readonly}
                                        defaultValue={realValue}
                                        name={selectedProduct.name}
                                        value={selectedProduct.value}
                                        onChange={this.handleChange(selectedProduct.name)}
                                    >
                                        {selectedProduct.expressions.map((expression, m) => (
                                            <option value={expression}>{
                                                expression
                                            }</option>
                                        ))
                                        }
                                    </Form.Control>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>
                </>
            )
        } else if (input.type === 'BooleanParameterControlSpec') {
            return (
                <>

                    <Form.Row className="align-items-center">
                        <Col>
                            <Form.Label>{input.displayName}</Form.Label>
                        </Col>
                        <Col>

                            <Form.Control
                                size="sm"
                                controlid={input.parameterName}
                                type="checkbox"
                                readOnly={input.readonly}
                                name={selectedProduct.name}
                                value={selectedProduct.value}
                                defaultValue={realValue}
                                onChange={this.handleChange(selectedProduct.name)}
                            />


                        </Col>
                        <Col xs="auto">
                            {this.showReset(selectedProduct.name)}
                        </Col>
                    </Form.Row>



                </>
            )
        } else if (input.type === 'TextParameterControlSpec') {
            // the following if statement helps handle inputs that were "derived" and 
            // cannot be entered.
            if (input.parameterName) {

                var realValue = selectedProduct.expression.replace(" " + selectedProduct.unit, "")

                return (
                    <>
                        <Form.Label>{input.displayName}</Form.Label>
                        <Form.Row className="align-items-center">
                            <Col>
                                <Form.Group key={input.parameterName} controlid={input.parameterName}>
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>{selectedProduct.unit}</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            // size="sm"                                            
                                            controlid={input.parameterName}
                                            type="text"
                                            readOnly={input.readonly}
                                            name={selectedProduct.name}
                                            value={selectedProduct.value}
                                            defaultValue={realValue}
                                            onChange={this.handleChange(selectedProduct.name)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                {this.showReset(selectedProduct.name)}
                            </Col>
                        </Form.Row>
                    </>
                )
            }
        } else if (input.type === 'EmptySpaceSpec') {

            return (
                <>
                    <Form.Label>{' '}</Form.Label>
                </>
            )

        } else if (input.type === "LabelSpec") {

            return (
                <>
                    <h6>{input.displayName}</h6>
                </>
            )
        }

    };


    configureInputs(inputs) {
        var configuredControls = []

        for (var i in inputs) {
            if (inputs[i].type === 'ControlTabGroupSpec') {
                // this configurator has multiple tabs for inputs                
                configuredControls.push({
                    tabName: inputs[i].name,
                    tabDisplayName: inputs[i].displayName,
                    tabtype: inputs[i].type,
                    formInputs: inputs[i].items
                })
            } else {
                configuredControls.push({
                    formInputs: inputs[i]
                })
            }
        }
        this.setState({
            configuredControls: configuredControls
        })
    };


    handleChange = name => event => {
        // the change handles both updating previously change params as well as 
        // adding any that have just been changed.
        const { configuredParameters, parameters } = this.state;

        var value = event.target.value
        // look and see if the configured parameter is already in the list        
        var configuredItemFound = configuredParameters.find(item => item.name === name)
        // grab the info on the parameter from the global list
        var parameterFound = parameters.find(item => item.name === name)

        // if it exists in the configured list
        if (configuredItemFound) {
            if (configuredItemFound.expression !== value) {
                configuredItemFound.expression = value
            }
        } else {
            // add it to the list
            var newUpdatedParam = {
                name: name,
                type: parameterFound.type,
                unit: parameterFound.unit,
                expression: value
            }
            configuredParameters.push(newUpdatedParam)
        }
        this.setState({
            inputsUpdated: true,
            success: false,
            partBuilt: false
        })
    };

    componentWillMount = async () => {
        await readUCHConfigurator(this.props.match.params.configId).then(data => {
            if (data.error) {
                this.setState({ errorMessage: data.error });
            } else {
                this.setState({
                    configuratorData: data,
                    inputs: data.forms[0].items,
                    parameters: data.parameters,                    
                    modelUrn: data.defaultFile,
                    modelUrnKey: Math.random(),
                    outputModelUrn: '',
                    hasDwg: (data.rootFileName.split(".")[1] === 'iam') ? 1 : 0,
                    viewerExtensions: (data.rootFileName.split(".")[1] === 'iam') ? ['NestedViewerExtension'] : [],
                    photo: data.photo
                })

                window.localStorage.setItem('currentUrn', data.defaultFile);
                // // setup the preview image url
                // getSignedUrl({ folderName: data.forgeProjectFolder, objectName: 'preview.png' }).then(data => {
                //     this.setState({
                //         previewImage: data
                //     })
                // });
                this.configureInputs(data.forms[0].items)

            }
        })
        if (window.uchInventorInterface) {
            this.setState({ runningInCAD: true })
        }
        // console.log(window.localStorage.getItem('forgeViewerStatus'))
        // while (window.localStorage.getItem('forgeViewerStatus') != 'loaded') {

        //     if (window.localStorage.getItem('forgeViewerStatus') === 'loaded') {            
        //         this.setState({ forgeLoading: false })
        //     } 
        // } 
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/configurator/" + this.props.match.params.configId);
    };


    clickSendErrorReport = e => {
        const { configuratorData } = this.state
        e.preventDefault();
        
        const mailMessage = {
            "subject":  'UCH configurator ' + configuratorData.title + ' error report',
            "from": isAuthenticated().user.email,           
            "to": "steve.kaufman@spatialbiz.com",
            "html": `<html><body>The forge report for configurator ${configuratorData._id} is: ${this.state.report},</body></html>`
        }

        // make request to api to send message
        sendMessage(mailMessage).then(data => {
            this.setState({ emailSent: true })
        });
    };

    addToCart = () => {
        // console.log('added');
        addItem({
            brand: 'configurators',
            _id: this.state.configuratorData._id,
            name: this.state.forge_model_download_url,
            description: this.state.configuratorData.description,
            vendor: '5e42a64c6e0fcb3e50b849f6',
            user: this.userId,
            modelLink: this.state.configuratorData.forgeProjectFolder + '/' + this.state.forge_model_download_url,
            content_type: 'Configurator',
            fileType: 'ipt',
            fileSize: '54338',
            photo: this.state.photo,
            edition_number: 1
        });
        // gather and send info about download
        addDownloadedBy({ configId: this.state.configuratorData._id, userId: this.userId }).then(downloadCount => { })
    };




    showMessage() {
        // this handles all forge errors that can possibly be returned from the forge service
        // namely: success, failedDownload, failedInstructions, failedUpload, failedUploadOptional
        // translation status tracked is only 'failed'

        const { message, report, workitemId, processTimeInSecs, outputDoc, emailSent, progressValue } = this.state

        switch (message) {
            case 'success':
                return (
                    <Toast>
                        <Toast.Header>
                            <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Configuration {message}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <p><small>forge processing time was {processTimeInSecs} seconds</small></p>                            
                            <button onClick={this.addToCart} className="btn btn-outline-success mt-2 mb-2 card-btn-1">
                                add to your downloads<FaFileDownload /></button>
                        </Toast.Body>
                    </Toast>

                )
                break;
            case 'running':
                return (
                    <Toast>
                        <Toast.Header>
                            <ReactLoading type={'bubbles'} color={'#128da3'} height={'20%'} width={'20%'} />
                            <strong className="mr-auto">{'   '}Configuration {message}</strong>
                            <ReactLoading type={'bubbles'} color={'#128da3'} height={'20%'} width={'20%'} />
                        </Toast.Header>

                    </Toast >
                )
                break;
            case 'translating':
                return (
                    <Toast>
                        <Toast.Header>
                            <ReactLoading type={'bubbles'} color={'#128da3'} height={'20%'} width={'20%'} />
                            <strong className="mr-auto">{'   '}Translating 3D model for preview</strong>
                            <ReactLoading type={'bubbles'} color={'#128da3'} height={'20%'} width={'20%'} />
                        </Toast.Header>
                    </Toast>


                )
                break;
            case 'failed':
                return (
                    <Toast.Body>
                        <Toast.Header>
                            <img src={Xmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Translation {message}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            {/* <a href={report}>Download forge report</a> */}
                            <p>forge workItem is: {workitemId}</p>
                            <p>forge processing time was {processTimeInSecs} seconds</p>
                            {!emailSent && <Button variant="outline-warning" onClick={this.clickSendErrorReport}>
                                Submit the error report</Button>
                            }
                            {emailSent && <>
                                <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                <strong className="mr-auto">report Submitted. We'll be in touch!</strong>
                            </>
                            }

                        </Toast.Body>

                    </Toast.Body>

                )
                break;
            case 'failedDownload':
                return (
                    <Toast>
                        <Toast.Header>
                            <img src={Xmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Configuration {message}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            {/* <a href={report}>Download forge report</a> */}
                            <p>forge workItem is: {workitemId}</p>
                            <p>forge processing time was {processTimeInSecs} seconds</p>
                            {!emailSent && <Button variant="outline-warning" onClick={this.clickSendErrorReport}>
                                Submit the error report</Button>
                            }
                            {emailSent && <>
                                <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                <strong className="mr-auto">report Submitted. We'll be in touch!</strong>
                            </>
                            }

                        </Toast.Body>

                    </Toast>

                )
                break;
            case 'failedInstructions':
                return (
                    <Toast>
                        <Toast.Header>
                            <img src={Xmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                            <strong className="mr-auto">Configuration {message}</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <a href={report}>Download forge report</a>
                            <p>forge workItem: {workitemId}</p>
                            <p>forge processing time was {processTimeInSecs} seconds</p>
                            {!emailSent && <Button variant="outline-warning" onClick={this.clickSendErrorReport}>
                                Submit the report</Button>
                            }
                            {emailSent && <>
                                <img src={Checkmark} style={{ height: '15px' }} className="rounded mr-2" alt="" />
                                <strong className="mr-auto">report Submitted!</strong>
                            </>
                            }

                        </Toast.Body>

                    </Toast>

                )
                break;
            default:
                break;

        }
    };

    translateFile = async () => {
        const { forge_model_download_url } = this.state
        const { rootFileName, forgeProjectFolder, inputDoc } = this.state.configuratorData

        this.setState({ message: 'translating' })
        // check for assembly or ipt
        // assembly is actually a dwf file to translate
        if (rootFileName.split('.')[1] == 'iam') {
            var forge_model_toTranslate = forge_model_download_url.replace(".ipt", ".dwf")
        } else {
            var forge_model_toTranslate = forge_model_download_url
        }

        const translationBody = {
            "folderName": forgeProjectFolder,
            "rootFileName": rootFileName,   //outputPreviewDoc
            "fileName": forge_model_toTranslate,   //rootFileName
        }

        console.log(translationBody)
        await translateToSvfSimple(translationBody, this.token).then(data => {
            if (data.error) {
                this.setState({ message: data.status, status: data.status })
            } else {
                this.setState({
                    modelUrn: data.urn,
                    modelUrnKey: Math.random(),
                    translationProgress: data.progress,
                    status: data.status,
                    message: data.status
                })
            }
        });
    }

    buildOutPutDocName(configuredParameters) {

        var filename = ''
        let outputFileName = '' // this is for non-caching version
        // use every single input value to generate hashed file nam
        // this is used to find out if the values exist or not so Forge
        //doesn't generate a new file
        for (var i = 0; i < configuredParameters.length; i++) {
            filename = filename + configuredParameters[i].expression
        }
        var hash = 0, i, chr;

        for (i = 0; i < filename.length; i++) {
            chr = filename.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }

        hash = hash + ".ipt"

        hash = hash.replaceAll('-', '')
        return hash
    };


    runConfiguration = async () => {
        const { activityId, forgeProjectFolder, inputDoc, rootFileName } = this.state.configuratorData;
        let fileExists = true
        const { configuredParameters } = this.state;

        this.setState({ message: 'running' })

        var paramList = '{'
        for (var i = 0; i < configuredParameters.length; i++) {
            paramList = paramList + `"${configuredParameters[i].name}": {"value": "${configuredParameters[i].expression}","unit": "${configuredParameters[i].unit}"},`
        }
        paramList = paramList.slice(0, -1) + '}' // removes the last digit "," and adds the closing "}"        

        var outputDoc = this.buildOutPutDocName(configuredParameters);

        // if running with file caching, check if the file exists
        // check if the file exists already so we don't have to regenerate
        if (inputDoc.split(".")[1] === 'ipt') {
            var searchableDoc = outputDoc
        } else {
            var searchableDoc = outputDoc.replace('.ipt', '.dwf')
        }

        await checkifModelExists({ inputFileName: searchableDoc, folderName: forgeProjectFolder }).then(data => {
            if (data.error) {
                // error is file not found so flag the variable to                 
                this.fileExists = false
            } else {
                this.fileExists = true
                this.setState({
                    forge_model_download_url: outputDoc,
                    status: data.status,
                    modelUrn: data.viewablefile
                })
            }
            this.setState({ initialRun: true })
        });

        // if file doesn't exist
        if (this.fileExists === false) {
            const configBody = {
                "activityId": activityId,
                "forgeProjectFolder": forgeProjectFolder,
                "inputDoc": inputDoc,
                "outputDoc": outputDoc,
                "rootFileName": rootFileName,
                "params": paramList
            }
            if (inputDoc.split(".")[1] === 'zip') {
                // console.log(configBody)
                await runAssyConfigSimple(configBody, this.token).then(data => {
                    if (data.error) {
                        this.setState({ error: data.error })
                    } else {
                        this.setState({
                            workItem: data.workItem,
                            inputsUpdated: false,
                            forge_model_download_url: outputDoc,
                            status: data.status,
                            report: data.report,
                            workitemId: data.workitemId,
                            processTimeInSecs: data.processTimeInSecs,
                            message: data.status,
                            hasDwg: 1,
                            viewerExtensions: ['NestedViewerExtension']
                        })
                        if (data.status === 'success') {
                            // alert(data.downloadFile)
                            // grab the json signed url for the return params
                            getSignedUrl({ folderName: forgeProjectFolder, objectName: 'result.json' }).then(data => {
                                getResultsParam(data).then(jsondata => {
                                    // put the result params into a JSON 
                                    this.setState({
                                        resultParams: jsondata
                                    })
                                })
                            });
                            this.translateFile()
                        }
                    }
                });
            } else {
                await runPartConfigSimple(configBody, this.token).then(data => {
                    if (data.error) {
                        this.setState({ message: data.status, status: data.status })
                    } else {
                        this.setState({
                            workItem: data.workItem,
                            inputsUpdated: false,
                            forge_model_download_url: outputDoc,
                            status: data.status,
                            report: data.report,
                            workitemId: data.workitemId,
                            processTimeInSecs: data.processTimeInSecs,
                            message: data.status,
                            viewerExtensions: [],
                        })
                        if (data.status === 'success') {

                            // alert(data.downloadFile)
                            // grab the json signed url for the return params
                            getSignedUrl({ folderName: forgeProjectFolder, objectName: 'result.json' }).then(data => {
                                getResultsParam(data).then(jsondata => {
                                    // put the result params into a JSON 
                                    this.setState({
                                        resultParams: jsondata
                                    })
                                })
                            });

                            getSignedUrl({ folderName: this.state.forgeProjectFolder, objectName: 'preview.png' }).then(data => {
                                this.setState({
                                    previewImage: data + '?' + Math.random()
                                })
                            });

                            this.translateFile()
                        }
                    }
                });
            }
        } else {
            this.setState({
                status: 'success',
                message: 'success',
                modelUrnKey: Math.random()
            })
        }
    };

    render() {
        const { height, modelUrn, modelUrnKey, previewImage, viewerExtensions, hasDwg, forgeLoading, configuratorData, runningInCAD } = this.state;
        return (
            <LoadingOverlay
                active={forgeLoading}
                spinner
                text={'Loading the Autodesk Forge Viewer'}
            >
                <Layout
                    title={configuratorData.title}
                    description={configuratorData.description}
                    className="container-fluid"
                    cadType={runningInCAD}
                > 
                    <div className="container-fluid">
                        {/* <p>{modelUrn}</p> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href={`/configurators`}>Back to Configurators</a></li>
                                <li className="breadcrumb-item active" aria-current="page" >{configuratorData.title}</li>
                            </ol>
                        </nav>
                        <div className="container-fluid">

                            <Row>
                                <Col md="4">
                                    {this.showMessage()}
                                    {this.state.configuredControls.map((input, i) => (
                                        <>
                                            {/* The next line checks to see if there are tabs in the form or just inputs */}
                                            {(input.tabtype !== undefined) && (input.formInputs !== undefined) && <>
                                                <Card key={i}>
                                                    <Accordion defaultActiveKey={1}>
                                                        <Card.Header>
                                                            <this.ContextAwareToggle eventKey={i + 1}>{input.tabName} {` `} </this.ContextAwareToggle>
                                                            <Accordion.Collapse eventKey={i + 1}>
                                                                <Card.Body>
                                                                    {/* for each control, build the input */}
                                                                    {input.formInputs.map((control, d) => (
                                                                        <>
                                                                            {this.handleInputType(control)}
                                                                        </>
                                                                    ))}
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card.Header>
                                                    </Accordion>
                                                </Card>
                                            </>
                                            }
                                            {/* if there are no tabs, the form only has inputs so build those */}
                                            {(input.tabName == undefined) && <>
                                                {this.handleInputType(input.formInputs)}
                                            </>
                                            }
                                        </>
                                    ))}

                                    <Form.Group>
                                        <br />
                                        <Button onClick={this.runConfiguration} disabled={!this.state.inputsUpdated}
                                            variant="outline-primary">Run Configuration</Button>{' '}
                                    </Form.Group>

                                </Col>

                                <Col md="8">
                                    {(modelUrn) &&
                                        <Viewer
                                            key={modelUrnKey}
                                            urn={modelUrn}
                                            extensions={viewerExtensions}
                                            hasDwg={hasDwg}
                                        />
                                    }

                                </Col>
                            </Row>

                        </div>
                    </div>
                    <br />
                    <br />
                    <Footer />
                </Layout>
            </LoadingOverlay>
        )
    }
}

export default singleConfigurator;