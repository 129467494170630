import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { signout, isAuthenticated } from "../auth";
import { itemTotal } from "./cartHelpers";
import Logo from "../images/ContentLogo.png";
import { Nav, Navbar } from 'react-bootstrap';
import ProfileMenu from './ProfileMenu';

const isActive = (history, path) => {
    if (history.location.pathname === path) {
        return { color: "#269ded", 'fontWeight': "bolder", textDecoration: 'underline' };
    } else {
        return { color: "black" };
    }
};

const Menu = ({ history }) => (

    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
        <Navbar.Brand href="/"><img alt="" src={Logo}
            className="xs-3"
            style={{ maxHeight: "40%", maxWidth: "50%" }}
        />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">

                <Nav.Link href="/" style={isActive(history, "/")} to="/">Home</Nav.Link>
                <Nav.Link href="/browse" style={isActive(history, "/browse")} to="/browse">Browse</Nav.Link>
                <Nav.Link href="/configurators" style={isActive(history, "/configurators")} to="/configurators">Configurators</Nav.Link>
                <Nav.Link href="/cart" style={isActive(history, "/cart")} to="/cart">Downloads{" "}<small className="badge badge-danger ml-2">{itemTotal()}</small><sup></sup>
                </Nav.Link>

                {isAuthenticated() && isAuthenticated().role === "subscriber" && (
                    <Nav.Link href="/user/dashboard" style={isActive(history, "/user/dashboard")} to="/user/dashboard">Dashboard</Nav.Link>
                )}

                {isAuthenticated() && isAuthenticated().role === "admin" && (
                    <Nav.Link href="/admin/dashboard" style={isActive(history, "/admin/dashboard")} to="/admin/dashboard">Dashboard</Nav.Link>
                )}

                {isAuthenticated() && isAuthenticated().role === "vendor" && (
                    <Nav.Link href="/admin/dashboard" style={isActive(history, "/admin/dashboard")} to="/admin/dashboard">Dashboard</Nav.Link>
                )}


                <Nav.Link href="/ForVendors" style={isActive(history, "/ForVendors")} to="/ForVendors">Vendors</Nav.Link>

                {/* {!isAuthenticated() && (
                    <Fragment>
                        <Nav.Link href="/signin" style={isActive(history, "/signin")} to="/signin">Signin</Nav.Link>
                        <Nav.Link href="/signup" style={isActive(history, "/signup")} to="/signup">Signup</Nav.Link>
                    </Fragment>
                )} */}
                <Nav.Link href="/gethelp" style={isActive(history, "/gethelp")} to="/gethelp">Help</Nav.Link>
                {/* <Nav.Link className="badge badge-warning" href="/gethelp" style={isActive(history, "/gethelp")} to="/gethelp">help</Nav.Link> */}




            </Nav>
            <ProfileMenu />
            {/* <Nav>
                {isAuthenticated() && (
                    <span className="p-2" style={{ cursor: "pointer", color: "black" }} onClick={() => signout(() => {
                        history.push("/");
                    })}>Signout
                    </span>
                )}                
            </Nav> */}
        </Navbar.Collapse>
    </Navbar>

    // <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
    //     <h5 className="my-0 mr-md-auto font-weight-normal">
    //         <a href="/"><img alt="" src={Logo}
    //             className="mb-3"
    //             style={{ maxHeight: "50%", maxWidth: "60%" }}
    //         />
    //         </a>
    //     </h5>
    //     <nav className="my-2 my-md-0 mr-md-3">
    //         <Link className="p-2" style={isActive(history, "/")} to="/">Home</Link>

    //         {/* <Link className="p-2 text-dark" style={isActive(history, "/cadhome")} to="/cadhome">CAD</Link> */}

    //         <Link className="p-2" style={isActive(history, "/browse")} to="/browse">Browse</Link>
    //         <Link className="p-2" style={isActive(history, "/configurators")} to="/configurators">Configurators</Link>
    //         {/* <Link className="p-2 text-dark" style={isActive(history, "/browsesymbols")} to="/browsesymbols">Browse Symbols</Link> */}
    //         <Link className="p-2" style={isActive(history, "/cart")} to="/cart">
    //             Downloads{" "}<sup><small className="badge badge-danger ml-2">{itemTotal()}</small></sup></Link>

    //         {isAuthenticated() && isAuthenticated().role === "subscriber" && (
    //             <Link className="p-2" style={isActive(history, "/user/dashboard")} to="/user/dashboard" >Dashboard</Link>
    //         )}

    //         {isAuthenticated() && isAuthenticated().role === "admin" && (
    //             <Link className="p-2" style={isActive(history, "/admin/dashboard")} to="/admin/dashboard">Dashboard</Link>
    //         )}

    //         <Link className="p-2" style={isActive(history, "/ForVendors")} to="/ForVendors">For Vendors</Link>


    //         {!isAuthenticated() && (
    //             <Fragment>
    //                 <Link className="p-2" style={isActive(history, "/signin")} to="/signin">Signin</Link>
    //                 <Link className="p-2" style={isActive(history, "/signup")} to="/signup">Signup</Link>
    //             </Fragment>
    //         )}

    //         {isAuthenticated() && (
    //             <span className="p-2" style={{ cursor: "pointer", color: "#ffffff" }} onClick={() => signout(() => {
    //                 history.push("/");
    //             })}>Signout
    //             </span>
    //         )}

    //         <Link className="badge badge-warning p-2" style={isActive(history, "/gethelp")} to="/gethelp">help</Link>

    //     </nav>
    // </div>
);


export default withRouter(Menu);
