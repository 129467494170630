import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import Footer from '../core/Footer';
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import { listDownloads, getAdminReport, getContents, getUserDetails } from "../api/apiAdmin";
import LoadingOverlay from 'react-loading-overlay';
import DefaultProfile from "../images/avatar.png";
import ReactGA from 'react-ga';
import { useMediaQuery } from 'react-responsive'

const AdminDashboard = () => {

    const [downloads, setDownloads] = useState([]);
    const [reports, setReports] = useState([]);
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState([])
    // const [userDetails, setUserDetails] = useState([])
    const token = isAuthenticated() && isAuthenticated().token;
    const userId = isAuthenticated() && isAuthenticated().userId;
    const userEmail = isAuthenticated() && isAuthenticated().email;
    const companyName = isAuthenticated() && isAuthenticated().companyName;
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [runningInCAD, setRunningInCAD] = useState(false);

    // const getUserDetailsById = () => {
    //     getUserDetails(userId, token).then(data => {
    //         if (data.error) {
    //             console.log(data.error);
    //         } else {
    //             setUserDetails(data);
    //         }
    //     });
    // };

    const loadContents = () => {
        //getContents(userId, skip, limit, token).then(data => {
        getContents(userId, 0, 4000, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setContents(data.data);
            }
        });
    };

    const loadReport = () => {
        const skip = 0
        const limit = 100

        getAdminReport(userId, skip, limit, token, companyName).then(data => {
            if (data.error) {
                setReports(0);
            } else {
                setReports(data);
            }
        });
    };

    const loadDownloads = () => {
        listDownloads(userId, token).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                setDownloads(data);
            }
        });
    };

    // const loadMessages = () => {

    //     listMessages(email, token).then(data => {
    //         if (data.error) {
    //             console.log(data.error);
    //         } else {
    //             setMessages(data);
    //         }
    //     });
    // };

    useEffect(() => {

        // getUserDetailsById();
        loadDownloads();
        // loadMessages(); // was working on a way to get messages
        loadReport();
        loadContents();
        setLoading(false);
        ReactGA.initialize('G-F4M83JTFLH');
        ReactGA.pageview("/admin/dashboard/" + isAuthenticated().email);
        ReactGA.set({ userId: userId });
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);



    const adminLinks = () => {
        return (
            <div className="card">
                <h4 className="card-header">Admin Links</h4>
                <ul className="list-group">
                    {isAuthenticated().role === 'admin' && <li className="list-group-item">
                        <Link className="nav-link" to="/admin/tags">
                            Manage Content Tags
                        </Link>
                    </li>}
                    <li className="list-group-item">
                        <Link className="nav-link" to="/admin/downloads">
                            All Your Downloads <span className="badge badge-pill badge-primary">{downloads.length}</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to="/admin/contents">
                            Manage Your Content <span className="badge badge-pill badge-success">{contents.length}</span> 
                        </Link>
                    </li>                    
                    <li className="list-group-item">
                        <Link className="nav-link" to="/admin/reports">
                            Your Companies Downloads <span className="badge badge-pill badge-success">{reports.length}</span>
                        </Link>
                    </li>
                    <li className="list-group-item">
                        <Link className="nav-link" to={`/profile/${userId}`}>
                            Update Profile
                        </Link>
                    </li>


                    {/* The following links are hardcoded until the new database is running and roles are established for 'super user' */}
                    {isAuthenticated().role === 'admin' && <li className="list-group-item">
                        <Link className="nav-link" to={`/configAdmin/${isAuthenticated().userId}`}>
                            Manage Your Configurators
                        </Link>
                    </li>}

                    {isAuthenticated().role === 'admin' && <li className="list-group-item">
                        <Link className="nav-link" to="/admin/manageusers">
                            Manage Users
                        </Link>
                    </li>}

                    {/* The following two links are hardcoded until the new database is running and roles are established for 'super user' */}
                    {(isAuthenticated().email.includes('spatialbiz') || isAuthenticated().email.includes('automationforce')) && <li className="list-group-item">
                        <Link className="nav-link" to="/admin/pointclouds">
                            Point Clouds [Beta]
                        </Link>
                    </li>}

                    {/* The following two links are hardcoded until the new database is running and roles are established for 'super user' */}
                    {(isAuthenticated().email.includes('spatialbiz') || isAuthenticated().email.includes('automationforce')) && <li className="list-group-item">
                        <Link className="nav-link" to="/admin/formaContent">
                            Forma Content [Beta]
                        </Link>
                    </li>}


                </ul>
            </div>
        );
    };

    const user = isAuthenticated().user

    const adminInfo = () => {


        return (
            <div>
                <div className="card">
                    <h3 className="card-header">User Information</h3>
                    <ul className="list-group">
                        <li className="list-group-item">First Name: <b>{isAuthenticated().name}</b></li>
                        <li className="list-group-item">Last Name: <b>{isAuthenticated().lastName}</b></li>
                        <li className="list-group-item">Company Name: <b>{isAuthenticated().companyName}</b></li>
                        <li className="list-group-item">Company Type: <b>{isAuthenticated().companyType}</b></li>
                        <li className="list-group-item">{isAuthenticated().email}</li>
                        <li className="list-group-item">
                            {isAuthenticated().role === "admin" ? "admin" : "Registered User"}
                        </li>
                    </ul>
                </div>

            </div>
        );
    };

    return (
        <LoadingOverlay
            active={loading}
            spinner
            text='Loading your content...'
        >
            <Layout
                title={`${isAuthenticated().name}'s Dashboard`}
                description="This is the place to manage all your content."
                className="container-fluid"
                isMobile={isTabletOrMobile}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6">
                                    <div>{adminLinks()}</div>
                                </div>
                                <div className="col-md-6">
                                    <div>{adminInfo()}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Layout>
        </LoadingOverlay>
    );
};

export default AdminDashboard;
