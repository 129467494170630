import React, { useState, useEffect } from 'react';
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { getAdminReport } from "../api/apiAdmin";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import LoadingOverlay from 'react-loading-overlay'; 
import { useMediaQuery } from 'react-responsive'

const AdminReports = () => { 

    const { userId, token, name, companyName } = isAuthenticated();
    const [reports, setReports] = useState([]);
    const [runningInCAD, setRunningInCAD] = useState(false);
    const [loading, setLoading] = useState([true])
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    
    const loadReport = () => {
        const skip = 0
        const limit = 100
        getAdminReport(userId, skip, limit, token, companyName).then(data => {
            if (data.error) {
                console.log(data.error);
            } else {
                console.log(data)
                setReports(data);
            }
        });
    };

    // this didn't work to get the link
    // function getUserDetails(userId) {
    //     alert(userId)
    //     getUserDetails(userId).then(data => {
    //         if (data.error) {                 
    //         } else {         
    //             alert(data)       
    //             return (data.email)
    //         }
    //     });
    // };

    useEffect(() => {
        loadReport();
        setLoading(false);
        if (window.uchInventorInterface) {
            setRunningInCAD(true)
        }
    }, []);

    const showReport = () => {

        if (reports.length > 0) {

            return (
                <div>
                    <div className="row">
                        <div className="col-sm-10"></div>
                        <div className="col-sm-2">
                            <p>
                                <ReactHTMLTableToExcel
                                    id="adminReportbutton"
                                    className="btn btn-primary"
                                    table="adminReport"
                                    filename="UCHadminReport"
                                    sheet="UCHadminReport"
                                    buttonText="Download as XLS" />
                            </p>
                        </div>
                    </div>
                    <table className="table" id="adminReport">
                        <thead>
                            <tr>
                                <th scope="col">Your Content Description</th>
                                <th scope="col">Type</th>
                                <th scope="col">File Name</th>
                                <th scope="col">Version</th>
                                <th scope="col">Downloaded On</th>
                                <th scope="col">Downloaded By</th>
                                <th scope="col">Downloaded By Email</th>
                                <th scope="col">Company Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((p, i) => (
                                <tr>
                                    <td>{p.contentDownloaded}</td>
                                    <td>{p.contentType}</td>
                                    <td>{p.contentFileName}</td>
                                    <td>{p.editionDownloaded}</td>
                                    <td>{p.downloadDate}</td>
                                    <td>{p.downloadedByName}</td>
                                    <td>{p.downloadedByEmail}</td>
                                    <td>{p.downloadedByCompany}</td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
    };

    return (
        <LoadingOverlay
                active={loading}
                spinner
                text='Loading your report...'
            >
        <Layout
            title="Admin Reports"
            description={`Hello ${name}! Here are your site analytics`}
            className="container-fluid"
            isMobile={isTabletOrMobile}
        >
            <div className="row">
                <div className="col-12">
                    <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href={`/admin/dashboard`}>Back to admin dashboard</a></li>
                                        <li className="breadcrumb-item active" aria-current="page" >Your content downloads by user</li>
                                    </ol>
                                </nav>
                                <hr />
                    <h3 className="text-center">
                        Your company downloads are at {reports.length + 1} total
                    </h3>
                    <hr />
                    {showReport()} 
                </div>
            </div>
        </Layout>
        </LoadingOverlay>
    );
};

export default AdminReports;